import { Badge, BadgeProps } from "@/components/ui/badge";
import {
  CaseStatus,
  getCaseStatusConfigByStatus,
  getVerdictConfigByVerdict,
  Verdict,
} from "@wire/shared";

export function getVerdictBadge(verdict: Verdict) {
  let config = getVerdictConfigByVerdict(verdict);
  let variant: BadgeProps["variant"] = "outline";
  switch (verdict) {
    case Verdict.ACTIONABLE:
      variant = "destructive";
      break;
    case Verdict.NON_ACTIONABLE:
      variant = "outlineSuccess";
      break;
    case Verdict.UNSURE:
      variant = "outlineWarning";
      break;
  }

  return <Badge variant={variant}>{config.display}</Badge>;
}

export function getCaseStatusBadge(status: CaseStatus) {
  let config = getCaseStatusConfigByStatus(status);
  let variant: BadgeProps["variant"] = "outline";
  switch (status) {
    case CaseStatus.CLOSED:
      variant = "outlineSuccess";
      break;
    case CaseStatus.NEW:
      variant = "outlineWarning";
      break;
    case CaseStatus.PROCESSING:
      variant = "outlineWarning";
      break;
    case CaseStatus.ESCALATED:
      variant = "destructive";
      break;
  }
  return <Badge variant={variant}>{config.display}</Badge>;
}
