"use client";
import { components } from "@/lib/api.types";
import { apiClient } from "@/lib/api";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { TableCard } from "../table-card";
import {
  IIntegrationConfig,
  IntegrationPlatform,
  IntegrationType,
  getIntegrationConfigByPlatform,
  getIntegrationConfigByType,
} from "@wire/shared";
import { FetchResponse } from "openapi-fetch";
import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import AddIntegration from "./add-integration";
import { useNavigate } from "@tanstack/react-router";
import { dateTime, dateTimeBuilder, getTimezone } from "@/lib/time";
import moment from "moment";

async function searchSystemLog(dto: components["schemas"]["PaginationDto"]) {
  const response = await apiClient.POST("/team/log", {
    body: { ...dto },
  });
  if (response.error != null) {
    throw new Error("Error searching system log");
  }
  return response.data;
}

export const SYSTEM_LOG_SETTINGS_QUERY = "settings-system-log";

export default function SystemLogSettings() {
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  const systemLogQuery = useQuery({
    queryKey: [SYSTEM_LOG_SETTINGS_QUERY, searchSettings],
    queryFn: () => searchSystemLog(searchSettings),
    placeholderData: keepPreviousData,
  });

  return (
    <div className="flex flex-col gap-4">
      <TableCard
        query={systemLogQuery}
        orderBy={searchSettings.orderBy}
        orderByDir={searchSettings.orderDir}
        onUpdate={setSearchSettings}
        searchable
        headers={[
          {
            key: "id",
            display: "Actor",
            format(value, row) {
              return row.userEmail ?? row.identifier ?? "-";
            },
          },
          { key: "message", display: "Action" },
          { key: "security", display: "Security Event", sortable: true },
          {
            key: "createdAt",
            sortable: true,
            display: `Time (${getTimezone()})`,
            format: (value) => dateTime(value),
          },
        ]}
      >
        <CardHeader>
          <div className="flex flex-col lg:items-center space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 lg:justify-between">
            <div>
              <CardTitle>System Log</CardTitle>
              <CardDescription>Events happening for your team</CardDescription>
            </div>
          </div>
        </CardHeader>
      </TableCard>
    </div>
  );
}
