import { apiClient, switchTeam } from "@/lib/api";

import { useMemo, useState } from "react";
import { toast } from "sonner";
import { useRouteContext, useRouter } from "@tanstack/react-router";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { useQueryClient } from "@tanstack/react-query";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Input } from "@/components/ui/input";

export default function DeleteTeam() {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const { team, teams, user } = useRouteContext({ from: "/_application" });
  const requiredConfirmationMessage = useMemo(
    () => `Permanently delete ${team.name}`,
    [team]
  );
  const queryClient = useQueryClient();

  async function deleteTeam() {
    if (team.id == user.parentTeamId) {
      toast.error("Cannot delete parent team");
      return;
    }
    if (confirmationMessage !== requiredConfirmationMessage) {
      toast.error("Incorrect confirmation message");
      return;
    }
    const response = await apiClient.DELETE("/admin/team/{id}", {
      params: {
        path: {
          id: team.id,
        },
      },
    });
    if (response.error != null) {
      toast.error("Error deleting team");
    } else {
      toast.success("Team deleted, switching to parent team");
      await switchTeam(user.parentTeamId);
      await router.navigate({ to: "/dashboard" });
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">Delete Team</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          {" "}
          <DialogTitle>Delete Team</DialogTitle>
          <DialogDescription>
            Permanently delete team and all associated data.
          </DialogDescription>
        </DialogHeader>
        <p className="text-sm">
          Type{" "}
          <code className="p-1 bg-muted text-sm">
            {requiredConfirmationMessage}
          </code>{" "}
          to delete the team.
        </p>
        <Input
          value={confirmationMessage}
          onChange={(e) => setConfirmationMessage(e.target.value)}
          placeholder="Confirm here"
        />
        <DialogFooter>
          <Button onClick={deleteTeam} variant="destructive">
            Delete Team
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
