import { ClipboardIcon } from "@heroicons/react/24/outline";
import copy from "copy-to-clipboard";
import { toast } from "sonner";

export default function CopyToClipboard({
  text,
  showIcon,
}: {
  text: string;
  showIcon?: boolean;
}) {
  function copyClicked() {
    copy(text);
    toast.success("Text copied to clipboard");
  }

  return (
    <div
      onClick={copyClicked}
      className="group cursor-pointer inline-flex relative space-y-2"
    >
      {text}
      {showIcon && (
        <ClipboardIcon className="h-5 cursor-pointer w-5 absolute bg-background rounded-md border border-gray-200 right-0 hidden group-hover:block" />
      )}
    </div>
  );
}
