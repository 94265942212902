import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { components } from "./api.types";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { dateTime } from "@/lib/time";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function pluralize(
  count: number,
  singular: string,
  plural: string = `${singular}s`
) {
  return count == 1 ? singular : plural;
}

// Typically used to detect the first render of a component so we can skip it in useEffect
export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export function useDebounce<T>(delay: number, value?: T) {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    if (value == debouncedValue) return;
    setDebouncing(true);
    const handler = setTimeout(() => {
      if (value != debouncedValue) {
        setDebouncedValue(value ?? undefined);
      }
      setDebouncing(false);
    }, delay);

    return () => {
      setDebouncing(false);
      clearTimeout(handler);
    };
  }, [value, delay]);

  function override(val?: T) {
    setDebouncedValue(val);
  }

  return { debounced: debouncedValue, debouncing, override };
}

export function dashCaseToTitleCase(s: string) {
  return s
    .replace(/[-_]/g, " ")
    .replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
}

export function capitalizeFirst(str: string) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function ellipsisTruncate(str: string, length: number) {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  }
  return str;
}

export function getDetailsFromRaw(raw: object) {
  let out: ReactNode[] = [];
  for (const [key, value] of Object.entries(raw)) {
    if (value == null) continue;
    if (typeof value == "object") {
      out.push(
        <li key={key} className="grid grid-cols-1 lg:grid-cols-4 ">
          <span className="text-muted-foreground font-mono">{key}</span>
          <span className="col-span-3 lg:text-right overflow-hidden whitespace-nowrap text-ellipsis">
            <Dialog>
              <DialogTrigger asChild>
                <Button size="sm" variant="outline">
                  View Object
                </Button>
              </DialogTrigger>
              <DialogContent className="max-h-[80vh] w-auto min-w-[256px] max-w-[80vw] flex flex-col overflow-hidden">
                <DialogHeader>
                  <DialogTitle>{key}</DialogTitle>
                </DialogHeader>
                <div className="overflow-auto">
                  <UnicodeSafeJSONPre json={value} />
                </div>
              </DialogContent>
            </Dialog>
          </span>
        </li>
      );
    } else {
      out.push(getCaseDetailValue(key, value));
    }
  }
  return out;
}

// Highlights any malicious characters so the user isn't tricked by them
const UnicodeSafeJSONPre = (props: { json: object }) => {
  const isNonStandardChar = (char: string) => {
    const code = char.charCodeAt(0);
    return code > 127 || (code < 32 && code !== 10 && code !== 13);
  };

  const renderCharacters = () => {
    return JSON.stringify(props.json, null, 2)
      .split("")
      .map((char, index) => {
        if (isNonStandardChar(char)) {
          return (
            <span key={index} className="bg-red-500">
              [U+{char.charCodeAt(0).toString(16).toUpperCase()}]
            </span>
          );
        }
        return <span key={index}>{char}</span>;
      });
  };
  return (
    <pre className="whitespace-pre-nowrap text-left">{renderCharacters()}</pre>
  );
};

function getCaseDetailValue(key: string, value: any) {
  let formattedValue = value;
  if (
    (key.toLowerCase().includes("time") &&
      key.toLocaleLowerCase() !== "timezone") ||
    key.toLowerCase().includes("date")
  ) {
    let date = dateTime(value);
    if (date != null) {
      formattedValue = date;
    }
  }
  return (
    <li
      key={key}
      className="flex flex-col lg:flex-row gap-2 justify-between overflow-hidden"
    >
      <span className="text-muted-foreground max-w-[512px] font-mono">
        {key}
      </span>
      <span className="col-span-3 lg:text-right overflow-hidden lg:whitespace-nowrap hyphens-auto lg:text-ellipsis">
        {value !== formattedValue || value.length > 256 ? (
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger className="lg:text-right max-w-[512px] text-left break-all">
                {formattedValue}
              </TooltipTrigger>
              <TooltipContent className="max-w-[400px] text-md w-auto whitespace-normal">
                {value}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          value
        )}
      </span>
    </li>
  );
}
