"use client";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { useState } from "react";
import { toast } from "sonner";

export default function ToggleIntegration({
  integration,
  onComplete,
}: {
  integration: components["schemas"]["Integration"];
  onComplete: () => void;
}) {
  const [enabled, setEnabled] = useState(integration.enabled);
  async function enable() {
    const response = await apiClient.PATCH("/integration/{id}/activate", {
      params: { path: { id: integration.id } },
    });
    if (response.error != null) {
      toast.error((response as any).error.message);
      return;
    }
    setEnabled(true);
    toast.success("Integration enabled");
    onComplete();
  }
  async function disable() {
    let response = await apiClient.PATCH("/integration/{id}/deactivate", {
      params: { path: { id: integration.id } },
    });
    if (response.error != null) {
      toast.error((response as any).error.message);
      return;
    }
    setEnabled(false);
    toast.warning("Integration disabled");
    onComplete();
  }
  if (enabled) {
    return (
      <Button variant="outlineDestructive" onClick={disable}>
        Disable Integration
      </Button>
    );
  } else {
    return <Button onClick={enable}>Enable Integration</Button>;
  }
}
