import { TableCard } from "@/components/table-card";
import { Badge } from "@/components/ui/badge";
import { CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { cn } from "@/lib/utils";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useNavigate, useRouteContext } from "@tanstack/react-router";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { toast } from "sonner";

async function getBatches(dto: components["schemas"]["SearchAQLBatchesDto"]) {
  const result = await apiClient.POST("/aql/batch", { body: dto });

  if (result.error != null) {
    throw new Error("Error getting batch items");
  }
  return result.data;
}

const EXTRACTION_QUEUE_QUERY_KEY = "admin-batches";

export default function AdminAQL() {
  const [filter, setFilter] = useState<
    //eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    components["schemas"]["AQLBatch"]["status"] | undefined
  >("IN_PROGRESS");
  const [querySettings, setQuerySettings] = useState<
    components["schemas"]["SearchAQLBatchesDto"]
  >({ orderBy: "startDate", orderDir: "asc", status: filter });

  const navigate = useNavigate();
  const batchQuery = useQuery({
    queryKey: [EXTRACTION_QUEUE_QUERY_KEY, querySettings],
    queryFn: () => getBatches(querySettings),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    setQuerySettings({ ...querySettings, status: filter });
  }, [filter]);

  async function complete(id: string) {
    const result = await apiClient.PATCH("/aql/batch/{id}", {
      params: { path: { id } },
      body: { status: "COMPLETED" },
    });
    if (result.error != null) {
      toast.error(result.error.message);
      return;
    }
    toast.success("Batch completed");
  }

  return (
    <TableCard
      query={batchQuery}
      onUpdate={(settings) =>
        setQuerySettings({ ...querySettings, ...settings })
      }
      searchable
      orderBy={querySettings.orderBy}
      orderByDir={querySettings.orderDir}
      onClick={(row) =>
        navigate({ to: "/admin/aql/$batchId", params: { batchId: row.id } })
      }
      headers={[
        {
          display: "Status",
          key: "status",
          format(value, row) {
            return <AQLBatchStatus className="text-nowrap" status={value} />;
          },
        },
        {
          display: "Start Date",

          sortable: true,
          key: "startDate",
          format(value, row) {
            return (
              <div className="text-nowrap">
                {" "}
                {moment(value).format("MMM Do YYYY")}
              </div>
            );
          },
        },
        {
          display: "End Date",
          key: "startDate",
          format(value, row) {
            return (
              <div className="text-nowrap">
                {" "}
                {moment(value)
                  .add(row.durationDays, "days")
                  .format("MMM Do YYYY")}
              </div>
            );
          },
        },
        {
          display: "Sample Size",
          sortable: true,
          key: "sampleSize",
          info: "The AQL sample size, not inclusive of manually reviewed cases that were not randomly sampled.",
          format(value, row) {
            return value != null ? numeral(value).format() : "-";
          },
        },
        {
          display: "Critical Defects",
          sortable: true,
          key: "criticalDefects",
        },
        {
          display: "Major Defects",
          sortable: true,
          key: "majorDefects",
        },
        {
          display: "Minor Defects",
          sortable: true,
          key: "minorDefects",
        },
      ]}
      rowActions={[
        {
          name: "View",
          onClick: (row) =>
            navigate({
              to: "/admin/aql/$batchId",
              params: { batchId: row.id },
            }),
        },
        {
          name: "Complete",
          onClick: (row) => complete(row.id),
          shouldDisplay(row) {
            return row.status === "IN_PROGRESS";
          },
        },
      ]}
    >
      <CardHeader className="flex flex-col gap-4 lg:flex-row lg:justify-between lg:items-center ">
        <div>
          <CardTitle>AQL Batches</CardTitle>
          <CardDescription>All AQL batches across all teams</CardDescription>
        </div>
        <div>
          <ul className="bg-background shadow-sm border p-2 rounded-md flex flex-col lg:flex-row w-full items-center lg:w-auto lg:items-start">
            <FilterItem
              onClick={() => setFilter(undefined)}
              key="all-filter"
              activeFilter={filter}
            >
              All
            </FilterItem>
            <FilterItem
              key="processing-filter"
              filter="NOT_STARTED"
              onClick={() => setFilter("NOT_STARTED")}
              activeFilter={filter}
            >
              Not Started
            </FilterItem>
            <FilterItem
              key="escalated-filter"
              filter="IN_PROGRESS"
              onClick={() => setFilter("IN_PROGRESS")}
              activeFilter={filter}
            >
              In Progress
            </FilterItem>
            <FilterItem
              key="closed-filter"
              filter="COMPLETED"
              onClick={() => setFilter("COMPLETED")}
              activeFilter={filter}
            >
              Completed
            </FilterItem>
          </ul>
        </div>
      </CardHeader>
    </TableCard>
  );
}

export function AQLBatchStatus(props: {
  status: components["schemas"]["AQLBatch"]["status"];
  className?: string;
}) {
  switch (props.status) {
    case "IN_PROGRESS":
      return <Badge className={props.className}>In Progress</Badge>;
    case "COMPLETED":
      return (
        <Badge variant="success" className={props.className}>
          Completed
        </Badge>
      );
    default:
      return (
        <Badge variant="outline" className={props.className}>
          Not Started
        </Badge>
      );
  }
}

function FilterItem(
  props: React.PropsWithChildren<{
    activeFilter?: components["schemas"]["AQLBatch"]["status"];
    filter?: components["schemas"]["AQLBatch"]["status"];
    onClick?: () => void;
  }>
) {
  return (
    <li
      onClick={props.onClick}
      className={cn(
        "px-3 text-sm cursor-pointer text-muted-foreground font-medium rounded-md",
        {
          "bg-background font-semibold text-foreground":
            props.filter == props.activeFilter,
        }
      )}
    >
      {props.children}
    </li>
  );
}
