"use client";
import { components } from "@/lib/api.types";
import { apiClient } from "@/lib/api";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { TableCard } from "../table-card";
import {
  IIntegrationConfig,
  IntegrationPlatform,
  IntegrationType,
  getIntegrationConfigByPlatform,
  getIntegrationConfigByType,
  getIntegrationTypeConfigByType,
} from "@wire/shared";
import { FetchResponse } from "openapi-fetch";
import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import AddIntegration from "./add-integration";
import { useNavigate } from "@tanstack/react-router";
import { dateTimeBuilder } from "@/lib/time";
import { capitalizeFirst } from "@/lib/utils";

async function searchIntegrations(dto: components["schemas"]["PaginationDto"]) {
  const response = await apiClient.POST("/integration", {
    body: { ...dto, includeDisabled: true },
  });
  if (response.error != null) {
    throw new Error("Error searching integration");
  }
  return response.data;
}

export const INTEGRATION_SETTINGS_QUERY = "settings-integration";

export default function IntegrationSettings() {
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useState<IntegrationPlatform>();
  const integrationQuery = useQuery({
    queryKey: [INTEGRATION_SETTINGS_QUERY],
    queryFn: () => searchIntegrations(searchSettings),
    placeholderData: keepPreviousData,
  });

  async function toggleIntegration(
    integration: components["schemas"]["IntegrationWithoutMetadata"]
  ) {
    let response: FetchResponse<any, any, any>;
    if (integration.enabled) {
      response = await apiClient.PATCH("/integration/{id}/deactivate", {
        params: { path: { id: integration.id } },
      });
    } else {
      response = await apiClient.PATCH("/integration/{id}/activate", {
        params: { path: { id: integration.id } },
      });
    }
    if (response.error != null) {
      return toast.error("Error updating integration");
    }
    if (integration.enabled) {
      toast.warning("Integration disabled");
    } else {
      toast.success("Integration enabled");
    }
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  async function deleteIntegration(id: string) {
    const response = await apiClient.DELETE("/integration/{id}", {
      params: { path: { id: id } },
    });
    if (response.error != null) {
      toast.error("Error deleting integration");
    } else {
      toast.warning("Integration deleted");
    }
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  async function testIntegration(id: string) {
    const response = await apiClient.POST("/integration/{id}/test", {
      params: { path: { id: id } },
    });
    if (response.error != null) {
      toast.error("Error testing integration");
    } else {
      toast.success("Integration test sent");
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <TableCard
        query={integrationQuery}
        onUpdate={setSearchSettings}
        onClick={(row) =>
          navigate({
            to: "/settings/integrations/$integrationId",
            params: { integrationId: row.id },
          })
        }
        rowActions={[
          {
            name: (row) => (row.enabled ? "Disable" : "Enable"),
            onClick: toggleIntegration,
          },
          {
            name: "Test",
            shouldDisplay: (row) =>
              getIntegrationConfigByPlatform(row.platform).types.includes(
                IntegrationType.CHAT
              ),
            onClick: (row) => testIntegration(row.id),
          },
          {
            name: "Delete",
            confirm: true,
            confirmMessage: (row) =>
              `Are you sure you want to delete the this integration?`,
            onClick: (row) => deleteIntegration(row.id),
          },
        ]}
        headers={[
          {
            key: "id",
            display: "Name",
            format: (val, row) => {
              let config = getIntegrationConfigByPlatform(row.platform);
              return (
                <div className="flex items-center space-x-2">
                  <img className="h-4 w-4" src={config.logo} />{" "}
                  <span>{config.display}</span>
                </div>
              );
            },
          },
          {
            key: "platform",
            display: "Platform",
            format: (platform) => {
              let config = getIntegrationConfigByPlatform(platform);
              return (
                <div className="flex flex-col">
                  <span>{config.display}</span>
                  <span className="text-xs text-muted-foreground">
                    {config.types
                      .map((v) => getIntegrationTypeConfigByType(v).display)
                      .join(", ")}
                  </span>
                </div>
              );
            },
          },
          {
            key: "enabled",
            display: "Enabled",
          },
          {
            key: "healthy",
            display: "Healthy",
          },
          {
            key: "lastUsedAt",
            display: "Last Used",
            format: (val) =>
              val != null ? dateTimeBuilder(val).fromNow() : "-",
          },
        ]}
      >
        <CardHeader>
          <div className="flex flex-col lg:items-center space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 lg:justify-between">
            <div className="flex flex-col flex-1 items-center lg:flex-row lg:justify-between">
              <CardTitle>Integrations</CardTitle>
              <AddIntegration />
            </div>
          </div>
        </CardHeader>
      </TableCard>
    </div>
  );
}
