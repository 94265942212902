import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { getIntegrationConfigByPlatform } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";
export const Route = createFileRoute("/_application/assets/users/")({
  component: Users,
  loader: async ({ context, deps }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(getVIPAutomationsOptions()),
      context.queryClient.ensureQueryData(getTechnicalAutomationsOptions()),
      context.queryClient.ensureQueryData(getDirectoryUsersOptions()),
    ]);
  },
});

function getDirectoryUsersOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [DIRECTORY_USERS_QUERY_KEY, settings],
    queryFn: () => getDirectoryUsers(settings),
    placeholderData: keepPreviousData,
  });
}

function getTechnicalAutomationsOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [TECHNICAL_AUTOMATIONS_KEY, settings],
    queryFn: () => getTechnicalAutomations(settings),
    placeholderData: keepPreviousData,
  });
}

function getVIPAutomationsOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [VIP_AUTOMATIONS_KEY, settings],
    queryFn: () => getVIPAutomations(settings),
    placeholderData: keepPreviousData,
  });
}

async function getVIPAutomations(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  const response = await apiClient.POST("/directory/vip/automation", {
    body: searchSettings,
  });

  if (response.error != null) {
    throw new Error("Error getting vip automations");
  }

  return response.data;
}

async function getTechnicalAutomations(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  const response = await apiClient.POST("/directory/technical/automation", {
    body: searchSettings,
  });

  if (response.error != null) {
    throw new Error("Error getting technical automations");
  }

  return response.data;
}
async function getDirectoryUsers(
  searchSettings: components["schemas"]["DirectoryUserSearchDto"] = {
    field: "title",
  }
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/directory", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting directory users");
  }

  return response.data;
}

const VIP_AUTOMATIONS_KEY = "settings-vip-automations";
const TECHNICAL_AUTOMATIONS_KEY = "technical-vip-automations";
const DIRECTORY_USERS_QUERY_KEY = "settings-directory-users";

export default function Users() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [directoryUserSearchSettings, setDirectoryUserSearchSettings] =
    useState<components["schemas"]["DirectoryUserSearchDto"]>();

  const [vipAutomationSearchSettings, setVIPAutomationSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const [
    technicalAutomationSearchSettings,
    setTechnicalAutomationSearchSettings,
  ] = useState<components["schemas"]["PaginationDto"]>();

  const directoryUsersQuery = useSuspenseQuery(
    getDirectoryUsersOptions(directoryUserSearchSettings)
  );
  const vipAutomationsQuery = useSuspenseQuery(
    getVIPAutomationsOptions(vipAutomationSearchSettings)
  );
  const technicalAutomationsQuery = useSuspenseQuery(
    getTechnicalAutomationsOptions(technicalAutomationSearchSettings)
  );

  async function deleteVIPAutomation(id: string) {
    const response = await apiClient.DELETE("/directory/vip/automation/{id}", {
      params: { path: { id } },
    });

    if (response.error != null) {
      toast.error("Error deleting automation");
      return;
    }

    await queryClient.invalidateQueries({ queryKey: [VIP_AUTOMATIONS_KEY] });
    toast.warning("Automation deleted");
  }

  async function deleteTechnicalAutomation(id: string) {
    const response = await apiClient.DELETE(
      "/directory/technical/automation/{id}",
      {
        params: { path: { id } },
      }
    );

    if (response.error != null) {
      toast.error("Error deleting automation");
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [TECHNICAL_AUTOMATIONS_KEY],
    });
    toast.warning("Automation deleted");
  }
  function updateDirectoryUserSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setDirectoryUserSearchSettings({
      ...directoryUserSearchSettings,
      ...settings,
      field: directoryUserSearchSettings?.field ?? "title",
    });
  }

  async function updateDirectoryUser(
    id: string,
    dto: components["schemas"]["UpdateDirectoryUserDto"]
  ) {
    const response = await apiClient.PATCH("/directory/{id}", {
      params: {
        path: { id },
      },
      body: dto,
    });

    if (response.error != null) {
      return toast.error("Error updating user");
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY],
    });
    toast.success("User updated");
  }

  async function addVIPAutomation() {
    if (
      directoryUserSearchSettings == null ||
      directoryUserSearchSettings.field == null ||
      directoryUserSearchSettings.search == null
    ) {
      toast.error("Please select search field and search");
      return;
    }
    let search = formatSearchQuery(directoryUserSearchSettings.search);

    const response = await apiClient.PUT("/directory/vip/automation", {
      body: {
        search: search,
        field: directoryUserSearchSettings.field,
      },
    });

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error("VIP automation already exists");
      } else {
        toast.error("Error creating VIP automation");
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [VIP_AUTOMATIONS_KEY],
    });
    toast.success("VIP Automation created");
  }

  async function addTechnicalAutomation() {
    if (
      directoryUserSearchSettings == null ||
      directoryUserSearchSettings.field == null ||
      directoryUserSearchSettings.search == null
    ) {
      toast.error("Please select search field and search");
      return;
    }
    let search = formatSearchQuery(directoryUserSearchSettings.search);

    const response = await apiClient.PUT("/directory/technical/automation", {
      body: {
        search: search,
        field: directoryUserSearchSettings.field,
      },
    });

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error("Technical user automation already exists");
      } else {
        toast.error("Error creating technical user automation");
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [TECHNICAL_AUTOMATIONS_KEY],
    });
    toast.success("Technical User Automation created");
  }

  if (directoryUsersQuery.isLoading) {
    return <Skeleton className="w-full h-[256px]" />;
  }

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <TableCard
          onUpdate={updateDirectoryUserSearchSettings}
          query={directoryUsersQuery}
          searchable
          compact
          onClick={(row) =>
            navigate({
              to: "/assets/users/$userId",
              params: { userId: row.id },
            })
          }
          searchFilters={[
            {
              label: "Search Field",
              placeholder: "Select field",
              defaultValue: "title",
              values: [
                { key: "name", display: "Name" },
                { key: "email", display: "Email" },
                { key: "title", display: "Job Title" },
                { key: "department", display: "Department" },
                { key: "role", display: "Role" },
              ],
              onSelect: (v: any) =>
                setDirectoryUserSearchSettings({
                  ...directoryUserSearchSettings,
                  page: 1,
                  search: directoryUserSearchSettings?.search,
                  field: v,
                }),
            },
          ]}
          headers={[
            { display: "Name", key: "name", sortable: true },
            { display: "Email", key: "email", sortable: true },
            { display: "Title", key: "title", sortable: true },
            {
              display: "Roles",
              key: "roles",
              truncate: 60,
              hover: true,
            },
            { display: "Department", key: "department", sortable: true },
            { display: "VIP", key: "vip", sortable: true },
            { display: "Technical", key: "technical", sortable: true },
            {
              display: "Source",
              key: "integrationPlatform",
              format: (v) => getIntegrationConfigByPlatform(v).display,
            },
            {
              display: "VIP Locked",
              key: "vipOverriddenByUser",
              info: ` A user has manually updated the VIP status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable VIP Lock".`,
            },
            {
              display: "Technical Locked",
              key: "technicalOverriddenByUser",
              info: ` A user has manually updated the technical status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable Technical Lock".`,
            },
          ]}
          rowActions={[
            {
              name: (row) => (row.vip ? `Disable VIP` : "Enable VIP"),
              onClick: (row) =>
                updateDirectoryUser(row.id, {
                  vip: !row.vip,
                  vipOverriddenByUser: true,
                }),
            },
            {
              name: (row) =>
                row.vipOverriddenByUser
                  ? `Disable VIP Lock`
                  : "Enable VIP Lock",
              onClick: (row) =>
                updateDirectoryUser(row.id, {
                  vipOverriddenByUser: !row.vipOverriddenByUser,
                }),
            },
            {
              name: (row) =>
                row.technical ? `Disable Technical` : "Enable Technical",
              onClick: (row) =>
                updateDirectoryUser(row.id, {
                  technical: !row.technical,
                  technicalOverriddenByUser: true,
                }),
            },
            {
              name: (row) =>
                row.vipOverriddenByUser
                  ? `Disable Technical Lock`
                  : "Enable Technical Lock",
              onClick: (row) =>
                updateDirectoryUser(row.id, {
                  technicalOverriddenByUser: !row.technicalOverriddenByUser,
                }),
            },
          ]}
        >
          <CardHeader>
            <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
              <div className="flex flex-col gap-2">
                <CardTitle>Directory Users</CardTitle>
                <CardDescription>
                  All users ingested from your directory integrations. Use{" "}
                  <kbd>*</kbd> for a wildcard, <kbd>"quotes"</kbd> for exact
                  match, or <kbd>_</kbd> for single character wildcard.
                </CardDescription>
              </div>
              {directoryUserSearchSettings?.search && (
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button>Actions</Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={addVIPAutomation}>
                      Add New VIP Automation
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={addTechnicalAutomation}>
                      Add New Technical User Automation
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </CardHeader>
        </TableCard>
        <TableCard
          onUpdate={setVIPAutomationSearchSettings}
          query={vipAutomationsQuery}
          searchable
          headers={[
            { display: "Search Field", key: "searchField" },
            { display: "Search", key: "search" },
          ]}
          rowActions={[
            {
              name: "Delete Automation",
              onClick: (row) => deleteVIPAutomation(row.id),
              confirm: true,
              confirmMessage:
                "Are you sure you want to delete this VIP Automation? This will also remove any VIPs identified from this automation.",
            },
          ]}
        >
          <CardHeader>
            <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
              <div>
                <CardTitle>VIP Automations</CardTitle>
                <CardDescription>
                  Search queries to automatically identify VIPs in your
                  organization. To create a new automation, issue a search on
                  the Users table above and select Actions &gt; Add New VIP
                  Automation.
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>

        <TableCard
          onUpdate={setTechnicalAutomationSearchSettings}
          query={technicalAutomationsQuery}
          searchable
          headers={[
            { display: "Search Field", key: "searchField" },
            { display: "Search", key: "search" },
          ]}
          rowActions={[
            {
              name: "Delete Automation",
              onClick: (row) => deleteTechnicalAutomation(row.id),
              confirm: true,
              confirmMessage:
                "Are you sure you want to delete this Technical User Automation? This will remove all users identified from this automation.",
            },
          ]}
        >
          <CardHeader>
            <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
              <div>
                <CardTitle>Technical User Automations</CardTitle>
                <CardDescription>
                  Search queries to automatically identify technical users in
                  your organization. To create a new automation, issue a search
                  on the Users table above and select Actions &gt; Add New
                  Technical User Automation.
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
