import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { Dialog, DialogTrigger, DialogContent } from "@radix-ui/react-dialog";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import { ConstrainedAppLayout } from "@/components/app-layout";
import { FILE_QUERY_KEY } from "@/routes/_application/assets/files/$fileId";

export const Route = createFileRoute("/_application/assets/ips/")({
  component: IPs,
  loader: async ({ context, deps }) => {
    await Promise.all([context.queryClient.ensureQueryData(getOptions())]);
  },
});

function getOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [IPS_QUERY_KEY, settings],
    queryFn: () => search(settings),
    placeholderData: keepPreviousData,
  });
}

async function search(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/ip", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting IPs");
  }

  return response.data;
}

const IPS_QUERY_KEY = "assets-ips";

export default function IPs() {
  const queryClient = useQueryClient();
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useSuspenseQuery(getOptions(searchSettings));

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <TableCard
          onUpdate={updateSearchSettings}
          query={query}
          searchable
          compact
          onClick={(row) =>
            navigate({
              to: "/assets/ips/$ipId",
              params: { ipId: row.id },
            })
          }
          headers={[
            {
              display: "IP",
              key: "id",
              format: (_, row) => row.ipv4 ?? row.ipv6,
            },
            {
              display: "City",
              key: "id",
              format: (_, row) => row.metadata?.city ?? "-",
            },
            {
              display: "Region",
              key: "id",
              format: (_, row) => row.metadata?.region ?? "-",
            },
            {
              display: "Country",
              key: "id",
              format: (_, row) => row.metadata?.country ?? "-",
            },
          ]}
        >
          <CardHeader>
            <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
              <div className="flex flex-col gap-2">
                <CardTitle>IP Addresses</CardTitle>
                <CardDescription>
                  IP addresses pulled from your environment
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
