"use client";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";

export default function DeleteIntegration({
  integration,
}: {
  integration: components["schemas"]["Integration"];
}) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  async function deleteIntegration() {
    await apiClient.DELETE("/integration/{id}", {
      params: { path: { id: integration.id } },
    });
    toast.warning("Integration deleted");
    await navigate({ to: "/settings", search: { tab: "integrations" } });
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outlineDestructive">Delete</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete your
            integration and remove your data from our servers.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="destructive" onClick={deleteIntegration}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
