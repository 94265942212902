import { ConstrainedAppLayout } from "@/components/app-layout";
import DeleteIntegration from "@/components/settings/integrations/delete-integration";
import ToggleIntegration from "@/components/settings/integrations/trigger-integration";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import {
  IntegrationType,
  getIntegrationConfigByPlatform,
  getIntegrationTypeConfigByType,
} from "@wire/shared";
import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import moment from "moment";
import { useMemo } from "react";
import { toast } from "sonner";
import {
  CheckCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { cn } from "@/lib/utils";
import { dateTimeBuilder } from "@/lib/time";
import HealthCheckIntegration from "@/components/settings/integrations/health-check-integration";
import { components } from "@/lib/api.types";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

export const Route = createFileRoute(
  "/_application/settings/integrations/$integrationId"
)({
  loader: ({ params, context }) =>
    context.queryClient.ensureQueryData(
      getIntegrationOptions(params.integrationId)
    ),
  component: Integration,
});

async function getIntegration(id: string) {
  const response = await apiClient.GET("/integration/{id}", {
    params: { path: { id } },
  });
  if (response.error != null) {
    throw new Error("Error getting register information");
  }
  let integration = response.data;

  return { integration };
}

const INTEGRATION_QUERY_KEY = "INTEGRATION";
const getIntegrationOptions = (id: string) =>
  queryOptions({
    queryKey: [INTEGRATION_QUERY_KEY, id],
    queryFn: () => getIntegration(id),
  });

function Integration() {
  const { integrationId } = Route.useParams();
  const queryClient = useQueryClient();
  const {
    data: { integration },
  } = useSuspenseQuery(getIntegrationOptions(integrationId));
  const integrationConfig = useMemo(() => {
    return getIntegrationConfigByPlatform(integration.platform);
  }, [integration]);

  async function testIntegration() {
    const response = await apiClient.POST("/integration/{id}/test", {
      params: { path: { id: integrationId } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
    } else {
      toast.success(
        `Integration tested, please check ${integrationConfig.display}`
      );
    }
  }

  return (
    <ConstrainedAppLayout>
      <Card>
        <CardHeader>
          <div className="flex flex-col gap-4 lg:flex-row lg:items-center items-start lg:justify-between">
            <div>
              <CardTitle className="flex gap-1 text-xl items-center">
                <img className="h-6 w-6" src={integrationConfig.logo} />
                {integrationConfig.display}
              </CardTitle>
            </div>
            <div className="space-x-2">
              {integrationConfig.types.includes(IntegrationType.CHAT) && (
                <Button onClick={testIntegration} variant="outline">
                  Test
                </Button>
              )}
              <DeleteIntegration integration={integration} />
              <ToggleIntegration
                onComplete={() =>
                  queryClient.invalidateQueries({
                    queryKey: [INTEGRATION_QUERY_KEY, integration.id],
                  })
                }
                integration={integration}
              />
              <HealthCheckIntegration
                onComplete={() =>
                  queryClient.invalidateQueries({
                    queryKey: [INTEGRATION_QUERY_KEY, integration.id],
                  })
                }
                integration={integration}
              />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <ul className="flex flex-wrap gap-4">
            {Object.values(integration.metadata).map((v) => (
              <HealthCard
                disabled={!integration.enabled}
                refresh={() =>
                  queryClient.invalidateQueries({
                    queryKey: [INTEGRATION_QUERY_KEY, integration.id],
                  })
                }
                integrationId={integration.id}
                metadataId={v.id}
                title={getIntegrationTypeConfigByType(v.type as any)?.display}
                metadata={v}
              />
            ))}
          </ul>
        </CardContent>
      </Card>
    </ConstrainedAppLayout>
  );
}

function HealthCard(props: {
  metadataId: string;
  integrationId: string;
  disabled: boolean;
  refresh: () => void;
  metadata?: Partial<components["schemas"]["MetadataItem"]>;
  title: string;
}) {
  async function toggleIgnore() {
    const response = await apiClient.PATCH(
      "/integration/{integrationId}/metadata/{metadataId}",
      {
        params: {
          path: {
            integrationId: props.integrationId,
            metadataId: props.metadataId,
          },
        },
        body: { ignored: !props.metadata?.ignored },
      }
    );
    if (response.error != null) {
      toast.error((response as any)?.error?.message);
    }
    props.refresh();
  }
  const healthDescription = useMemo(() => {
    if (props.disabled) return "Disabled";
    if (props.metadata?.message) return props.metadata.message;
    if (props.metadata?.ignored) return "Ignored";
    if (props.metadata?.healthy) return "Healthy";
    return "Unhealthy";
  }, [props.disabled, props.metadata]);
  return (
    <Card
      key={props.title}
      className={cn({
        "bg-green-100 dark:bg-green-700": props.metadata?.healthy,
        "bg-red-100 dark:bg-red-700": !props.metadata?.healthy,
        "bg-muted dark:bg-muted": props.metadata?.ignored || props.disabled,
      })}
    >
      <CardHeader className="flex flex-col lg:flex-row justify-between gap-8">
        <div>
          <CardTitle>{props.title}</CardTitle>
          <CardDescription>{healthDescription}</CardDescription>
        </div>
        <div>
          {!props.metadata?.healthy && !props.disabled && (
            <Button variant="outline" onClick={toggleIgnore}>
              {props.metadata?.ignored ? "Unignore" : "Ignore"}
            </Button>
          )}
        </div>
      </CardHeader>
      <CardFooter className="text-xs text-muted-foreground">
        {props.metadata?.lastUsedAt ? (
          <>Used {dateTimeBuilder(props.metadata?.lastUsedAt).fromNow()}</>
        ) : (
          <>Not used</>
        )}
      </CardFooter>
    </Card>
  );
}
