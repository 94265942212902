import copy from "copy-to-clipboard";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

export function OAuthButton(props: {
  url?: string;
  onComplete?: () => void;
  title: string;
  error?: string;
}) {
  function copyLink() {
    if (!props.url) return;
    copy(props.url);
    toast.success("URL copied to clipboard", {
      action: {
        label: "More info",
        onClick: () =>
          window.open(
            "/docs/faq/#how-do-i-set-up-integrations-as-a-non-admin-user",
            "_blank"
          ),
      },
    });
    props.onComplete?.();
  }

  return (
    <div className="space-y-2">
      <div className="flex flex-row space-x-2">
        <Button onClick={props.onComplete} asChild>
          <a href={props.url} target="_blank">
            {props.title}
          </a>
        </Button>
        <Button onClick={copyLink} variant="outline">
          Copy Link for Admin
        </Button>
        <a
          href="/docs/faq/#how-do-i-set-up-integrations-as-a-non-admin-user"
          target="_blank"
        >
          <Button variant="outline">?</Button>
        </a>
      </div>
      {props.error && <p className="text-sm text-red-500">{props.error}</p>}
    </div>
  );
}
