import { apiClient } from "@/lib/api";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { useRouter } from "@tanstack/react-router";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { PlusIcon } from "@radix-ui/react-icons";
import { useQueryClient } from "@tanstack/react-query";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { SETTINGS_TEAM_MEMBER_QUERY } from "@/components/settings/team";

export default function AddTeamMember() {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const teamMemberSchema = useMemo(
    () =>
      z.object({
        email: z.string().email().min(1).max(512),
        name: z.string().min(1, {}).max(512),
      }),
    []
  );

  const teamMemberForm = useForm<z.infer<typeof teamMemberSchema>>({
    resolver: zodResolver(teamMemberSchema),
    defaultValues: {
      name: "",
      email: "",
    },
  });

  async function onTeamMemberSubmit(values: z.infer<typeof teamMemberSchema>) {
    const response = await apiClient.PUT("/team/invite", {
      body: { name: values.name, email: values.email },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }

    // This handles refetching the new team mate
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBER_QUERY],
    });
    await router.invalidate();

    toast.success("User invited");
    setOpen(false);
    teamMemberForm.reset();
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button>
            <PlusIcon className="h-5 w-5 mr-2" />
            Invite Team Member
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            {" "}
            <DialogTitle>Invite Team Member</DialogTitle>
            <DialogDescription>
              Invite members of your security team to collaborate on cases.
            </DialogDescription>
          </DialogHeader>
          <Form {...teamMemberForm}>
            <form
              onSubmit={teamMemberForm.handleSubmit(onTeamMemberSubmit)}
              className="col-span-5 mt-2 flex flex-col space-y-4"
            >
              <FormField
                control={teamMemberForm.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Wirespeed" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={teamMemberForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="jake@wirespeed.co" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex self-end">
                <Button
                  disabled={teamMemberForm.formState.isSubmitting}
                  variant="outline"
                  type="submit"
                >
                  Invite
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
