import { ConstrainedAppLayout } from "@/components/app-layout";
import { Button } from "@/components/ui/button";
import { switchTeam } from "@/lib/api";
import { GLOBAL_TEAM_QUERY_KEY, router } from "@/main";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import {
  Router,
  SearchSchemaInput,
  createFileRoute,
  useNavigate,
} from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/switch")({
  component: Switch,
  validateSearch: (
    search: {
      from: string;
      to: string;
      redirect: string;
    } & SearchSchemaInput
  ) => {
    return {
      from: search.from,
      to: search.to,
      redirect: search.redirect,
    };
  },
});

let checked = false;

export function Switch() {
  const { from, to, redirect } = Route.useSearch();
  const navigate = useNavigate();
  const { teams } = Route.useRouteContext();
  const fromTeam = useMemo(() => {
    return teams.find((v) => v.id == from)?.name;
  }, [from, teams]);
  const toTeam = useMemo(() => {
    return teams.find((v) => v.id == to)?.name;
  }, [to, teams]);

  async function goToLink() {
    await switchTeam(to, navigate, redirect, false);
    toast.warning("Switched teams");
  }

  if (localStorage.getItem("DONT_WARN_SWITCH") == "true") {
    // This is so loading can finish before we navigate. We were getting issues with the route context not updating without this
    useEffect(() => {
      if (!checked) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        goToLink();
        checked = true;
      }
    }, []);
    return;
  }

  async function dontWarnAgain() {
    localStorage.setItem("DONT_WARN_SWITCH", "true");
    await goToLink();
  }

  function dontContinue() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate({ to: "/dashboard" });
  }

  return (
    <ConstrainedAppLayout>
      <div className="flex mt-24 h-full w-full text-center justify-center flex-col items-center">
        <h1 className="text-4xl flex flex-col items-center gap-4">
          <ExclamationTriangleIcon className="h-16 w-16" /> Warning
        </h1>
        <div className="text-2xl font-light mt-4">
          You are currently logged into the <b>{fromTeam}</b> team and are
          trying to access a resource for the <b>{toTeam}</b> team. Continuing
          will update your session to the {toTeam} team.
        </div>
        <div className="flex mt-8 flex-col gap-4 lg:flex-row ">
          <Button onClick={goToLink} size="lg">
            Continue
          </Button>
          <Button onClick={dontWarnAgain} size="lg" variant="outline">
            Don't warn me again
          </Button>
          <Button onClick={dontContinue} size="lg" variant="outline">
            Go back
          </Button>
        </div>
      </div>
    </ConstrainedAppLayout>
  );
}
