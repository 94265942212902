import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { queryClient } from "@/main";
import { DialogDescription } from "@radix-ui/react-dialog";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getIntegrationConfigByPlatform } from "@wire/shared";
import { useEffect, useState } from "react";
import { toast } from "sonner";

async function getExtractionData(dto: components["schemas"]["PaginationDto"]) {
  const result = await apiClient.POST("/admin/extraction", { body: dto });

  if (result.error != null) {
    throw new Error("Error getting admin extraction queue items");
  }
  return result.data;
}

const EXTRACTION_QUEUE_QUERY_KEY = "extraction-queue";

export default function ExtractionQueue() {
  const [selectedItem, setSelectedItem] =
    useState<components["schemas"]["ExtractionQueue"]>();
  const [querySettings, setQuerySettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});

  const extractionQuery = useQuery({
    queryKey: [EXTRACTION_QUEUE_QUERY_KEY, querySettings],
    queryFn: () => getExtractionData(querySettings),
    placeholderData: keepPreviousData,
  });

  async function retryExtraction(
    ids?: string[],
    allChecked?: boolean,
    delayMs?: number
  ) {
    const response = await apiClient.PATCH("/admin/extraction", {
      body: { ids, delayMs, ...querySettings },
    });

    if (response.error != null) {
      toast.error("Error retrying extraction");
      return;
    }
    if (delayMs != null) {
      toast.success(`Retrying extraction in ${delayMs / 1000 / 60}m`);
    } else {
      toast.success("Retrying extraction");
    }
    await queryClient.invalidateQueries({
      queryKey: [EXTRACTION_QUEUE_QUERY_KEY],
    });
  }

  async function deleteExtraction({ id }: { id: string }) {
    const response = await apiClient.DELETE("/admin/extraction/{id}", {
      params: { path: { id } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Extraction deleted");
    await queryClient.invalidateQueries({
      queryKey: [EXTRACTION_QUEUE_QUERY_KEY],
    });
  }

  return (
    <>
      {selectedItem != null && (
        <Dialog
          open
          onOpenChange={(open) => !open && setSelectedItem(undefined)}
        >
          <DialogContent className="max-h-[80vh] w-auto min-w-[256px] max-w-[80vw] flex flex-col overflow-hidden">
            <DialogHeader>
              <DialogTitle>
                {selectedItem?.raw.title ?? selectedItem?.raw.name ?? "Unknown"}
              </DialogTitle>
              <DialogDescription>
                {
                  getIntegrationConfigByPlatform(
                    selectedItem.integrationPlatform
                  ).display
                }
              </DialogDescription>
            </DialogHeader>
            <div className="overflow-auto bg-muted rounded-md p-4">
              <pre className="whitespace-pre-nowrap text-left">
                {JSON.stringify(selectedItem?.raw, null, 2)}
              </pre>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <TableCard
        query={extractionQuery}
        onUpdate={setQuerySettings}
        searchable
        selectable
        onClick={(row) => setSelectedItem(row)}
        headers={[
          {
            display: "Name",
            key: "raw",
            format: (event: any) => {
              return event.title ?? event.name ?? "Unknown";
            },
          },
          { display: "Team", key: "teamName" },
          {
            display: "Platform",
            key: "integrationPlatform",
            format: (value) => getIntegrationConfigByPlatform(value).display,
          },
        ]}
        tableActions={[
          { display: "Retry", onClick: retryExtraction },
          {
            display: "Retry in 10m",
            onClick: (ids, allChecked) =>
              retryExtraction(ids, allChecked, 1000 * 60 * 10),
          },
        ]}
        rowActions={[
          {
            name: "Retry",
            onClick: (row) => retryExtraction([row.id]),
          },
          {
            name: "Retry in 10m",
            onClick: (row) =>
              retryExtraction([row.id], undefined, 1000 * 60 * 10),
          },
          { name: "Delete", onClick: deleteExtraction },
        ]}
      >
        <CardHeader>
          <CardTitle>Extraction Queue</CardTitle>
          <CardDescription>
            Detections awaiting extraction templates
          </CardDescription>
        </CardHeader>
      </TableCard>
    </>
  );
}
