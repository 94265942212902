import { ConstrainedAppLayout } from "@/components/app-layout";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
  CardContent,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import {
  ExclamationTriangleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import moment from "moment";
import { ReactNode, useMemo, useState } from "react";
import { cn, getDetailsFromRaw } from "@/lib/utils";
import {
  CaseStatus,
  DetectionCategoryConfig,
  getCaseStatusConfigByStatus,
  getIntegrationConfigByPlatform,
  getVerdictConfigByVerdict,
  Verdict,
} from "@wire/shared";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { toast } from "sonner";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { CollapsibleTrigger } from "@radix-ui/react-collapsible";
import {
  calculateTTRMilliseconds,
  calculateTTRSeconds,
  dateTime,
  getTimezone,
} from "@/lib/time";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { components } from "@/lib/api.types";
import CopyToClipboard from "@/components/copy-to-clipboard";
import numeral from "numeral";
import { Badge, BadgeProps } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogDescription,
} from "@/components/ui/dialog";

export const Route = createFileRoute("/_application/cases/$caseId")({
  component: Case,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.caseId));
  },
});

async function getData(caseId: string) {
  const [caseMatch] = await Promise.all([
    apiClient.GET("/cases/{idOrSid}", {
      params: { path: { idOrSid: caseId } },
    }),
  ]);
  if (caseMatch.error != null) {
    throw new Error("Error getting cases information");
  }
  return {
    caseData: caseMatch.data,
  };
}

export const CASES_QUERY_KEY = "case-settings";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
    refetchInterval: 5_000,
  });

function Case() {
  const { caseId } = Route.useParams();
  const { user } = Route.useRouteContext();
  const queryClient = useQueryClient();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const {
    data: { caseData },
  } = useSuspenseQuery(getOptions(caseId));
  const navigate = useNavigate();

  async function reingest() {
    const promise = apiClient.POST("/cases/{caseId}/reingest", {
      params: { path: { caseId: caseData.id } },
    });
    toast.promise(promise, {
      loading: "Reingesting case...",
    });
    const response = await promise;
    if (response.error != null) {
      toast.error("Error reingesting case");
      return;
    }
    await navigate({
      to: "/cases/$caseId",
      params: { caseId: response.data.id },
    });
    toast.success("Case reingested, page has been updated");
  }

  async function contain() {
    if (caseData.testMode) {
      toast.error("Test mode cases cannot be contained");
      return;
    }
    const response = await apiClient.POST("/cases/{caseId}/contain", {
      params: { path: { caseId: caseData.id } },
    });
    if (response.error != null) {
      toast.error("Error containing case");
      return;
    }
    toast.success("Case contained");
    await queryClient.invalidateQueries({ queryKey: [CASES_QUERY_KEY] });
  }
  async function undoContain() {
    if (caseData.testMode) {
      toast.error("Test mode cases cannot be uncontained");
      return;
    }
    const response = await apiClient.POST("/cases/{caseId}/uncontain", {
      params: { path: { caseId: caseData.id } },
    });
    if (response.error != null) {
      toast.error("Error undoing case containment");
      return;
    }
    toast.success("Case containment undone");
    await queryClient.invalidateQueries({ queryKey: [CASES_QUERY_KEY] });
  }

  async function deleteCase() {
    const response = await apiClient.DELETE("/cases/{id}", {
      params: { path: { id: caseData.id } },
    });
    if (response.error != null) {
      toast.error("Error deleting case");
      return;
    }
    toast.warning("Case deleted");
    await navigate({ to: "/cases" });
  }

  async function updateCase(dto: components["schemas"]["UpdateCaseDto"]) {
    const response = await apiClient.PATCH("/cases/{id}", {
      params: { path: { id: caseData.id } },
      body: dto,
    });
    if (response.error != null) {
      toast.error("Error updating case");
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [CASES_QUERY_KEY] });
    if (dto.status) {
      if (dto.status == CaseStatus.CLOSED) {
        toast.success("🧑‍⚖️ Case Closed");
      } else {
        toast.success("Case Updated");
      }
    }
    if (dto.handledCorrectly != null) {
      toast.success("Thank you for your feedback");
    }
  }

  const statisticsText = useMemo(() => {
    let parts: ReactNode[] = [];
    if (caseData.testMode) {
      parts.push(
        <HoverBadge
          variant="outlineWarning"
          key="test-mode"
          text={`Test Mode`}
          hoverText={
            "This case was ingested in test mode, timeline information and statistics will be inaccurate."
          }
        />
      );
    }
    if (caseData.reingested) {
      parts.push(
        <HoverBadge
          variant="outlineWarning"
          key="reingested"
          text={`Reingested`}
          hoverText={
            "This case was reingested, timeline information and statistics will be inaccurate."
          }
        />
      );
    }

    if (
      caseData.sourceDetectedAt &&
      caseData.sourceIngestedAt &&
      caseData.sourceDetectedAt != caseData.sourceIngestedAt
    ) {
      let sourceDelay = moment.duration(
        calculateTTRSeconds(
          caseData.sourceDetectedAt,
          caseData.sourceIngestedAt
        ) * 1000
      );
      let sourceVariant: "destructive" | "outlineSuccess" | "outlineWarning" =
        "destructive";
      if (sourceDelay.asSeconds() < 30) {
        sourceVariant = "outlineSuccess";
      } else if (sourceDelay.asSeconds() < 90) {
        sourceVariant = "outlineWarning";
      }

      parts.push(
        <HoverBadge
          variant={sourceVariant}
          key="source-delay"
          text={`Source delay | ${sourceDelay.humanize()}`}
          hoverText={
            <>
              The time between when the source detects an event and when it is
              published to their API.
              <br />
              <br />
              {numeral(
                calculateTTRSeconds(
                  caseData.sourceDetectedAt,
                  caseData.sourceIngestedAt
                )
              ).format()}{" "}
              seconds
            </>
          }
        />
      );
    }
    /**
     * When showing the RAG colors in the UI for our MTTD|MTTR
     * we want to show them the actual time, but color code based on when the event actually went live in their API.
     * E.g. if it wasn't available for 5 minutes, we don't deserve a red color, that's available in the source delay
     */
    let delayToIngestion =
      moment.duration(
        calculateTTRMilliseconds(
          caseData.sourceDetectedAt,
          caseData.sourceIngestedAt
        )
      ) ?? 0;

    if (caseData.sourceDetectedAt && caseData.verdictedAt) {
      let mttdDuration = moment.duration(
        calculateTTRMilliseconds(
          caseData.sourceDetectedAt,
          caseData.verdictedAt
        )
      );
      let actualMTTD =
        mttdDuration.asMilliseconds() - delayToIngestion.asMilliseconds();
      let variant: "outlineSuccess" | "outlineWarning" | "destructive" =
        "destructive";
      if (actualMTTD < 30_000) {
        variant = "outlineSuccess";
      } else if (actualMTTD < 90_000) {
        variant = "outlineWarning";
      }
      parts.push(
        <HoverBadge
          key="mttd"
          variant={variant}
          text={`MTTD | ${mttdDuration.humanize()}`}
          hoverText={`${numeral(mttdDuration.asSeconds()).format()} seconds`}
        />
      );
    }

    if (caseData.verdictedAt) {
      let verdictDuration = moment.duration(
        calculateTTRMilliseconds(caseData.createdAt, caseData.verdictedAt)
      );
      let variant: "outlineSuccess" | "outlineWarning" | "destructive" =
        "destructive";
      if (verdictDuration.asSeconds() < 30) {
        variant = "outlineSuccess";
      } else if (verdictDuration.asSeconds() < 90) {
        variant = "outlineWarning";
      }
      parts.push(
        <HoverBadge
          key="ttv"
          variant={variant}
          text={
            <>
              Time to Verdict | {verdictDuration.asMilliseconds()}
              ms
            </>
          }
          hoverText={
            <>
              <span>
                Time taken from ingestion into Wirespeed to a verdict being
                made.
              </span>
              <br />
              <br />
              {verdictDuration.asMilliseconds()}ms
            </>
          }
        />
      );
    }
    if (
      caseData.status == CaseStatus.CLOSED &&
      caseData.sourceDetectedAt &&
      caseData.closedAt
    ) {
      let mttrDuration = moment.duration(
        calculateTTRMilliseconds(caseData.sourceDetectedAt, caseData.closedAt)
      );
      let variant: "outlineSuccess" | "outlineWarning" | "destructive" =
        "destructive";
      let actualMTTR =
        mttrDuration.asMilliseconds() - delayToIngestion.asMilliseconds();

      if (actualMTTR < 30_000) {
        variant = "outlineSuccess";
      } else if (actualMTTR < 90_000) {
        variant = "outlineWarning";
      }
      parts.push(
        <HoverBadge
          variant={variant}
          key="mttr"
          text={`MTTR | ${mttrDuration.humanize()}`}
          hoverText={`${numeral(mttrDuration.asSeconds()).format()} seconds`}
        />
      );
    }

    return parts;
  }, [caseData]);

  return (
    <ConstrainedAppLayout>
      <Dialog open={confirmDelete} onOpenChange={setConfirmDelete}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Are you sure you want to delete this case?
            </DialogTitle>
            <DialogDescription>This action cannot be undone</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button onClick={deleteCase} variant="destructive">
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <main className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8">
        {caseData.duplicateCaseId && (
          <Alert className="-mb-4" variant="warning">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              This case was identified as a duplicate. Click{" "}
              <Link
                className="text-blue-500"
                to="/cases/$caseId"
                params={{ caseId: caseData.duplicateCaseId }}
              >
                here
              </Link>{" "}
              to view the original case.
            </AlertDescription>
          </Alert>
        )}
        <Card className="w-full hyphens-auto">
          <CardHeader className="bg-muted/50">
            <div className="flex flex-col gap-0.5 lg:flex-row lg:justify-between">
              <div className="grid gap-0.5">
                <CardTitle className="flex gap-1 flex-row items-end">
                  <span className="leading-none ">{caseData.title}</span>
                  <span className="text-xs leading-none font-medium text-muted-foreground">
                    <CopyToClipboard text={caseData.sid} />
                  </span>
                </CardTitle>
                <CardDescription className="leading-relaxed hyphens-auto flex gap-1 items-center">
                  {caseData.name}
                  <HoverCard openDelay={0} closeDelay={50}>
                    <HoverCardTrigger asChild>
                      <InfoCircledIcon className="h-4 cursor-pointer w-4" />
                    </HoverCardTrigger>
                    <HoverCardContent className="font-normal leading-normal w-auto max-w-[400px]">
                      <h2 className="font-semibold text-lg">Description</h2>
                      <p>{caseData.description}</p>
                    </HoverCardContent>
                  </HoverCard>
                </CardDescription>
              </div>
              <div className="flex flex-col gap-4 lg:flex-row">
                {caseData.contained ? (
                  <Button onClick={undoContain}>Undo Containment</Button>
                ) : caseData.containments.length ? (
                  <Button onClick={contain}>Contain</Button>
                ) : undefined}
                {caseData.status != CaseStatus.CLOSED ? (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline">Close Case</Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() =>
                          updateCase({
                            verdict: Verdict.ACTIONABLE,
                            status: CaseStatus.CLOSED,
                          })
                        }
                      >
                        <div>
                          <h4 className="font-semibold">Close as Actionable</h4>
                          <p className="text-muted-foreground">
                            Case resulted in containment
                          </p>
                        </div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />

                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() =>
                          updateCase({
                            verdict: Verdict.NON_ACTIONABLE,
                            status: CaseStatus.CLOSED,
                          })
                        }
                      >
                        <div>
                          <h4 className="font-semibold">
                            Close as Non-Actionable
                          </h4>
                          <p className="text-muted-foreground">
                            Case did not result in containment
                          </p>
                        </div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />

                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() =>
                          updateCase({
                            verdict: Verdict.UNSURE,
                            status: CaseStatus.CLOSED,
                          })
                        }
                      >
                        <div>
                          <h4 className="font-semibold">Close as Unsure</h4>
                          <p className="text-muted-foreground">
                            Case was handled in a unique manner
                          </p>
                        </div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                ) : (
                  <Button
                    variant="outline"
                    onClick={() =>
                      updateCase({ status: CaseStatus.PROCESSING })
                    }
                  >
                    Reopen Case
                  </Button>
                )}
                {user.superAdmin && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline">Actions</Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-w-64">
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={reingest}
                      >
                        <div>
                          <h4 className="font-semibold">Reingest</h4>
                          <p className="text-muted-foreground">
                            Reprocess this case and potentially trigger user
                            interactions
                          </p>
                        </div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />

                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => setConfirmDelete(true)}
                      >
                        <div>
                          <h4 className="font-semibold">Delete</h4>
                          <p className="text-muted-foreground">
                            Permanently delete this case and all associated data
                          </p>
                        </div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>
            <div className="flex flex-wrap gap-2">{statisticsText}</div>
          </CardHeader>
          <CardContent className="mt-4">
            <h2 className="font-semibold">What Happened</h2>
            <div
              className="text-sm [&_a]:text-blue-500 normal-lists"
              dangerouslySetInnerHTML={{ __html: caseData.whatHappened! }}
            ></div>
            <h2 className="font-semibold mt-4">Next Steps</h2>
            <div
              className="text-sm [&_a]:text-blue-500 normal-lists"
              dangerouslySetInnerHTML={{ __html: caseData.nextSteps! }}
            ></div>

            <Collapsible>
              <CollapsibleTrigger className="flex mt-4 group items-center space-x-2 w-full">
                <div className="border-t flex-1"></div>
                <div className="text-xs group-hover:bg-muted p-2 rounded-md text-muted-foreground">
                  Click to view metadata
                </div>
                <div className="border-t flex-1"></div>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <ul className="grid gap-3 text-sm">
                  <li className="flex items-center justify-between">
                    <span className="text-muted-foreground">Category</span>
                    <span>
                      {DetectionCategoryConfig[caseData.category]?.display}
                    </span>
                  </li>
                  <li className="flex items-center justify-between">
                    <span className="text-muted-foreground">Status</span>
                    <span>
                      {getCaseStatusConfigByStatus(caseData.status)?.display}
                    </span>
                  </li>
                  {caseData.sourceIngestedAt && (
                    <li className="flex items-center justify-between">
                      <span className="text-muted-foreground">
                        Created At{" "}
                        <span className="text-xs">({getTimezone()})</span>
                      </span>
                      <span>{dateTime(caseData.sourceIngestedAt)} </span>
                    </li>
                  )}
                  <li className="flex items-center justify-between">
                    <span className="text-muted-foreground">
                      Closed At{" "}
                      <span className="text-xs">({getTimezone()})</span>
                    </span>
                    <span>
                      {caseData.closedAt ? dateTime(caseData.closedAt) : "-"}{" "}
                    </span>
                  </li>
                  <li className="flex items-center justify-between">
                    <span className="text-muted-foreground">Verdict</span>
                    <span>
                      {getVerdictConfigByVerdict(caseData.verdict)?.display}
                    </span>
                  </li>
                  <li className="flex items-center justify-between">
                    <span className="text-muted-foreground">Source</span>
                    <span>
                      <Link
                        to="/settings/integrations/$integrationId"
                        className="text-blue-500 hover:underline"
                        params={{ integrationId: caseData.integrationId }}
                      >
                        {
                          getIntegrationConfigByPlatform(
                            caseData.integrationPlatform
                          ).display
                        }
                      </Link>
                    </span>
                  </li>
                  <ExtractionSection caseData={caseData} />
                </ul>
              </CollapsibleContent>
            </Collapsible>
          </CardContent>
        </Card>

        <Card className="flex-1">
          <CardHeader className="bg-muted/50 flex gap-4 flex-col lg:flex-row lg:items-center lg:justify-between">
            <div>
              <CardTitle>Timeline</CardTitle>
              <CardDescription>
                Below is a log of every decision made by Wirespeed
              </CardDescription>
            </div>
            <div className="flex gap-2">
              {user.superAdmin ? (
                <CaseAQLSelector
                  refresh={() =>
                    queryClient.invalidateQueries({
                      queryKey: [CASES_QUERY_KEY],
                    })
                  }
                  caseId={caseData.id}
                />
              ) : (
                <>
                  <HoverCard openDelay={500}>
                    <HoverCardTrigger asChild>
                      <Button
                        onClick={() => updateCase({ handledCorrectly: true })}
                        variant="outline"
                        className="p-2"
                      >
                        <HandThumbUpIcon className="h-4 w-4" />
                      </Button>
                    </HoverCardTrigger>
                    <HoverCardContent className="text-sm">
                      This case was handled correctly.
                    </HoverCardContent>
                  </HoverCard>
                  <HoverCard openDelay={500}>
                    <HoverCardTrigger asChild>
                      <Button
                        onClick={() => updateCase({ handledCorrectly: false })}
                        variant="outline"
                        className="p-2"
                      >
                        <HandThumbDownIcon className="h-4 w-4" />
                      </Button>
                    </HoverCardTrigger>
                    <HoverCardContent className="text-sm">
                      This case was handled incorrectly.
                    </HoverCardContent>
                  </HoverCard>
                </>
              )}
            </div>
          </CardHeader>
          <CardContent className="mt-4 overflow-auto">
            <ul role="list" className="space-y-6 break-all">
              {caseData.logs.map((log, idx) => (
                <li
                  key={`case-${caseData.id}-logs-${idx}`}
                  className="relative flex gap-x-4"
                >
                  <div
                    className={cn(
                      idx === caseData.logs.length - 1 ? "h-6" : "-bottom-6",
                      "absolute left-0 top-0 flex w-6 justify-center"
                    )}
                  >
                    <div className="w-px bg-muted" />
                  </div>

                  <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
                      <div className="h-1.5 w-1.5 rounded-full bg-muted ring-1 ring-gray-300" />
                    </div>
                    <div className="flex flex-col  lg:flex-row lg:justify-between w-full">
                      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                        {log.log}
                      </p>
                      <time
                        dateTime={log.timestamp}
                        className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                      >
                        {dateTime(log.timestamp)}
                      </time>
                    </div>
                  </>
                </li>
              ))}
            </ul>
          </CardContent>
          <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
            <div className="text-xs text-muted-foreground">
              All times {getTimezone()}
            </div>
          </CardFooter>
        </Card>

        <Card className="overflow-hidden">
          <CardHeader className="flex flex-row bg-muted/50 items-start">
            <CardTitle>Original Event Details</CardTitle>
          </CardHeader>
          <CardContent className="p-6 text-sm border-t">
            <div className="grid gap-y-2 gap-x-8 grid-cols-1 xl:grid-cols-2 mt-2">
              {...getDetailsFromRaw(caseData.raw)}
            </div>
          </CardContent>
          <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
            <div className="text-xs text-muted-foreground">
              All times {getTimezone()}
            </div>
          </CardFooter>
        </Card>
      </main>
    </ConstrainedAppLayout>
  );
}

function HoverBadge(props: {
  text: string | ReactNode;
  hoverText: string | ReactNode;
  variant: BadgeProps["variant"];
}) {
  return (
    <HoverCard openDelay={50} closeDelay={50}>
      <HoverCardTrigger>
        <Badge className="cursor-pointer" variant={props.variant}>
          {props.text}
        </Badge>
      </HoverCardTrigger>
      <HoverCardContent>{props.hoverText}</HoverCardContent>
    </HoverCard>
  );
}

function ExtractionSection(props: {
  caseData: components["schemas"]["CaseWithEntities"];
}) {
  let sections: ReactNode[] = [];
  if (props.caseData.endpoints?.length) {
    for (const endpoint of props.caseData.endpoints) {
      sections.push(
        <li key={endpoint.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">Endpoint</span>
          <span>
            <Link
              className="line-clamp-1 text-blue-500 text-sm hover:underline"
              to="/assets/endpoints/$endpointId"
              params={{ endpointId: endpoint.id }}
            >
              {endpoint.name}
            </Link>
          </span>
        </li>
      );
    }
  }
  if (props.caseData.directory?.length) {
    for (const user of props.caseData.directory) {
      sections.push(
        <li key={user.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">User</span>
          <span>
            <Link
              className="line-clamp-1 break-all text-blue-500 text-sm hover:underline"
              to="/assets/users/$userId"
              params={{ userId: user.id }}
            >
              {user.name ?? user.email ?? user.id}
            </Link>
          </span>
        </li>
      );
    }
  }
  if (props.caseData.tools?.length) {
    for (const tool of props.caseData.tools) {
      sections.push(
        <li key={tool.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">Tool</span>
          <span>
            <Link
              className="line-clamp-1 break-all text-blue-500 text-sm hover:underline"
              to="/assets/tools/$toolId"
              params={{ toolId: tool.id }}
            >
              {tool.name}
            </Link>
          </span>
        </li>
      );
    }
  }
  if (props.caseData.processes?.length) {
    for (const process of props.caseData.processes) {
      sections.push(
        <li key={process.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">Process</span>
          <span>
            <Link
              className="line-clamp-1 break-all text-blue-500 text-sm hover:underline"
              to="/assets/processes/$processId"
              params={{ processId: process.id }}
            >
              {process.command}
            </Link>
          </span>
        </li>
      );
    }
  }
  if (props.caseData.files?.length) {
    for (const file of props.caseData.files) {
      sections.push(
        <li key={file.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">File</span>
          <span>
            <Link
              className="line-clamp-1 break-all text-blue-500 text-sm hover:underline"
              to="/assets/files/$fileId"
              params={{ fileId: file.id }}
            >
              {file.name}
            </Link>
          </span>
        </li>
      );
    }
  }
  if (props.caseData.domains?.length > 0) {
    for (const domain of props.caseData.domains) {
      sections.push(
        <li key={domain.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">Domain</span>
          <span>
            <Link
              className="line-clamp-1 break-all text-blue-500 text-sm hover:underline"
              to="/assets/domains/$domainId"
              params={{ domainId: domain.id }}
            >
              {domain.name}
            </Link>
          </span>
        </li>
      );
    }
  }
  if (props.caseData.locations?.length) {
    for (const location of props.caseData.locations) {
      sections.push(
        <li key={location.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">Location</span>
          <span>
            <Link
              className="line-clamp-1 break-all text-blue-500 text-sm hover:underline"
              to="/assets/locations/$locationId"
              params={{ locationId: location.id }}
            >
              {location.city}, {location.state}
            </Link>
          </span>
        </li>
      );
    }
  }
  if (props.caseData.ips?.length) {
    for (const ip of props.caseData.ips) {
      sections.push(
        <li key={ip.id} className="flex items-center justify-between">
          <span className="text-muted-foreground">IP Address</span>
          <span>
            <Link
              className="line-clamp-1 break-all text-blue-500 text-sm hover:underline"
              to="/assets/ips/$ipId"
              params={{ ipId: ip.id }}
            >
              {ip.ipv4 ?? ip.ipv6}
            </Link>
          </span>
        </li>
      );
    }
  }
  return sections;
}

function CaseAQLSelector(props: { caseId: string; refresh: () => void }) {
  async function reportAQLSample(defectLevel: string) {
    const response = await apiClient.PUT("/aql/case/{id}/sample", {
      params: { path: { id: props.caseId } },
      body: { defectLevel: defectLevel as any },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    props.refresh();
    toast.success("AQL sample reported");
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">AQL</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-w-64">
        <DropdownMenuLabel>Defect level</DropdownMenuLabel>
        <DropdownMenuSeparator />

        <DefectOption
          defectLevel="None"
          description="Everything looks great"
          onClick={() => reportAQLSample("NONE")}
        />
        <DropdownMenuSeparator />
        <DefectOption
          defectLevel="Minor"
          description="False positive, we alerted when we shouldn't have"
          onClick={() => reportAQLSample("MINOR")}
        />
        <DropdownMenuSeparator />
        <DefectOption
          defectLevel="Major"
          description="Correlated to the wrong category, but the outcome was not a false negative."
          onClick={() => reportAQLSample("MAJOR")}
        />
        <DropdownMenuSeparator />
        <DefectOption
          defectLevel="Critical"
          description="False negative, we didn't alert when we should've"
          onClick={() => reportAQLSample("CRITICAL")}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function DefectOption(props: {
  defectLevel: string;
  description: string;
  onClick: () => void;
}) {
  return (
    <DropdownMenuItem className="cursor-pointer" onClick={props.onClick}>
      <div>
        <h4 className="font-semibold">{props.defectLevel}</h4>
        <p className="text-muted-foreground">{props.description}</p>
      </div>
    </DropdownMenuItem>
  );
}
