import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { Dialog, DialogTrigger, DialogContent } from "@radix-ui/react-dialog";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useState } from "react";
import { ConstrainedAppLayout } from "@/components/app-layout";
import moment from "moment";

export const Route = createFileRoute("/_application/assets/domains/")({
  component: Domains,
  loader: async ({ context, deps }) => {
    await context.queryClient.ensureQueryData(getOptions());
  },
});

function getOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [PROCESSES_QUERY_KEY, settings],
    queryFn: () => getDomains(settings),
    placeholderData: keepPreviousData,
  });
}

async function getDomains(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/domain", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting domains");
  }

  return response.data;
}

const PROCESSES_QUERY_KEY = "settings-domains";

export default function Domains() {
  const queryClient = useQueryClient();
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useSuspenseQuery(getOptions(searchSettings));

  if (query.isLoading) {
    return <Skeleton className="w-full h-[256px]" />;
  }

  return (
    <ConstrainedAppLayout>
      <TableCard
        onUpdate={updateSearchSettings}
        query={query}
        searchable
        onClick={(row) =>
          navigate({
            to: "/assets/domains/$domainId",
            params: { domainId: row.id },
          })
        }
        compact
        headers={[
          { display: "Name", key: "name", sortable: true },
          {
            display: "Created at",
            key: "createdAt",
            sortable: true,
            format: (value, row) => {
              return moment(value).format("MMM Do YYYY");
            },
          },
        ]}
      >
        <CardHeader>
          <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
            <div className="flex flex-col gap-2">
              <CardTitle>Domains</CardTitle>
              <CardDescription>
                Domains that have been extracted from detections in your
                environment.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
      </TableCard>
    </ConstrainedAppLayout>
  );
}
