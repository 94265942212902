import { apiClient } from "@/lib/api";
import { FormEvent } from "react";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

export default function HIBP(props: { onComplete: () => void }) {
  async function addIntegration(e: FormEvent) {
    e.preventDefault();
    const response = await apiClient.PUT("/integration/hibp");

    if (response.error != null) {
      toast.error("Error adding integration");
    } else {
      toast.success("Integration added");
      props.onComplete();
    }
  }

  return (
    <div>
      <div>
        <form onSubmit={addIntegration}>
          <Button type="submit" className="mt-4">
            Integrate Have I Been Pwned
          </Button>
        </form>
      </div>
    </div>
  );
}
