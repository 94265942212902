"use client";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { useState } from "react";
import { toast } from "sonner";

export default function HealthCheckIntegration({
  integration,
  onComplete,
}: {
  integration: components["schemas"]["Integration"];
  onComplete: () => void;
}) {
  async function check() {
    const response = apiClient.POST("/integration/{id}/health", {
      params: { path: { id: integration.id } },
    });
    toast.promise(response, {
      loading: "Checking health...",
      success: "Health check performed",
      error: "Error performing health check",
    });
    await response;
    onComplete();
  }
  return <Button onClick={check}>Health Check</Button>;
}
