import {
  Link,
  SearchSchemaInput,
  createFileRoute,
} from "@tanstack/react-router";

import { ConstrainedAppLayout } from "@/components/app-layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { apiClient } from "@/lib/api";
import ProfileSettings from "@/components/settings/profile";
import TeamSettings from "@/components/settings/team";
import IntegrationSettings from "@/components/settings/integration";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import NotificationSettings from "@/components/settings/notifications";
import AdminIntegrations from "@/components/admin-settings/integrations";
import Queues from "@/components/admin-settings/queues";
import AdminTeamSettings from "@/components/admin-settings/team";
import ExtractionQueue from "@/components/admin-settings/extraction-queue";
import { Button } from "@/components/ui/button";
import AdminAQL from "@/components/admin-settings/aql/aql";

export const Route = createFileRoute("/_application/admin/")({
  component: AdminSettings,
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getOptions());
  },
  validateSearch: (
    search: {
      tab?: "integrations" | "queues" | "team" | "extraction" | "aql";
    } & SearchSchemaInput
  ) => {
    return {
      tab: search.tab ?? "queues",
    };
  },
});

async function getSettings() {
  const [integrations, queues] = await Promise.all([
    apiClient.GET("/admin/integration"),
    apiClient.GET("/admin/queue"),
  ]);
  if (integrations.error != null || queues.error != null) {
    throw new Error("Error getting settings information");
  }
  return {
    integrations: integrations.data,
    queues: queues.data,
  };
}

export const ADMIN_SETTINGS_QUERY_KEY = "admin-settings";
const getOptions = () =>
  queryOptions({
    queryKey: [ADMIN_SETTINGS_QUERY_KEY],
    queryFn: getSettings,
  });

export function AdminSettings() {
  const {
    data: { integrations, queues },
  } = useSuspenseQuery(getOptions());
  const { tab } = Route.useSearch();

  return (
    <ConstrainedAppLayout>
      <Card className="bg-inherit border-none shadow-none">
        <CardHeader className="flex flex-col lg:flex-row lg:justify-between">
          <CardTitle className="text-2xl">Admin Settings</CardTitle>
          <Button variant="outline">
            <Link to="/user-onboarding/$step" params={{ step: "1" }}>
              Go to onboarding
            </Link>
          </Button>
        </CardHeader>
        <CardContent>
          <Tabs
            orientation="vertical"
            value={tab}
            className="grid grid-cols-1 w-full mx-auto gap-4 lg:grid-cols-4"
          >
            <TabsList className="col-span-1 flex-col justify-start items-start space-y-2 h-auto bg-inherit">
              <TabsTrigger value="queues" asChild>
                <Link to="." search={{ tab: "queues" }}>
                  Queues
                </Link>
              </TabsTrigger>
              <TabsTrigger value="integrations" asChild>
                <Link to="." search={{ tab: "integrations" }}>
                  Integrations
                </Link>
              </TabsTrigger>
              <TabsTrigger value="team" asChild>
                <Link to="." search={{ tab: "team" }}>
                  Create Team
                </Link>
              </TabsTrigger>
              <TabsTrigger value="extraction" asChild>
                <Link to="." search={{ tab: "extraction" }}>
                  Extraction Queue
                </Link>
              </TabsTrigger>
              <TabsTrigger value="aql" asChild>
                <Link to="." search={{ tab: "aql" }}>
                  AQL
                </Link>
              </TabsTrigger>
            </TabsList>
            <div className="col-span-3">
              <TabsContent value="queues">
                <Queues queues={queues} />
              </TabsContent>
              <TabsContent value="integrations">
                <AdminIntegrations integrations={integrations} />
              </TabsContent>
              <TabsContent value="team">
                <AdminTeamSettings />
              </TabsContent>
              <TabsContent value="extraction">
                <ExtractionQueue />
              </TabsContent>
              <TabsContent value="aql">
                <AdminAQL />
              </TabsContent>
            </div>
          </Tabs>
        </CardContent>
      </Card>
    </ConstrainedAppLayout>
  );
}
