"use client";
import { components } from "@/lib/api.types";
import moment from "moment";
import { apiClient } from "@/lib/api";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { TableCard } from "../table-card";
import { getIntegrationConfigByPlatform } from "../../../../../packages/shared/types/integration.types";
import { FetchResponse } from "openapi-fetch";
import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import AddIntegration from "./add-integration";
import {
  NOTIFICATION_FREQUENCY_CONFIG_LIST,
  NOTIFICATION_TYPE_CONFIG_LIST,
  NOTIFICATION_TYPE_LIST,
  NotificationFrequency,
  NotificationType,
  getNotificationTypeConfig,
} from "@wire/shared";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

async function getNotificationSubscriptions() {
  const response = await apiClient.GET("/notification/subscription");
  if (response.error != null) {
    throw new Error("Error getting notfication subscriptions");
  }
  return response.data;
}

const NOTIFICATION_QUERY = "settings-notifications";

export default function NotificationSettings() {
  const queryClient = useQueryClient();
  const notificationQuery = useQuery({
    queryKey: [NOTIFICATION_QUERY],
    queryFn: getNotificationSubscriptions,
    placeholderData: keepPreviousData,
  });

  const notifications = useMemo(() => {
    if (notificationQuery.data == null) return;
    let out = [];
    const notificationTypes = NOTIFICATION_TYPE_LIST;
    for (const type of notificationTypes) {
      const config = getNotificationTypeConfig(type);
      const found = notificationQuery.data.find((v) => v.type == type);
      out.push({
        type: type,
        display: config.display,
        description: config.description,
        id: found?.id,
        frequency: found?.frequency,
      });
    }
    return out;
  }, [notificationQuery.data]);

  async function toggleNotificationSubscription(
    type: NotificationType,
    id?: string
  ) {
    let response: FetchResponse<any, any, any>;
    if (id != null) {
      response = await apiClient.DELETE("/notification/subscription/{id}", {
        params: { path: { id: id } },
      });
    } else {
      response = await apiClient.PUT("/notification/subscription", {
        body: {
          type,
          frequency: NotificationFrequency.DAILY,
        },
      });
    }
    if (response.error != null) {
      return toast.error("Error updating notification subscription");
    }
    if (id != null) {
      toast.warning("Unsubscribed from notification");
    } else {
      toast.success("Subscribed to notification");
    }
    await queryClient.invalidateQueries({ queryKey: [NOTIFICATION_QUERY] });
  }

  async function updateNotificationSubscriptionFrequency(
    id: string,
    frequency: NotificationFrequency
  ) {
    const response = await apiClient.PATCH("/notification/subscription/{id}", {
      params: { path: { id: id } },
      body: { frequency },
    });
    if (response.error != null) {
      toast.error("Error updating notification frequency");
    } else {
      toast.success("Notification subscription frequency updated");
    }
    await queryClient.invalidateQueries({ queryKey: [NOTIFICATION_QUERY] });
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Notification Settings</CardTitle>
        <CardDescription>
          Manage notification subscriptions and frequencies
        </CardDescription>
        <CardContent className="p-0">
          <ul className="grid mt-4 gap-4">
            {notifications?.map((notification) => (
              <li
                className="border flex flex-col space-y-4  rounded-md p-4"
                key={notification.type}
              >
                <div>
                  <div>
                    <h2 className="font-bold">{notification.display}</h2>
                    <p className="text-muted-foreground">
                      {notification.description}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:justify-between">
                  <div className="flex flex-col space-y-2">
                    <Label className="text-muted-foreground">
                      Notification Subscription
                    </Label>
                    <Switch
                      onCheckedChange={() =>
                        toggleNotificationSubscription(
                          notification.type,
                          notification.id
                        )
                      }
                      checked={!!notification.id}
                    />
                  </div>
                  <div>
                    <div className="flex flex-col space-y-2">
                      <Label className="text-muted-foreground">
                        Notification Frequency
                      </Label>
                      <Select
                        key={`${notification.type}-${notification.id}`}
                        disabled={!notification.id}
                        defaultValue={notification.frequency}
                        onValueChange={(e) =>
                          updateNotificationSubscriptionFrequency(
                            notification.id!,
                            e as NotificationFrequency
                          )
                        }
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select Frequency" />
                        </SelectTrigger>
                        <SelectContent>
                          {NOTIFICATION_FREQUENCY_CONFIG_LIST.map((v) => (
                            <SelectItem key={v.type} value={v.type}>
                              {v.display}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
      </CardHeader>
    </Card>
  );
}
