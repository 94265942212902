import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "@/components/ui/pagination";
import {
  Table,
  TableHeader as UITableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@/components/ui/table";
import { cn, ellipsisTruncate, pluralize, useDebounce } from "@/lib/utils";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EllipsisHorizontalIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { UseQueryResult, UseSuspenseQueryResult } from "@tanstack/react-query";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Skeleton } from "./ui/skeleton";
import { CheckIcon, XMarkIcon } from "@heroicons/react/16/solid";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Input } from "./ui/input";
import { Checkbox } from "./ui/checkbox";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { components } from "@/lib/api.types";

const PAGE_SIZE = 10;
interface RowAction<T> {
  name: string | ((row: T) => string);
  shouldDisplay?: (row: T) => boolean;
  onClick: (row: T) => Promise<void> | void | Promise<any>;
  disabled?: boolean;
  confirm?: boolean;
  confirmMessage?: string | ((row: T) => string);
}

interface TableHeader<T> {
  key: keyof T;
  display: string;
  info?: string;
  hiddenOnMobile?: boolean;
  truncate?: number;
  // Should we show the column value in a popover when hovered
  hover?: boolean;
  sortable?: boolean;
  format?: (value: any, row: T) => ReactNode | string;
}

export interface TableAction {
  display: string;
  showOnSearch?: boolean;
  onClick: (ids: string[], allSelected?: boolean) => Promise<void>;
}

interface SearchFilter {
  placeholder: string;
  defaultValue: string;
  label: string;
  values: { key: string; display: string }[];
  onSelect: (key: string) => void;
}

interface TableCardProps<T> {
  query:
    | UseSuspenseQueryResult<{ totalCount: number; data: readonly T[] }, Error>
    | UseQueryResult<{ totalCount: number; data: readonly T[] }, Error>;
  onUpdate: (data: components["schemas"]["PaginationDto"]) => void;
  onClick?: (row: T) => void;
  searchable?: boolean;
  defaultPage?: number;
  defaultSearch?: string;
  orderBy?: string;
  orderByDir?: "asc" | "desc";
  compact?: boolean;
  selectable?: boolean;
  embedded?: boolean; // Hide the card styling
  searchFilters?: SearchFilter[];
  tableActions?: TableAction[];
  rowActions?: RowAction<T>[];
  headers: TableHeader<T>[];
}

export function TableCard<T extends { id: string }>(
  props: React.PropsWithChildren<TableCardProps<T>>
) {
  const [page, setPage] = useState(props.defaultPage ?? 1);
  const rowData = useMemo(() => {
    return props.query?.data?.data;
  }, [props.query?.data]);
  const totalCount = useMemo(() => {
    return props.query.data?.totalCount ?? 0;
  }, [props.query.data?.totalCount]);
  const [search, setSearch] = useState(props.defaultSearch ?? "");
  const { debounced: debouncedSearch } = useDebounce(250, search);
  const [checkedIDs, setCheckedIDs] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const showPrevButton = useMemo(() => {
    return page > 1;
  }, [page]);
  const showNextButton = useMemo(() => {
    return (props.query.data?.totalCount ?? 0) > page * PAGE_SIZE;
  }, [page, props.query.data]);
  const [selectedAction, setSelectedAction] = useState<RowAction<T>>();

  useEffect(() => {
    if (props.defaultPage != null && page != props.defaultPage) {
      setPage(props.defaultPage);
    }
  }, [props.defaultPage]);

  useEffect(() => {
    props.defaultSearch &&
      search != props.defaultSearch &&
      setSearch(props.defaultSearch);
  }, [props.defaultSearch]);

  useEffect(() => {
    props.onUpdate?.({
      page,
      size: PAGE_SIZE,
      search:
        debouncedSearch != null && debouncedSearch.length > 0
          ? debouncedSearch
          : "",
    });
  }, [page, debouncedSearch]);

  function toggleRow(id: string) {
    let index = checkedIDs.findIndex((v) => v == id);
    if (index > -1) {
      setCheckedIDs(checkedIDs.filter((v) => v != id));
    } else {
      setCheckedIDs([...checkedIDs, id]);
    }
  }
  return (
    <Card
      className={cn("w-full", {
        "p-0 shadow-none border-none": props.embedded,
      })}
    >
      {props.children}
      <CardContent>
        {props.query.isLoading && (
          <Skeleton className="h-full min-h-[256px] w-full" />
        )}
        {!props.query.isLoading && props.searchable && (
          <div className="flex flex-col mb-2 lg:items-end space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4">
            {props.searchFilters != null &&
              props.searchFilters.map((filter) => (
                <div key={filter.label}>
                  <Label className="text-xs font-normal text-muted-foreground">
                    {filter.label}
                  </Label>
                  <Select
                    defaultValue={filter.defaultValue}
                    onValueChange={(e) => {
                      filter.onSelect(e);
                      setCheckedIDs([]);
                    }}
                  >
                    <SelectTrigger className="w-auto min-w-[180px]">
                      <SelectValue placeholder={filter.placeholder} />
                    </SelectTrigger>
                    <SelectContent>
                      {filter.values.map((v) => (
                        <SelectItem key={v.key} value={v.key}>
                          {v.display}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              ))}
            <Input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Search"
            />
          </div>
        )}
        {rowData != null &&
          rowData.length == 0 &&
          (props.query.isFetched || props.query.isFetching) && (
            <div className="flex flex-col items-center text-muted-foreground">
              <img
                src="/illustrations/empty-adventure.svg"
                className="w-auto max-w-[256px] h-auto mt-4"
              />
              <h3 className="text-xl mt-8">No results found</h3>
            </div>
          )}
        {rowData != null && rowData.length > 0 && (
          <div className={"border rounded-md shadow-sm"}>
            <Table>
              <UITableHeader>
                <TableRow>
                  {props.selectable && (
                    <TableHead key="checkbox">
                      <Checkbox
                        className="border-muted"
                        checked={allChecked}
                        onCheckedChange={(checked) =>
                          setAllChecked(checked.valueOf() as boolean)
                        }
                      />
                    </TableHead>
                  )}
                  {props.headers.map((v) => {
                    return (
                      <TableHeader<T>
                        defaultDir={
                          props.orderBy == v.key ? props.orderByDir : undefined
                        }
                        key={v.display}
                        header={v}
                        onUpdate={props.onUpdate}
                      />
                    );
                  })}
                  {props.rowActions != null && props.rowActions.length > 0 && (
                    <TableHead key="actions" className="w-8"></TableHead>
                  )}
                </TableRow>
              </UITableHeader>
              <TableBody>
                {rowData.map((row) => (
                  <TableRow
                    onClick={() => props.onClick?.(row)}
                    className={cn("group", {
                      "cursor-pointer": props.onClick != null,
                    })}
                    key={row.id}
                  >
                    {props.selectable && (
                      <TableCell
                        key={`${row["id"]}-checkbox`}
                        className={"font-medium"}
                      >
                        <Checkbox
                          checked={checkedIDs.includes(row.id) || allChecked}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleRow(row.id);
                          }}
                          className="border-muted"
                        />
                      </TableCell>
                    )}
                    {props.headers.map((header) => {
                      // TODO: Abstract all this into a better place

                      return (
                        <TableCellValue
                          hover={header.hover}
                          key={`${header.display}-${row.id}`}
                          className={cn(
                            { "whitespace-nowrap": props.compact },
                            { "hidden lg:table-cell": header.hiddenOnMobile }
                          )}
                          value={
                            header.format != null
                              ? header.format(row[header.key], row)
                              : row[header.key] ?? "-"
                          }
                          truncate={header.truncate}
                        />
                      );
                    })}
                    {props.rowActions != null &&
                      props.rowActions.length > 0 && (
                        <TableRowAction
                          row={row}
                          rowActions={props.rowActions}
                          selectedAction={selectedAction}
                          setSelectedAction={setSelectedAction}
                          key={`${row.id}-actions`}
                        />
                      )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
      <CardFooter>
        {totalCount > 0 && (
          <div className="text-xs text-muted-foreground">
            Showing{" "}
            <strong>
              {(page - 1) * PAGE_SIZE + 1}-
              {(page - 1) * PAGE_SIZE + (props.query.data?.data.length ?? 0)}
            </strong>{" "}
            of <strong>{totalCount}</strong> {pluralize(totalCount, "item")}
          </div>
        )}
        <div className="ml-auto mr-0 w-auto flex flex-col space-y-2 lg:space-y-0 lg:space-x-2 lg:flex-row">
          <div className="flex flex-col lg:flex-row gap-2">
            {props.tableActions?.map(
              (action) =>
                (checkedIDs.length > 0 ||
                  allChecked ||
                  (action.showOnSearch && debouncedSearch)) && (
                  <Button
                    key={action.display}
                    onClick={() => {
                      void action.onClick(
                        checkedIDs,
                        checkedIDs.length == props.query.data?.totalCount
                      );
                    }}
                  >
                    {action.display}
                  </Button>
                )
            )}
          </div>
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <Button
                  onClick={() => setPage(page - 1)}
                  disabled={!showPrevButton}
                  size="icon"
                  variant="outline"
                >
                  <ChevronLeftIcon className="h-3.5 w-3.5" />
                  <span className="sr-only">Previous Item</span>
                </Button>
              </PaginationItem>
              <PaginationItem>
                <Button
                  disabled={!showNextButton}
                  onClick={() => setPage(page + 1)}
                  size="icon"
                  variant="outline"
                >
                  <ChevronRightIcon className="h-3.5 w-3.5" />
                  <span className="sr-only">Next Item</span>
                </Button>
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      </CardFooter>
    </Card>
  );
}

function TableHeader<T>(props: {
  header: TableHeader<T>;
  defaultDir?: "asc" | "desc";
  onUpdate: TableCardProps<T>["onUpdate"];
}) {
  const [sortDir, setSortDir] = useState<"asc" | "desc" | undefined>(
    props.defaultDir
  );

  useEffect(() => {
    setSortDir(props.defaultDir);
  }, [props.defaultDir]);

  function sort() {
    let dir = sortDir;
    if (dir == null) {
      dir = "desc";
    } else if (sortDir == "desc") {
      dir = "asc";
    } else if (sortDir == "asc") {
      dir = undefined;
    }
    setSortDir(dir);
    if (dir == null) {
      props.onUpdate({ orderBy: undefined, orderDir: undefined });
    } else {
      props.onUpdate({ orderBy: props.header.key as string, orderDir: dir });
    }
  }

  return (
    <TableHead
      className={cn({
        "hidden lg:table-cell": props.header.hiddenOnMobile,
        "hover:bg-muted cursor-pointer": props.header.sortable,
      })}
      key={props.header.display}
      onClick={props.header.sortable ? () => sort() : undefined}
    >
      <div className="flex whitespace-nowrap items-center">
        {sortDir && (
          <ChevronUpIcon
            className={cn("h-4 w-4", { "rotate-180": sortDir == "desc" })}
          />
        )}
        {props.header.display}{" "}
        {props.header.info && (
          <HoverCard openDelay={0} closeDelay={50}>
            <HoverCardTrigger className="ml-1">
              <InformationCircleIcon className="h-4 w-4" />
            </HoverCardTrigger>
            <HoverCardContent className="whitespace-normal font-normal">
              {props.header.info}
            </HoverCardContent>
          </HoverCard>
        )}
      </div>
    </TableHead>
  );
}

function TableCellValue(props: {
  value: any;
  className?: string;
  hover?: boolean;
  truncate?: number;
}) {
  let value = props.value;
  let className = "";
  if (typeof value == "boolean") {
    if (value) {
      value = <CheckIcon className="text-green-500 h-4 w-4" />;
    } else {
      value = <XMarkIcon className="text-red-500 h-4 w-4" />;
    }
  }
  if (Array.isArray(value)) {
    value = value.join(", ");
  }
  let truncatedValue =
    props.truncate != null && props.truncate
      ? ellipsisTruncate(value, props.truncate)
      : value;

  if (
    typeof value == "string" &&
    ((props.truncate != null &&
      props.truncate &&
      value.length > props.truncate) ||
      props.hover)
  ) {
    // This way lists are displayed separated by newlines on hover
    let hoverValue: string | ReactNode = value;
    if (Array.isArray(props.value)) {
      hoverValue = props.value.map((v) => (
        <>
          {v}
          <br />
        </>
      ));
    }
    value = (
      <HoverCard key={Math.random()} openDelay={50} closeDelay={50}>
        <HoverCardTrigger asChild>
          <span>{truncatedValue}</span>
        </HoverCardTrigger>
        <HoverCardContent
          key={Math.random()}
          className="w-auto max-w-[350px] text-wrap break-all"
        >
          {hoverValue}
        </HoverCardContent>
      </HoverCard>
    );
  }
  return (
    <TableCell className={cn(className, props.className)}>{value}</TableCell>
  );
}

function TableRowAction<T>(props: {
  rowActions: RowAction<T>[];
  row: T;
  setSelectedAction: (row?: RowAction<T>) => void;
  selectedAction?: RowAction<T>;
}) {
  const [open, setOpen] = useState(false);

  return (
    <TableCell
      onClick={(e) => e.stopPropagation()}
      className={cn("group-hover:sticky group-hover:right-0", {
        "sticky right-0": open,
      })}
    >
      <Dialog>
        <DropdownMenu onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <Button size="icon" variant="outline">
              <EllipsisHorizontalIcon className="h-4 w-4" />
              <span className="sr-only">Actions</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent updatePositionStrategy="always" align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            {props.rowActions
              .map((action) => {
                let actionName =
                  typeof action.name == "function"
                    ? action.name(props.row)
                    : action.name;
                if (
                  action.shouldDisplay != null &&
                  !action.shouldDisplay(props.row)
                )
                  return;
                if (action.confirm) {
                  return (
                    <DialogTrigger key={actionName} asChild>
                      <DropdownMenuItem
                        onClick={(e) => {
                          props.setSelectedAction(action);
                        }}
                      >
                        {actionName}
                      </DropdownMenuItem>
                    </DialogTrigger>
                  );
                } else {
                  return (
                    <DropdownMenuItem
                      key={actionName}
                      onClick={(e) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        action.onClick(props.row);
                      }}
                    >
                      {actionName}
                    </DropdownMenuItem>
                  );
                }
              })
              .filter(Boolean)}
          </DropdownMenuContent>
        </DropdownMenu>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              {typeof props.selectedAction?.confirmMessage == "function"
                ? props.selectedAction.confirmMessage(props.row)
                : props.selectedAction?.confirmMessage}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">No</Button>
            </DialogClose>
            <DialogClose
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                props.selectedAction?.onClick(props.row);
                props.setSelectedAction(undefined);
              }}
              asChild
            >
              <Button>Yes</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TableCell>
  );
}
