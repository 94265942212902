"use client";
import { cn } from "@/lib/utils";
import {
  Link,
  useLocation,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useEffect, useMemo, useState } from "react";
import { apiClient, logout, switchTeam } from "@/lib/api";
import {
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandDialog,
} from "./ui/command";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ModeToggle } from "./mode-toggle";
import { Combobox } from "@/components/ui/combo-box";
import { Logo } from "@/components/ui/logo";
import { Label } from "@/components/ui/label";

async function searchCases(search: string) {
  const result = await apiClient.POST("/cases", { body: { search } });
  return result.data;
}

export default function Navigation() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { team, user, teams } = useRouteContext({ from: "/_application" });
  const [cmdOpen, setCmdOpen] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const { data: caseData } = useQuery({
    queryKey: ["search-cases", search],
    queryFn: () => searchCases(search),
    placeholderData: keepPreviousData,
  });
  const showTeamSelector = useMemo(() => {
    return teams.length > 1;
  }, [teams]);

  const navigation = useMemo(() => {
    let options = [
      {
        name: "Dashboard",
        href: "/dashboard",
        current: pathname == "/dashboard",
      },
      {
        name: "Cases",
        href: "/cases",
        current: pathname.startsWith("/cases"),
      },
      {
        name: "Assets",
        current: pathname.startsWith("/assets"),
        children: [
          {
            name: "Users",
            description:
              "Users discovered in detections that are present in your directories",
            href: "/assets/users",
            current: pathname.startsWith("/assets/users"),
          },
          {
            name: "Endpoints",
            description:
              "Endpoints and servers synced from your detection platforms",
            href: "/assets/endpoints",
            current: pathname.startsWith("/assets/endpoints"),
          },
          {
            name: "Locations",
            description: "Locations of detections that have ocurred",
            href: "/assets/locations",
            current: pathname.startsWith("/assets/locations"),
          },
          {
            name: "Processes",
            description:
              "Potentially malicious processes that have triggered detections",
            href: "/assets/processes",
            current: pathname.startsWith("/assets/processes"),
          },
          {
            name: "Tools",
            description:
              "Known tools that have been associated with detections in your environment",
            href: "/assets/tools",
            current: pathname.startsWith("/assets/tools"),
          },
          {
            name: "Files",
            description: "Files pulled from detections on your endpoints",
            href: "/assets/files",
            current: pathname.startsWith("/assets/files"),
          },
          {
            name: "IP Addresses",
            description: "IP address discovered in your environment",
            href: "/assets/ips",
            current: pathname.startsWith("/assets/ips"),
          },
          {
            name: "Domains",
            description: "Domains associated with events in your environment",
            href: "/assets/domains",
            current: pathname.startsWith("/assets/domains"),
          },
        ],
      },
      {
        name: "Settings",
        href: "/settings",
        current: pathname.startsWith("/settings"),
      },
    ];
    if (user.superAdmin) {
      options.push({
        name: "Admin",
        href: "/admin",
        current: pathname.startsWith("/admin"),
      });
    }
    return options;
  }, [pathname]);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setCmdOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);
  return (
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background z-10 px-4 xl:px-6">
      <nav className="flex flex-row text-lg w-full font-medium">
        <Link
          to="/dashboard"
          className="flex items-center gap-2 text-lg font-semibold"
        >
          <div className="h-9 w-9 rounded-md border flex items-center justify-center shadow-sm hover:bg-muted">
            <img src={team.logoUrl} className="h-6 w-6" alt="Team Logo" />
          </div>
          <span className="sr-only">Team Logo</span>
        </Link>
        <div className="hidden xl:flex xl:flex-row xl:items-center ml-4">
          {navigation.map((v) => {
            if (v.children != null) {
              return (
                <DropdownMenu
                  open={dropdownOpen}
                  onOpenChange={setDropdownOpen}
                  key={v.name}
                >
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className={cn("text-muted-foreground cursor-pointer", {
                        "text-foreground": v.current,
                      })}
                    >
                      {v.name}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="start"
                    className="text-sm max-w-[600px]"
                  >
                    <DropdownMenuLabel className="px-4">
                      {v.name}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup className="gap-2 grid grid-cols-2 p-2">
                      {v.children.map((v) => (
                        <Link
                          onClick={() => setDropdownOpen(false)}
                          key={v.href}
                          to={v.href}
                        >
                          <div
                            className={cn("p-2 rounded-md hover:bg-muted", {
                              "bg-muted": v.current,
                            })}
                          >
                            <p
                              className={cn("font-medium", {
                                "text-sm font-semibold": v.current,
                              })}
                            >
                              {v.name}
                            </p>
                            <p
                              className={cn(
                                "text-muted-foreground transition-colors"
                              )}
                            >
                              {v.description}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              );
            } else {
              return (
                <Link
                  key={v.href}
                  to={v.href}
                  className={cn(
                    "text-muted-foreground transition-colors hover:text-foreground",
                    { "text-foreground": v.current }
                  )}
                >
                  <Button variant="ghost">{v.name}</Button>
                </Link>
              );
            }
          })}
        </div>
      </nav>
      <div>
        <Sheet open={open} onOpenChange={setOpen}>
          <SheetContent side="right" className="overflow-auto">
            <nav className="grid gap-6 overflow-auto text-lg font-medium">
              <Link
                to="/dashboard"
                className="flex items-center gap-2 text-lg font-semibold"
              >
                <Logo words className="h-8 w-auto ml-0" />
                <span className="sr-only">Wirespeed</span>
              </Link>

              {showTeamSelector && (
                <div className="flex flex-col gap-1">
                  <Label className="text-muted-foreground text-xs">Team</Label>
                  <Combobox
                    defaultValue={team.id}
                    values={teams.map((v) => ({ label: v.name, value: v.id }))}
                    placeholder="Select team"
                    emptyMessage="Team not found"
                    onSelect={(v) => switchTeam(v, navigate)}
                  />
                </div>
              )}
              {navigation.map((v) => {
                if (v.children != null) {
                  return (
                    <div key={`mobile-${v.href}`}>
                      <div className="text-muted-foreground">{v.name}</div>
                      <div className="ml-4 flex flex-col  gap-4 mt-4">
                        {v.children.map((c) => (
                          <Link
                            onClick={() => setOpen(false)}
                            key={`mobile-${c.href}`}
                            to={c.href}
                            className={cn(
                              "text-muted-foreground transition-colors hover:text-foreground",
                              { "text-foreground": c.current }
                            )}
                          >
                            {c.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <Link
                      onClick={() => setOpen(false)}
                      key={`mobile-${v.href}`}
                      to={v.href}
                      className={cn(
                        "text-muted-foreground transition-colors hover:text-foreground",
                        { "text-foreground": v.current }
                      )}
                    >
                      {v.name}
                    </Link>
                  );
                }
              })}
            </nav>
          </SheetContent>
        </Sheet>
      </div>

      <div className="flex flex-shrink-0 ml-auto items-center gap-2">
        {!team.completedOnboarding && (
          <div className="hidden xl:block">
            <Link to="/user-onboarding/$step" params={{ step: "2" }}>
              <Button size="sm">Complete Onboarding</Button>
            </Link>
          </div>
        )}
        {team.testMode && (
          <a
            href="/docs/settings#test-mode"
            target="_blank"
            className="hover:bg-muted shadow-sm border border-input h-9 rounded-md p-2 text-xs font-semibold flex-shrink-0 items-center flex text-amber-500"
          >
            Test Mode
          </a>
        )}

        {showTeamSelector && (
          <div className="hidden xl:block">
            <Combobox
              value={team.id}
              defaultValue={team.id}
              values={teams.map((v) => ({ label: v.name, value: v.id }))}
              placeholder="Select team"
              emptyMessage="Team not found"
              onSelect={(v) => switchTeam(v, navigate)}
            />
          </div>
        )}
        <ModeToggle />
        <a href="/docs" target="_blank">
          <Button className="h-9 w-9 text-lg font-light" variant="outline">
            ?
          </Button>
        </a>
        <div className="hidden rounded-md shadow-sm xl:block flex-initial">
          <Button
            className="h-9 w-9 flex-shrink-0"
            variant="outline"
            onClick={() => setCmdOpen(true)}
            size="icon"
          >
            <MagnifyingGlassIcon className="h-5 w-5" />
            <span className="sr-only">Search Cases</span>
          </Button>
          <CommandDialog
            commandProps={{ shouldFilter: false }}
            open={cmdOpen}
            onOpenChange={setCmdOpen}
          >
            <CommandInput
              onValueChange={setSearch}
              placeholder="Search cases..."
            />
            <CommandList>
              <CommandGroup heading="Cases">
                <CommandEmpty>No results found.</CommandEmpty>
                {caseData?.data.map((v) => (
                  <CommandItem
                    onSelect={() => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      navigate({
                        to: "/cases/$caseId",
                        params: { caseId: v.id },
                      });
                      setCmdOpen(false);
                    }}
                    key={v.id}
                    value={v.id}
                  >
                    <div>
                      <p>{v.title}</p>
                      <p className="text-muted-foreground text-xs">
                        {v.sid} | {v.name}
                      </p>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </CommandDialog>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="h-9 w-9 flex-shrink-0"
              variant="outline"
              size="icon"
            >
              <UserIcon className="h-5 w-5" />
              <span className="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link className="cursor-pointer" to="/settings">
                Settings
              </Link>
            </DropdownMenuItem>
            {/* <DropdownMenuItem>Support</DropdownMenuItem> */}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => logout()}
            >
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button
          onClick={() => setOpen(true)}
          variant="outline"
          size="icon"
          className="shrink-0 xl:hidden"
        >
          <Bars3Icon className="h-5 w-5" />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </div>
    </header>
  );
}
