import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@radix-ui/react-icons";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { getCaseStatusConfigByStatus } from "@wire/shared";
import { useMemo, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/_application/assets/endpoints/$endpointId"
)({
  component: Location,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.endpointId));
  },
});

async function getData(endpointId: string) {
  const [endpoint] = await Promise.all([
    apiClient.GET("/endpoint/{id}", {
      params: { path: { id: endpointId } },
    }),
  ]);
  if (endpoint.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return {
    endpoint: endpoint.data,
  };
}

export const ENDPOINT_QUERY_KEY = "endpoint";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [ENDPOINT_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  endpointId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "ENDPOINT", assetId: endpointId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  endpointId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, endpointId],
    queryFn: () => searchCases(searchSettings, endpointId),
    placeholderData: keepPreviousData,
  });

function Location() {
  const { endpointId } = Route.useParams();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, endpointId));
  const {
    data: { endpoint },
  } = useSuspenseQuery(getOptions(endpointId));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function updateEndpoint(
    dto: components["schemas"]["UpdateEndpointDto"]
  ) {
    const response = await apiClient.PATCH("/endpoint/{id}", {
      params: { path: { id: endpointId } },
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
    }
    await queryClient.invalidateQueries({
      queryKey: [ENDPOINT_QUERY_KEY, endpointId],
    });
  }

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 mb-4 flex space-y-0 items-center flex-col gap-4 lg:flex-row lg:justify-between">
            <div>
              <CardTitle>
                {endpoint.name ??
                  endpoint.privateIpAddress ??
                  endpoint.sourceId}
              </CardTitle>
              <CardDescription>Endpoint</CardDescription>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row">
              {endpoint.hva && (
                <Button onClick={() => updateEndpoint({ hva: false })}>
                  Remove HVA Status
                </Button>
              )}
              {!endpoint.hva && (
                <Button onClick={() => updateEndpoint({ hva: true })}>
                  Set as HVA
                </Button>
              )}
            </div>
          </CardHeader>
          <CardContent className="flex overflow-auto flex-col gap-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">{dateTime(endpoint.createdAt)}</p>
            </div>
            <div>
              <h2 className="font-semibold">Hostname</h2>
              <p className="text-sm">{endpoint.name}</p>
            </div>

            <div>
              <h2 className="font-semibold">High Value Target</h2>
              <p className="text-sm">
                {endpoint.hva ? (
                  <CheckIcon className="text-green-500 h-6 w-6" />
                ) : (
                  <XMarkIcon className="text-red-500 h-6 w-6" />
                )}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">Public IP</h2>
              <p className="text-sm">
                {endpoint.publicIPs?.map((v, idx) => (
                  <Link
                    className="text-blue-500"
                    to="/assets/ips/$ipId"
                    params={{ ipId: v.id }}
                  >
                    {v.ipv4 ?? v.ipv6}
                    {idx < endpoint.publicIPs!.length - 1 && ", "}
                  </Link>
                )) ?? "-"}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">Private IP Address</h2>
              <p className="text-sm">{endpoint.privateIpAddress ?? "-"}</p>
            </div>
            <div>
              <h2 className="font-semibold">Source ID</h2>
              <p className="text-sm">{endpoint.sourceId ?? "-"}</p>
            </div>
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({ to: "/cases/$caseId", params: { caseId: row.id } })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getCaseStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "sourceIngestedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this endpoint has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
