import { AppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Label } from "@/components/ui/label";
import { Popover } from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getCaseStatusBadge, getVerdictBadge } from "@/lib/case";
import { calculateTTRSeconds, dateTime } from "@/lib/time";
import { cn } from "@/lib/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { PopoverTrigger } from "@radix-ui/react-popover";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  Link,
  SearchSchemaInput,
  createFileRoute,
  useNavigate,
} from "@tanstack/react-router";
import {
  CaseStatus,
  DetectionCategory,
  getCaseStatusConfigByStatus,
  getVerdictConfigByVerdict,
  Verdict,
  VerdictConfig,
} from "@wire/shared";
import { differenceInSeconds } from "date-fns";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";

const PAGE_SIZE = 10;
type SearchStatus = NonNullable<
  components["schemas"]["SearchCasesDto"]["statuses"]
>[number];
export const Route = createFileRoute("/_application/cases/")({
  component: Cases,
  validateSearch: (
    search: {
      filter?: SearchStatus;
      page?: string | number;
      orderBy?: string;
      orderDir?: "asc" | "desc";
      search?: string;
    } & SearchSchemaInput
  ) => {
    let page = search.page ?? 1;
    if (typeof page == "string") {
      page = parseInt(page);
    }
    if (isNaN(page)) {
      page = 1;
    }
    return {
      filter: search.filter,
      search: search.search,
      orderBy: search.orderBy ?? "sid",
      orderDir: search.orderDir ?? "desc",
      page: isNaN(page) ? 1 : page,
    };
  },
  loaderDeps: ({ search }) => {
    return {
      filter: search.filter,
      page: search.page,
      search: search.search,
      orderBy: search.orderBy,
      orderDir: search.orderDir,
    };
  },
  loader: async ({ context, deps }) => {
    await context.queryClient.ensureQueryData(
      getOptions({
        statuses: deps.filter ? [deps.filter] : [],
        page: deps.page,
        search: deps.search,
        orderBy: deps.orderBy,
        orderDir: deps.orderDir,
      })
    );
  },
});

async function getData(
  searchSettings: components["schemas"]["SearchCasesDto"]
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: searchSettings,
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}

export const CASES_QUERY_KEY = "case-settings";
const getOptions = (searchSettings: components["schemas"]["SearchCasesDto"]) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings],
    queryFn: () => getData(searchSettings),
    placeholderData: keepPreviousData,
  });

function Cases() {
  const {
    filter: urlFilter,
    page: urlPage,
    search: urlSearch,
    orderBy: urlOrderBy,
    orderDir: urlOrderDir,
  } = Route.useSearch();
  const navigate = useNavigate();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({
    statuses: urlFilter ? [urlFilter] : [],
    page: urlPage,
    orderBy: urlOrderBy,
    orderDir: urlOrderDir,
    search: urlSearch,
  });
  const casesQuery = useQuery(getOptions(searchSettings));

  async function updateSearchSettings(
    settings: components["schemas"]["SearchCasesDto"]
  ) {
    let search = settings.search ?? searchSettings.search;
    if (settings?.search?.length == 0) {
      search = undefined;
    }
    let orderBy = settings.orderBy;
    let orderDir = settings.orderDir;
    let page = settings.page;

    if (
      (settings.statuses != null && settings.statuses.length > 0) ||
      search != null ||
      orderBy != null ||
      orderDir != null
    ) {
      page = 1;
    }
    if (search != null && search.length == 0) search = undefined;
    await navigate({
      replace: true,
      to: ".",
      search: {
        page: page ?? searchSettings.page,
        orderBy: orderBy ?? searchSettings.orderBy,
        orderDir: orderDir ?? searchSettings.orderDir,
        search: search,
        filter: urlFilter,
      },
    });

    setSearchSettings({
      ...searchSettings,
      ...settings,
      page,
      statuses: urlFilter ? [urlFilter] : [],
    });
  }

  useEffect(() => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateSearchSettings({ statuses: urlFilter ? [urlFilter] : [] });
  }, [urlFilter]);

  return (
    <AppLayout>
      <main className="flex-1 p-4 flex flex-col items-start space-y-4">
        <TableCard
          query={casesQuery}
          defaultPage={urlPage}
          orderBy={urlOrderBy}
          orderByDir={urlOrderDir}
          defaultSearch={urlSearch}
          onUpdate={updateSearchSettings}
          searchable
          onClick={(row) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate({ to: "/cases/$caseId", params: { caseId: row.id } });
          }}
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              sortable: true,
              format: (value) => getCaseStatusBadge(value),
            },
            {
              display: "Verdict",
              key: "verdict",
              sortable: true,
              format: (value) => getVerdictBadge(value),
            },
            {
              display: "Created At",
              key: "sourceIngestedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
            {
              display: "MTTR (seconds)",
              key: "createdAt",
              format(value, row) {
                if (
                  !row.sourceDetectedAt ||
                  !row.closedAt ||
                  row.excludeFromMeans
                )
                  return "-";
                return row.reingested ||
                  row.testMode ||
                  row.status != CaseStatus.CLOSED
                  ? "-"
                  : numeral(
                      calculateTTRSeconds(row.sourceDetectedAt, row.closedAt)
                    ).format();
              },
            },
            {
              display: "MTTD (seconds)",
              key: "createdAt",
              format(value, row) {
                if (
                  !row.sourceDetectedAt ||
                  !row.verdictedAt ||
                  row.excludeFromMeans
                )
                  return "-";
                return row.reingested || row.testMode
                  ? "-"
                  : numeral(
                      calculateTTRSeconds(row.sourceDetectedAt, row.verdictedAt)
                    ).format();
              },
            },
            {
              display: "",
              key: "id",
              format(value, row) {
                let text = "";
                if (row.testMode) {
                  text =
                    "This case was ingested in test mode and may be missing information like MTTR, MTTD, and more.";
                } else if (row.reingested) {
                  text =
                    "This case was reingested and may be missing information like MTTR, MTTD, and more.";
                } else {
                  return;
                }
                return (
                  <div className="flex items-center justify-center">
                    <HoverCard closeDelay={50} openDelay={0}>
                      <HoverCardTrigger className="ml-1">
                        <InformationCircleIcon className="h-6 w-6 text-red-500" />
                      </HoverCardTrigger>
                      <HoverCardContent className="whitespace-normal font-normal">
                        {text}
                      </HoverCardContent>
                    </HoverCard>
                  </div>
                );
              },
            },
          ]}
        >
          <>
            <CardHeader className="pb-2">
              <CardTitle>Cases</CardTitle>
              <CardDescription>Cases for your organization.</CardDescription>
            </CardHeader>
            <CardContent className="pb-2">
              <div className="flex w-full lg:items-stretch flex-col gap-2 lg:flex-row">
                <ul className="bg-background shadow-sm border p-2 rounded-md flex flex-col lg:flex-row w-full items-center lg:w-auto lg:items-start">
                  <FilterItem key="all-filter" activeFilter={urlFilter}>
                    All
                  </FilterItem>
                  <FilterItem
                    key="processing-filter"
                    filter="PROCESSING"
                    activeFilter={urlFilter}
                  >
                    Processing
                  </FilterItem>
                  <FilterItem
                    key="escalated-filter"
                    filter="ESCALATED"
                    activeFilter={urlFilter}
                  >
                    Escalated
                  </FilterItem>
                  <FilterItem
                    key="closed-filter"
                    filter="CLOSED"
                    activeFilter={urlFilter}
                  >
                    Closed
                  </FilterItem>
                </ul>
                <div>
                  <Select
                    onValueChange={(v) =>
                      updateSearchSettings({
                        verdict: v == "any" ? undefined : (v as Verdict),
                      })
                    }
                  >
                    <SelectTrigger className="h-full bg-background font-medium min-w-[180px]">
                      <SelectValue
                        key="select-value"
                        placeholder="Filter By Verdict"
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem key="any" value="any">
                        Any
                      </SelectItem>
                      {Object.values(VerdictConfig).map((v) => (
                        <SelectItem key={v.display} value={v.type}>
                          {v.display}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </CardContent>
          </>
        </TableCard>
      </main>
    </AppLayout>
  );
}

function FilterItem(
  props: React.PropsWithChildren<{
    activeFilter: SearchStatus | undefined;
    filter?: SearchStatus;
  }>
) {
  return (
    <li
      className={cn(
        "px-3 text-sm text-muted-foreground font-medium rounded-md",
        {
          "bg-background font-semibold text-foreground":
            props.filter == props.activeFilter,
        }
      )}
    >
      <Link to="." search={{ filter: props.filter }}>
        {props.children}
      </Link>
    </li>
  );
}
