import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@radix-ui/react-icons";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { getCaseStatusConfigByStatus } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/assets/files/$fileId")({
  component: File,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.fileId));
  },
});

async function getData(fileId: string) {
  const [file] = await Promise.all([
    apiClient.GET("/file/{id}", {
      params: { path: { id: fileId } },
    }),
  ]);
  if (file.error != null) {
    throw new Error("Error getting file information");
  }
  return {
    file: file.data,
  };
}

export const FILE_QUERY_KEY = "file";
const getOptions = (fileId: string) =>
  queryOptions({
    queryKey: [FILE_QUERY_KEY, fileId],
    queryFn: () => getData(fileId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  fileId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "FILE", assetId: fileId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  fileId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, fileId],
    queryFn: () => searchCases(searchSettings, fileId),
    placeholderData: keepPreviousData,
  });

function File() {
  const { fileId } = Route.useParams();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, fileId));
  const {
    data: { file },
  } = useSuspenseQuery(getOptions(fileId));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function updateFile(dto: components["schemas"]["UpdateFileDto"]) {
    const response = await apiClient.PATCH("/file/{id}", {
      params: { path: { id: fileId } },
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEY, fileId] });
    toast.success("File updated");
  }

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 space-y-0 items-center mb-4 flex flex-col gap-4 lg:flex-row lg:justify-between">
            <div className="flex flex-col break-all justify-center">
              <CardTitle>
                {file.path}
                {file.path?.includes("/") ? "/" : "\\"}
                {file.name}
              </CardTitle>
              <CardDescription>File</CardDescription>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row">
              {file.excluded ? (
                <Button onClick={() => updateFile({ excluded: false })}>
                  Remove Exclusion
                </Button>
              ) : (
                <Button onClick={() => updateFile({ excluded: true })}>
                  Exclude
                </Button>
              )}
            </div>
          </CardHeader>
          <CardContent className="flex overflow-auto flex-col gap-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">{dateTime(file.createdAt)}</p>
            </div>
            <div>
              <h2 className="font-semibold">SHA1</h2>
              <p className="text-sm">{file.sha1 ?? "-"}</p>
            </div>
            <div>
              <h2 className="font-semibold">SHA256</h2>
              <p className="text-sm">{file.sha256 ?? "-"}</p>
            </div>

            <div>
              <h2 className="font-semibold">Excluded</h2>
              <p className="text-sm">
                {file.excluded ? (
                  <CheckIcon className="text-green-500 h-6 w-6" />
                ) : (
                  <XMarkIcon className="text-red-500 h-6 w-6" />
                )}
              </p>
            </div>
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({ to: "/cases/$caseId", params: { caseId: row.id } })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getCaseStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "sourceIngestedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this file has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
