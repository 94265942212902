import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { getCaseStatusConfigByStatus } from "@wire/shared";
import { useState } from "react";

export const Route = createFileRoute(
  "/_application/assets/processes/$processId"
)({
  component: Process,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.processId));
  },
});

async function getData(processId: string) {
  const [process] = await Promise.all([
    apiClient.GET("/process/{id}", {
      params: { path: { id: processId } },
    }),
  ]);
  if (process.error != null) {
    throw new Error("Error getting process information");
  }
  return {
    process: process.data,
  };
}

export const ASSET_QUERY_KEY = "process";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  processId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "PROCESS", assetId: processId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  processId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, processId],
    queryFn: () => searchCases(searchSettings, processId),
    placeholderData: keepPreviousData,
  });

function Process() {
  const { processId } = Route.useParams();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, processId));
  const {
    data: { process },
  } = useSuspenseQuery(getOptions(processId));
  const navigate = useNavigate();

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>
              <code>{process.command}</code>
            </CardTitle>
            <CardDescription>Process</CardDescription>
          </CardHeader>
          <CardContent className="flex overflow-auto flex-col gap-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">{dateTime(process.createdAt)}</p>
            </div>
            <div>
              <h2 className="font-semibold">SHA1</h2>
              <p className="text-sm">{process.sha1}</p>
            </div>
            <div>
              <h2 className="font-semibold">SHA256</h2>
              <p className="text-sm">{process.sha256}</p>
            </div>
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({ to: "/cases/$caseId", params: { caseId: row.id } })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getCaseStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "sourceIngestedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this process has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
