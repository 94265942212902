import { components } from "@/lib/api.types";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { getIntegrationConfigByPlatform } from "@wire/shared";
import AddAdminIntegration from "./add-admin-integration";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useState } from "react";
import { Label } from "../ui/label";
import { DialogTitle } from "@radix-ui/react-dialog";
import { apiClient } from "@/lib/api";
import { toast } from "sonner";

export default function AdminIntegrations({
  integrations,
}: {
  integrations: components["schemas"]["AdminIntegration"][];
}) {
  return (
    <Card>
      <CardHeader>
        <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:justify-between lg:items-center">
          <div>
            <CardTitle>Integration Settings</CardTitle>
          </div>
          <AddAdminIntegration />
        </div>
      </CardHeader>
      <CardContent>
        <ul className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          {integrations.map((v) => (
            <Integration integration={v} />
          ))}
        </ul>
        {!integrations.length && (
          <p className="text-foreground-muted text-center">
            No integrations added yet
          </p>
        )}
      </CardContent>
    </Card>
  );
}

function Integration({
  integration,
}: {
  integration: components["schemas"]["AdminIntegration"];
}) {
  const [clientKey, setClientKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [secondaryClientKey, setSecondaryClientKey] = useState("");
  const [secondarySecretKey, setSecondarySecretKey] = useState("");
  const [storeId, setStoreId] = useState("");
  const [stateSecret, setStateSecret] = useState("");
  const [signingSecret, setSigningSecret] = useState("");
  const [open, setOpen] = useState(false);

  async function update() {
    const response = await apiClient.PATCH("/admin/integration/{id}", {
      params: {
        path: { id: integration.id },
      },
      body: {
        clientKey,
        secretKey,
        signingSecret,
        stateSecret,
        storeId,
        secondaryClientKey,
        secondarySecretKey,
      },
    });
    if (response.error != null) {
      toast.error("Error updating integration");
      return;
    }
    toast.success("Integration updated");
    setClientKey("");
    setSecretKey("");
    setSigningSecret("");
    setStateSecret("");
    setSecondaryClientKey("");
    setSecondarySecretKey("");
    setStoreId("");
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          {getIntegrationConfigByPlatform(integration.type)?.display}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            {getIntegrationConfigByPlatform(integration.type)?.display}
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-4">
          <IntegrationInput
            label="Client Key"
            value={clientKey}
            hasValue={integration.clientKey}
            onChange={setClientKey}
          />
          <IntegrationInput
            label="Secret Key"
            value={secretKey}
            hasValue={integration.secretKey}
            onChange={setSecretKey}
          />
          <IntegrationInput
            label="Signing Secret"
            value={signingSecret}
            hasValue={integration.signingSecret}
            onChange={setSigningSecret}
          />
          <IntegrationInput
            label="State Secret"
            hasValue={integration.stateSecret}
            value={stateSecret}
            onChange={setStateSecret}
          />
          <IntegrationInput
            label="Store ID"
            hasValue={integration.storeId}
            value={storeId}
            onChange={setStoreId}
          />
          <IntegrationInput
            label="Secondary Client Key"
            hasValue={integration.secondaryClientKey}
            value={secondaryClientKey}
            onChange={setSecondaryClientKey}
          />
          <IntegrationInput
            label="Secondary Secret Key"
            hasValue={integration.secondarySecretKey}
            value={secondarySecretKey}
            onChange={setSecondarySecretKey}
          />
          <Button onClick={update}>Update</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function IntegrationInput(props: {
  label: string;
  value: string;
  hasValue: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div>
      <Label>{props.label}</Label>
      <Input
        value={props.value}
        type="password"
        autoComplete="off"
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
}
