import { apiClient } from "@/lib/api";

import { queryClient } from "@/main";
import { SETTINGS_QUERY_KEY } from "@/routes/_application/settings";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { z } from "zod";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useForm } from "react-hook-form";
import { Checkbox } from "@/components/ui/checkbox";
import { Switch } from "@/components/ui/switch";

export default function AdminTeamSettings() {
  const router = useRouter();

  const teamSchema = useMemo(
    () =>
      z.object({
        teamName: z.string().min(1).max(512),
        email: z.string().email().min(1).max(512),
        ownerName: z.string().min(1, {}).max(512),
        domain: z.string().url(),
        demo: z.boolean().optional(),
        seed: z.number().optional(),
      }),
    []
  );

  const teamForm = useForm<z.infer<typeof teamSchema>>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      teamName: "",
      ownerName: "",
      email: "",
      domain: "",
      demo: false,
      seed: 42,
    },
  });

  const [domainManuallyModified, setDomainManuallyModified] = useState(false);

  async function onTeamSubmit(values: z.infer<typeof teamSchema>) {
    const promise = apiClient.PUT("/admin/invite", {
      body: {
        name: values.teamName,
        ownerEmail: values.email,
        ownerName: values.ownerName,
        domain: values.domain,
        demo: values.demo ?? false,
        seed: values.seed,
      },
    });

    if (values.demo) {
      toast.promise(promise, {
        loading: "Creating team, takes ~10s",
      });
      const response = await promise;
      if (response.error != null || response.response.status != 200) {
        toast.error(response.error?.message ?? "Unknown error");
        return;
      }
      toast.success("Team created, switch to the team");
    } else {
      await promise;
      toast.success("Team created");
    }

    teamForm.reset();
    setDomainManuallyModified(false);

    // Refresh all data
    await queryClient.invalidateQueries({ queryKey: [] });
    await router.invalidate();
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Create Team</CardTitle>
        <CardDescription>Create a new team and invite an admin</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...teamForm}>
          <form
            onSubmit={teamForm.handleSubmit(onTeamSubmit)}
            className="col-span-5 mt-2 flex flex-col space-y-4"
          >
            <FormField
              control={teamForm.control}
              name="teamName"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Team Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Wirespeed" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={teamForm.control}
              name="ownerName"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Owner Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Jon Speed" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={teamForm.control}
              name="email"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="jake@wirespeed.co"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!domainManuallyModified) {
                          const emailValue = e.target.value;
                          const domainMatch = emailValue.match(/@(.+)$/);
                          if (domainMatch != null && domainMatch[1]) {
                            teamForm.setValue(
                              "domain",
                              `https://${domainMatch[1]}`
                            );
                          }
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={teamForm.control}
              name="domain"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Domain</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="https://wirespeed.co"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDomainManuallyModified(true);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={teamForm.control}
              name="demo"
              render={({ field }) => (
                <FormItem className="flex-1 flex items-center space-y-0 gap-2">
                  <FormLabel>Demo Team</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {teamForm.watch("demo") && (
              <FormField
                control={teamForm.control}
                name="seed"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Demo Seed</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <div className="flex self-end">
              <Button
                disabled={teamForm.formState.isSubmitting}
                variant="outline"
                type="submit"
              >
                Invite
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
