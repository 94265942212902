import {
  createRootRouteWithContext,
  Link,
  Outlet,
} from "@tanstack/react-router";
import "../globals.css";
import { Toaster } from "@/components/ui/sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { User } from "@/lib/api";
import { components } from "@/lib/api.types";
import { motion } from "framer-motion";
import { ThemeProvider } from "@/components/theme-provider";
import { Button } from "@/components/ui/button";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  user: User;
  team: components["schemas"]["Team"];
  teams: components["schemas"]["Team"][];
}>()({
  component: () => (
    <>
      <QueryClientProvider client={queryClient}>
        <motion.div
          className="min-h-screen min-w-screen"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.25 }}
        >
          <Outlet />
        </motion.div>
        <Toaster richColors closeButton />
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        {/* <TanStackRouterDevtools initialIsOpen={true} /> */}
      </QueryClientProvider>
    </>
  ),
  notFoundComponent: () => {
    return (
      <div className="h-screen w-screen flex flex-col space-y-8 items-center justify-center">
        <img src="/illustrations/lost.svg" className="w-auto max-w-[40vw]" />
        <h1 className="text-2xl">Looks like this page doesn't exist</h1>
        <Link to="/dashboard">
          <Button>Go Home</Button>
        </Link>
      </div>
    );
  },
  errorComponent: () => {
    return (
      <div className="h-screen w-screen flex flex-col space-y-8 items-center justify-center">
        <img src="/illustrations/lost.svg" className="w-auto max-w-[40vw]" />
        <h1 className="text-2xl">Looks like something went wrong</h1>
        <div className="flex space-x-4">
          <Link to="/dashboard">
            <Button>Go Home</Button>
          </Link>
          <Button variant="outline" onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        </div>
      </div>
    );
  },
});
