import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { PlusIcon } from "@radix-ui/react-icons";
import { useEffect, useMemo, useState } from "react";
import Slack from "./integrations/slack";
import Microsoft from "./integrations/microsoft";
import Google from "./integrations/google";
import Email from "./integrations/email";
import { useQueryClient } from "@tanstack/react-query";
import { INTEGRATION_SETTINGS_QUERY } from "./integration";
import {
  IntegrationConfig,
  IntegrationPlatform,
  IntegrationType,
  getIntegrationConfigByPlatform,
} from "@wire/shared";
import Okta from "@/components/settings/integrations/okta";
import HIBP from "@/components/settings/integrations/hibp";
import IPInfo from "@/components/settings/integrations/ipinfo";
import SentinelOne from "@/components/settings/integrations/sentinel-one";

export default function AddIntegration(props: {
  type?: IntegrationType;
  platform?: IntegrationPlatform;
  onboarding?: boolean;
  hideTrigger?: boolean;
  open?: boolean;
  onChange?: (open: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const integrationConfig = useMemo(() => {
    if (props.platform) {
      return getIntegrationConfigByPlatform(props.platform);
    }
  }, [props.platform]);
  const [name, setName] = useState(integrationConfig?.display);
  const [open, setOpen] = useState(props.open ?? false);
  const [integrationPlatform, setIntegrationPlatform] = useState<
    IntegrationPlatform | undefined
  >(props.platform);

  useEffect(() => {
    setIntegrationPlatform(props.platform);
    if (name == null) setName(integrationConfig?.display);
  }, [props.platform]);

  useEffect(() => {
    props.onChange?.(open);

    if (!open) {
      setName("");
      setIntegrationPlatform(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (props.open != null) {
      setOpen(props.open);
    }
  }, [props.open]);

  async function complete() {
    setOpen(false);
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  const integrations = useMemo(() => {
    let options = Object.values(IntegrationConfig);
    return options
      .sort((a, b) => a.display.localeCompare(b.display))
      .map((type) => (
        <SelectItem value={type.platform} key={type.platform}>
          {type.display}
        </SelectItem>
      ));
  }, [props.type]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!props.hideTrigger && (
        <DialogTrigger asChild>
          <Button>
            <PlusIcon className="h-5 w-5 mr-2" />
            Add new integration
          </Button>
        </DialogTrigger>
      )}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add new integration</DialogTitle>
          <DialogDescription>
            Integrate with your security platforms to automate detection and
            response workflows.
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="space-y-4">
            <div>
              <Label>Type</Label>
              <Select
                defaultValue={integrationPlatform}
                onValueChange={(e) => {
                  setIntegrationPlatform(e as IntegrationPlatform);
                  if (name == null)
                    setName(
                      getIntegrationConfigByPlatform(e as IntegrationPlatform)
                        .display
                    );
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select an integration" />
                </SelectTrigger>
                <SelectContent>{integrations}</SelectContent>
              </Select>
            </div>
            {integrationPlatform && (
              <IntegrationTypeComponent
                onboarding={props.onboarding}
                onComplete={complete}
                platform={integrationPlatform}
              />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function IntegrationTypeComponent(props: {
  platform: IntegrationPlatform;
  onComplete: () => void;
  onboarding?: boolean;
}) {
  switch (props.platform) {
    case IntegrationPlatform.SLACK:
      return <Slack onComplete={props.onComplete} />;
    case IntegrationPlatform.GOOGLE:
      return (
        <Google onComplete={props.onComplete} onboarding={props.onboarding} />
      );
    case IntegrationPlatform.OKTA:
      return <Okta onComplete={props.onComplete} />;
    case IntegrationPlatform.SENTINEL_ONE:
      return <SentinelOne onComplete={props.onComplete} />;
    case IntegrationPlatform.MICROSOFT:
    case IntegrationPlatform.MICROSOFT_TEAMS:
      return (
        <Microsoft platform={props.platform} onComplete={props.onComplete} />
      );
    case IntegrationPlatform.EMAIL:
      return <Email onComplete={props.onComplete} />;
    case IntegrationPlatform.HAVE_I_BEEN_PWNED:
      return <HIBP onComplete={props.onComplete} />;
    case IntegrationPlatform.IP_INFO:
      return <IPInfo onComplete={props.onComplete} />;
  }
}
