import { apiClient } from "@/lib/api";
import { useEffect, useState } from "react";
import { OAuthButton } from "./oauth-button";
import { IntegrationPlatform } from "@wire/shared";

export default function Microsoft(props: {
  platform: IntegrationPlatform.MICROSOFT | IntegrationPlatform.MICROSOFT_TEAMS;
  onComplete: () => void;
}) {
  const [error] = useState<string>();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    async function loadUrl() {
      let url: any;
      switch (props.platform) {
        case IntegrationPlatform.MICROSOFT:
          url = "/integration/microsoft/url";
          break;
        case IntegrationPlatform.MICROSOFT_TEAMS:
          url = "/integration/microsoft/teams/url";
          break;
      }
      let response = await apiClient.POST(url, {});
      setUrl(response.data?.url);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUrl();
  }, []);

  return (
    <OAuthButton
      title="Integrate Microsoft"
      error={error}
      url={url}
      onComplete={props.onComplete}
    />
  );
}
