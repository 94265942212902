import { StrictMode, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter, redirect } from "@tanstack/react-router";
import "./globals.css";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import {
  keepPreviousData,
  QueryClient,
  useQueries,
  useQuery,
} from "@tanstack/react-query";
import { apiClient, getUser, setAPIToken } from "./lib/api";
import { ThemeProvider } from "./components/theme-provider";
import { components } from "@/lib/api.types";

export const queryClient = new QueryClient();
const user = getUser();

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    queryClient,
    user: user,
    team: undefined!,
    teams: undefined!,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

async function getTeam() {
  const response = await apiClient.GET("/team");
  // This means they have a valid JWT but for some reason the team it's associated with is no longer valid
  // so let's toss them back to the login screen
  if (!response.response.ok && response.response.status == 404) {
    setAPIToken(undefined);
    window.location.href = "/login";
  }
  if (response.data != null) {
    return response.data;
  }
  return null;
}

async function getTeams() {
  const response = await apiClient.GET("/team/all");
  if (response.data != null) {
    return response.data;
  }
  return null;
}

export const GLOBAL_TEAM_QUERY_KEY = "GLOBAL_TEAM";
export const GLOBAL_TEAMS_QUERY_KEY = "GLOBAL_TEAMS";

function App() {
  const [
    { data: team, isLoading: teamIsLoading },
    { data: teams, isLoading: teamsIsLoading },
  ] = useQueries(
    {
      queries: [
        {
          queryKey: [GLOBAL_TEAM_QUERY_KEY],
          queryFn: getTeam,
          refetchOnWindowFocus: false,
          placeholderData: keepPreviousData,
        },
        {
          queryKey: [GLOBAL_TEAMS_QUERY_KEY],
          queryFn: getTeams,
          refetchOnWindowFocus: false,
          placeholderData: keepPreviousData,
        },
      ],
    },
    queryClient
  );

  if (teamIsLoading || teamsIsLoading) {
    return (
      <div className="h-screen w-screen flex items-end text-gray-100 dark:text-neutral-900 pb-4 text-xs justify-center">
        {
          "-[------->+<]>+++.+[--->+<]>.-.-------.-[--->+<]>--.++[--->++<]>.---.[->++++++<]>.+[->+++<]>.--[--->+<]>-.---[->++++<]>.------------.++++++++++.-------------.----.--[--->+<]>-.-----------.--.------.--[--->+<]>-.-----.+++."
        }
      </div>
    );
  }
  return (
    <StrictMode>
      <ThemeProvider defaultTheme="system" storageKey="doc_theme">
        <RouterProvider
          router={router}
          context={{ queryClient, user, team: team!, teams: teams! }}
        />
      </ThemeProvider>
    </StrictMode>
  );
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
}
