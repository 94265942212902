import { apiClient } from "@/lib/api";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import copy from "copy-to-clipboard";
import { toast } from "sonner";
import { OAuthButton } from "./oauth-button";
import { IntegrationPlatform } from "@wire/shared";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

export default function SentinelOne(props: { onComplete: () => void }) {
  const [apiKey, setAPIKey] = useState<string>();
  const [customerDomain, setCustomerDomain] = useState<string>();
  const [error, setError] = useState<string>();

  async function submit() {
    if (apiKey == null) {
      setError("API key is required");
      return;
    }
    if (customerDomain == null) {
      setError("URL is required");
      return;
    }

    const response = await apiClient.PUT("/integration/sentinel-one", {
      body: {
        url: customerDomain,
        apiKey: apiKey,
      },
    });

    if (response.error != null) {
      setError(
        response.error.message ??
          "Error adding integration, please check the instructions"
      );
      return;
    }

    props.onComplete();
  }

  return (
    <>
      <div className="space-y-4">
        <div>
          <Label>SentinelOne Domain</Label>
          <Input
            value={customerDomain}
            onChange={(e) => setCustomerDomain(e.target.value)}
            name="customerDomain"
            type="url"
            placeholder="https://usea1-011.sentinelone.net/"
          />
        </div>

        <div>
          <Label>API Key</Label>
          <Input
            type="password"
            value={apiKey}
            onChange={(e) => setAPIKey(e.target.value)}
            name="clientSecret"
            placeholder="Client Secret"
          />
        </div>
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}
      <div className="flex flex-col lg:flex-row gap-4">
        <Button onClick={submit}>Integrate</Button>
        <a href="/docs/integrations/sentinel-one">
          <Button variant="outline">Integration Instructions</Button>
        </a>
      </div>
    </>
  );
}
