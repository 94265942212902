import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import { getDetailsFromRaw } from "@/lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@radix-ui/react-icons";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { getCaseStatusConfigByStatus } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/assets/ips/$ipId")({
  component: File,
  loader: async ({ params, context }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(getOptions(params.ipId)),
      context.queryClient.ensureQueryData(
        getSearchUserOptions({ ipId: params.ipId })
      ),
      context.queryClient.ensureQueryData(
        getSearchEndpointOptions({ ipId: params.ipId })
      ),
      context.queryClient.ensureQueryData(
        getSearchCasesOptions({}, params.ipId)
      ),
    ]);
  },
});

async function getData(ipId: string) {
  const ip = await apiClient.GET("/ip/{id}", {
    params: { path: { id: ipId } },
  });
  if (ip.error != null) {
    throw new Error("Error getting IP information");
  }
  return ip.data;
}

export const IP_QUERY_KEY = "ip";
const getOptions = (ipId: string) =>
  queryOptions({
    queryKey: [IP_QUERY_KEY, ipId],
    queryFn: () => getData(ipId),
  });

async function searchUsers(
  searchSettings: components["schemas"]["DirectoryUserSearchDto"]
) {
  const users = await apiClient.POST("/directory", {
    body: searchSettings,
  });
  if (users.error != null) {
    throw new Error("Error getting user information");
  }
  return users.data;
}
export const USER_QUERY_KEY = "ip-user";
const getSearchUserOptions = (
  searchSettings: components["schemas"]["DirectoryUserSearchDto"]
) =>
  queryOptions({
    queryKey: [USER_QUERY_KEY, searchSettings],
    queryFn: () => searchUsers(searchSettings),
    placeholderData: keepPreviousData,
  });

async function searchEndpoints(
  searchSettings: components["schemas"]["EndpointSearchDto"]
) {
  const users = await apiClient.POST("/endpoint", {
    body: searchSettings,
  });
  if (users.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return users.data;
}
export const ENDPOINT_QUERY_KEY = "ip-endpoint";
const getSearchEndpointOptions = (
  searchSettings: components["schemas"]["EndpointSearchDto"]
) =>
  queryOptions({
    queryKey: [ENDPOINT_QUERY_KEY, searchSettings],
    queryFn: () => searchEndpoints(searchSettings),
    placeholderData: keepPreviousData,
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  ipId: string
) {
  const users = await apiClient.POST("/cases", {
    body: { ...searchSettings, assetId: ipId, assetType: "IP" },
  });
  if (users.error != null) {
    throw new Error("Error getting case information");
  }
  return users.data;
}
export const CASE_QUERY_KEY = "case-endpoint";
const getSearchCasesOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  ipId: string
) =>
  queryOptions({
    queryKey: [CASE_QUERY_KEY, searchSettings, ipId],
    queryFn: () => searchCases(searchSettings, ipId),
    placeholderData: keepPreviousData,
  });

function File() {
  const { ipId } = Route.useParams();
  const [userSearchSettings, setUserSearchSettings] = useState<
    components["schemas"]["DirectoryUserSearchDto"]
  >({ ipId });
  const [endpointSearchSettings, setEndpointSearchSettings] = useState<
    components["schemas"]["EndpointSearchDto"]
  >({ ipId });
  const [caseSearchSettings, setCaseSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const usersQuery = useSuspenseQuery(getSearchUserOptions(userSearchSettings));
  const casesQuery = useSuspenseQuery(
    getSearchCasesOptions(caseSearchSettings, ipId)
  );
  const endpointsQuery = useSuspenseQuery(
    getSearchEndpointOptions(endpointSearchSettings)
  );
  const { data: ip } = useSuspenseQuery(getOptions(ipId));
  const navigate = useNavigate();

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 space-y-0 items-center mb-4 flex flex-col gap-4 lg:flex-row lg:justify-between">
            <div className="flex flex-col justify-center">
              <CardTitle>{ip.ipv4 ?? ip.ipv6}</CardTitle>
              <CardDescription>IP Address</CardDescription>
            </div>
          </CardHeader>
          <CardContent className="grid grid-cols-1 lg:grid-cols-2 overflow-auto gap-x-8 gap-y-2">
            <div className="flex flex-row justify-between">
              <h2 className="text-muted-foreground font-mono">created at</h2>
              <p>{dateTime(ip.createdAt)}</p>
            </div>
            {ip.metadata != null ? getDetailsFromRaw(ip.metadata) : null}
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/cases/$caseId",
              params: { caseId: row.id },
            })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setCaseSearchSettings({ ...caseSearchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getCaseStatusConfigByStatus(value)?.display,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this IP has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>

        <TableCard
          onClick={(row) =>
            navigate({
              to: "/assets/users/$userId",
              params: { userId: row.id },
            })
          }
          query={usersQuery}
          onUpdate={(settings) =>
            setUserSearchSettings({ ...userSearchSettings, ...settings })
          }
          headers={[
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Email",
              key: "email",
              sortable: true,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Users</CardTitle>
            <CardDescription>
              Users that this IP has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/assets/endpoints/$endpointId",
              params: { endpointId: row.id },
            })
          }
          query={endpointsQuery}
          onUpdate={(settings) =>
            setEndpointSearchSettings({
              ...endpointSearchSettings,
              ...settings,
            })
          }
          headers={[
            { display: "Name", key: "name", sortable: true },
            { display: "Live", key: "live", sortable: true },
            { display: "Private IP", key: "privateIpAddress", sortable: true },
            {
              display: "Public IP",
              key: "id",
              format(value, row) {
                return row.publicIPs?.map((v) => v.ipv4 ?? v.ipv6).join(", ");
              },
            },
            { display: "OS", key: "operatingSystem", sortable: true },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Endpoints</CardTitle>
            <CardDescription>
              Endpoints that this IP has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
