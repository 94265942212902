import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { Dialog, DialogTrigger, DialogContent } from "@radix-ui/react-dialog";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import { ConstrainedAppLayout } from "@/components/app-layout";
import { FILE_QUERY_KEY } from "@/routes/_application/assets/files/$fileId";

export const Route = createFileRoute("/_application/assets/files/")({
  component: Files,
  loader: async ({ context, deps }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(getFileOptions()),
      context.queryClient.ensureQueryData(getFileAutomationOptions()),
    ]);
  },
});

function getFileOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [FILES_QUERY_KEY, settings],
    queryFn: () => searchFiles(settings),
    placeholderData: keepPreviousData,
  });
}

function getFileAutomationOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [FILE_AUTOMATIONS_KEY, settings],
    queryFn: () => getFileAutomations(settings),
    placeholderData: keepPreviousData,
  });
}

async function searchFiles(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/file", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting files");
  }

  return response.data;
}

async function getFileAutomations(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  const response = await apiClient.POST("/file/automation", {
    body: searchSettings,
  });

  if (response.error != null) {
    throw new Error("Error getting file automations");
  }

  return response.data;
}

const FILE_AUTOMATIONS_KEY = "settings-file-automations";
const FILES_QUERY_KEY = "settings-files";

export default function Files() {
  const queryClient = useQueryClient();
  const [fileAutomationSearchSettings, setFileAutomationSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useSuspenseQuery(getFileOptions(searchSettings));
  const fileAutomationsQuery = useSuspenseQuery(
    getFileAutomationOptions(fileAutomationSearchSettings)
  );

  async function addFileAutomation() {
    if (searchSettings == null || searchSettings.search == null) {
      toast.error("Please add a search");
      return;
    }
    let search = formatSearchQuery(searchSettings.search);

    const response = await apiClient.PUT("/file/automation", {
      body: {
        search: search,
      },
    });

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error("File automation already exists");
      } else {
        toast.error("Error creating file automation");
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [FILES_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [FILE_AUTOMATIONS_KEY],
    });
    toast.success("File Automation created");
  }

  async function updateFile(
    id: string,
    dto: components["schemas"]["UpdateFileDto"]
  ) {
    const response = await apiClient.PATCH("/file/{id}", {
      body: dto,
      params: { path: { id } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [FILES_QUERY_KEY] });
    toast.success("File updated");
  }

  async function deleteFileAutomation(id: string) {
    const response = await apiClient.DELETE("/file/automation/{id}", {
      params: { path: { id } },
    });

    if (response.error != null) {
      toast.error("Error deleting automation");
      return;
    }

    await queryClient.invalidateQueries({ queryKey: [FILE_AUTOMATIONS_KEY] });
    await queryClient.invalidateQueries({ queryKey: [FILES_QUERY_KEY] });
    toast.warning("Automation deleted");
  }

  if (query.isLoading) {
    return <Skeleton className="w-full h-[256px]" />;
  }

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <TableCard
          onUpdate={updateSearchSettings}
          query={query}
          searchable
          compact
          onClick={(row) =>
            navigate({
              to: "/assets/files/$fileId",
              params: { fileId: row.id },
            })
          }
          headers={[
            { display: "Name", key: "name", sortable: true },
            { display: "Path", key: "path", sortable: true },
            { display: "Excluded", key: "excluded", sortable: true },
            {
              display: "Excluded Overridden",
              key: "excludedOverriddenByUser",
              sortable: true,
              info: ` A user has manually updated the excluded status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable Excluded Lock".`,
            },
          ]}
          rowActions={[
            {
              name: (row) =>
                row.excluded ? `Disable Exclusion` : "Enable Exclusion",
              onClick: (row) => updateFile(row.id, { excluded: !row.excluded }),
            },
            {
              name: (row) =>
                row.excludedOverriddenByUser
                  ? `Disable Exclusion Lock`
                  : "Enable Exclusion Lock",
              onClick: (row) =>
                updateFile(row.id, {
                  excludedOverriddenByUser: !row.excludedOverriddenByUser,
                }),
            },
          ]}
        >
          <CardHeader>
            <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
              <div className="flex flex-col gap-2">
                <CardTitle>Files</CardTitle>
                <CardDescription>
                  Files pulled from detections on your endpoints
                </CardDescription>
              </div>
              {searchSettings?.search && (
                <Button onClick={addFileAutomation}>
                  Add New File Automation
                </Button>
              )}
            </div>
          </CardHeader>
        </TableCard>

        <TableCard
          onUpdate={setFileAutomationSearchSettings}
          query={fileAutomationsQuery}
          searchable
          headers={[
            {
              display: "Search Field",
              key: "search",
              format: (value, row) => "fullPath",
            },
            { display: "Search", key: "search" },
          ]}
          rowActions={[
            {
              name: "Delete Automation",
              onClick: (row) => deleteFileAutomation(row.id),
              confirm: true,
              confirmMessage:
                "Are you sure you want to delete this file Automation? This will also unexclude any files identified from this automation.",
            },
          ]}
        >
          <CardHeader>
            <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
              <div>
                <CardTitle>File Automations</CardTitle>
                <CardDescription>
                  Search queries to automatically exclude files in your
                  organization from being considered as malicious. To create a
                  new automation, issue a search above and select 'Create
                  Automation'.
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
