import {
  type CaseStatus,
  type IntegrationPlatform,
  IntegrationType,
  type Nullable,
} from "./shared.types";

export enum DetectionCategory {
  MALWARE = "MALWARE",
  DECEPTION = "DECEPTION",
  THREAT_INDICATOR_MATCH = "THREAT_INDICATOR_MATCH",
  WARNING = "WARNING",
  POTENTIAL_ADMIN_ABUSE = "POTENTIAL_ADMIN_ABUSE",
  NUISANCE_SOFTWARE = "NUISANCE_SOFTWARE",
  DIAGNOSTIC = "DIAGNOSTIC",
  POSTURE = "POSTURE",
  NON_THREAT = "NON_THREAT",
  PHISH = "PHISH",
  REPORTED_PHISH = "REPORTED_PHISH",
  EXPOSED_CREDENTIALS = "EXPOSED_CREDENTIALS",
  SUSPICIOUS_LOGIN = "SUSPICIOUS_LOGIN",
  SUSPICIOUS_EXECUTION = "SUSPICIOUS_EXECUTION",
  BRUTE_FORCE = "BRUTE_FORCE",
  INCOMPLETE_LOGIN = "INCOMPLETE_LOGIN",
  UNKNOWN = "UNKNOWN",
}

export interface IDetectionCategoryConfig {
  category: DetectionCategory;
  types: IntegrationType[];
  display: string;
}

export const DetectionCategoryConfig: {
  [key in DetectionCategory]: IDetectionCategoryConfig;
} = {
  [DetectionCategory.BRUTE_FORCE]: {
    category: DetectionCategory.BRUTE_FORCE,
    display: "Brute Force",
    types: [IntegrationType.DETECTIONS_IDENTITY],
  },
  [DetectionCategory.PHISH]: {
    category: DetectionCategory.PHISH,
    display: "Phish",
    types: [IntegrationType.DETECTIONS_EMAIL],
  },
  [DetectionCategory.REPORTED_PHISH]: {
    category: DetectionCategory.REPORTED_PHISH,
    display: "Reported phish",
    types: [IntegrationType.DETECTIONS_EMAIL],
  },
  [DetectionCategory.WARNING]: {
    category: DetectionCategory.WARNING,
    display: "Warning",
    types: Object.values(IntegrationType),
  },
  [DetectionCategory.NON_THREAT]: {
    category: DetectionCategory.NON_THREAT,
    display: "Non-threat",
    types: Object.values(IntegrationType),
  },
  [DetectionCategory.DECEPTION]: {
    category: DetectionCategory.DECEPTION,
    display: "Deception",
    types: [IntegrationType.DETECTIONS_ENDPOINT],
  },
  [DetectionCategory.DIAGNOSTIC]: {
    category: DetectionCategory.DIAGNOSTIC,
    display: "Diagnostic",
    types: Object.values(IntegrationType),
  },
  [DetectionCategory.INCOMPLETE_LOGIN]: {
    category: DetectionCategory.INCOMPLETE_LOGIN,
    display: "Incomplete Login",
    types: [IntegrationType.DETECTIONS_IDENTITY],
  },
  [DetectionCategory.MALWARE]: {
    category: DetectionCategory.MALWARE,
    display: "Malware",
    types: [IntegrationType.DETECTIONS_ENDPOINT],
  },
  [DetectionCategory.POSTURE]: {
    category: DetectionCategory.POSTURE,
    display: "Posture",
    types: Object.values(IntegrationType),
  },
  [DetectionCategory.POTENTIAL_ADMIN_ABUSE]: {
    category: DetectionCategory.POTENTIAL_ADMIN_ABUSE,
    display: "Potential Admin Abuse",
    types: [IntegrationType.DETECTIONS_ENDPOINT],
  },
  [DetectionCategory.NUISANCE_SOFTWARE]: {
    category: DetectionCategory.NUISANCE_SOFTWARE,
    display: "Nuisance Software",
    types: [IntegrationType.DETECTIONS_ENDPOINT],
  },
  [DetectionCategory.SUSPICIOUS_LOGIN]: {
    category: DetectionCategory.SUSPICIOUS_LOGIN,
    display: "Suspicious Login",
    types: [IntegrationType.DETECTIONS_IDENTITY],
  },
  [DetectionCategory.SUSPICIOUS_EXECUTION]: {
    category: DetectionCategory.SUSPICIOUS_EXECUTION,
    display: "Suspicious Execution",
    types: [IntegrationType.DETECTIONS_ENDPOINT],
  },
  [DetectionCategory.EXPOSED_CREDENTIALS]: {
    category: DetectionCategory.EXPOSED_CREDENTIALS,
    display: "Exposed Credentials",
    types: [IntegrationType.DETECTIONS_IDENTITY],
  },
  [DetectionCategory.THREAT_INDICATOR_MATCH]: {
    category: DetectionCategory.THREAT_INDICATOR_MATCH,
    display: "Threat Indicator Match",
    types: [IntegrationType.DETECTIONS_ENDPOINT],
  },
  [DetectionCategory.UNKNOWN]: {
    category: DetectionCategory.UNKNOWN,
    display: "Unknown",
    types: Object.values(IntegrationType),
  },
};
