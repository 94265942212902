import {
  Link,
  createFileRoute,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import { AppLayout } from "@/components/app-layout";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "@/components/ui/table";
import moment from "moment";
import numeral from "numeral";

import { Button } from "@/components/ui/button";
import { User, apiClient } from "@/lib/api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import {
  ArrowUpRightIcon,
  ExclamationTriangleIcon,
  UserIcon,
  WrenchIcon,
} from "@heroicons/react/16/solid";
import {
  ArrowsRightLeftIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClockIcon,
  CpuChipIcon,
} from "@heroicons/react/24/outline";
import { getIntegrationConfigByPlatform, IntegrationType } from "@wire/shared";

export const Route = createFileRoute("/_application/dashboard")({
  component: Dashboard,
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getOptions(context.user));
  },
});

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  Area,
  Line,
  LineChart,
  YAxis,
} from "recharts";
import { ReactNode, useMemo } from "react";
import { cn } from "@/lib/utils";
import { dateTimeBuilder } from "@/lib/time";
import { components } from "@/lib/api.types";

const chartConfig = {
  desktop: {
    label: "Week",
    color: "hsl(var(--chart-1))",
  },
  count: {
    label: "Cases",
  },
} satisfies ChartConfig;

async function getData(user: User) {
  const [
    caseCount,
    weeklyCases,
    mttr,
    mttd,
    closedCases,
    recentVIPs,
    integrations,
  ] = await Promise.all([
    apiClient.POST("/cases/count", { body: { status: "ESCALATED" } }),
    apiClient.GET("/cases/count/weekly"),
    apiClient.GET("/cases/mttr"),
    apiClient.GET("/cases/mttd"),
    apiClient.POST("/cases", {
      body: { statuses: ["CLOSED"], orderBy: "closedAt", orderDir: "desc" },
    }),
    apiClient.POST("/directory", {
      body: { vipOnly: true, orderBy: "createdAt" },
    }),
    apiClient.POST("/integration", { body: { size: 1000 } }),
  ]);
  if (
    caseCount.error != null ||
    weeklyCases.error != null ||
    closedCases.error != null ||
    mttr.error != null ||
    mttd.error != null ||
    recentVIPs.error != null ||
    integrations.error != null
  ) {
    throw new Error("Error getting dashboard information");
  }
  return {
    caseCount: caseCount.data,
    weeklyCases: weeklyCases.data,
    closedCases: closedCases.data,
    mttr: mttr.data,
    mttd: mttd.data,
    recentVIPs: recentVIPs.data,
    integrations: integrations.data,
  };
}

export const DASHBOARD_QUERY_KEY = "dashboard";
const getOptions = (user: User) =>
  queryOptions({
    queryKey: [DASHBOARD_QUERY_KEY],
    queryFn: () => getData(user),
  });

export default function Dashboard() {
  const { user } = useRouteContext({ from: "/_application/dashboard" });
  const {
    data: {
      caseCount,
      weeklyCases,
      closedCases,
      mttr,
      mttd,
      recentVIPs,
      integrations,
    },
  } = useSuspenseQuery(getOptions(user));
  const formattedWeeklyCases = useMemo(() => {
    return weeklyCases.map((v) => ({
      count: v.count,
      week: moment(v.week).format("MMM DD"),
    }));
  }, [weeklyCases]);
  const integrationHealth = useMemo(() => {
    if (integrations.totalCount == 0) return 100;
    if (integrations.data == null) return 100;
    return (
      integrations.data.reduce((acc, v) => acc + v.healthyPercentage, 0) /
      integrations.data.length
    );
  }, [integrations]);

  return (
    <AppLayout>
      <main className="flex flex-1 flex-col gap-4 md:gap-8">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
          <Link to={"/cases"} search={{ filter: "ESCALATED" }}>
            <Card className="hover:shadow-md h-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Escalated Cases
                </CardTitle>
                <HealthIcon
                  icon={ExclamationTriangleIcon}
                  health={((10 - caseCount.count) / 10) * 100}
                />
              </CardHeader>
              <CardContent>
                <div className={cn("text-2xl font-bold")}>
                  {caseCount.count}
                </div>
              </CardContent>
            </Card>
          </Link>
          <Link to={"/settings"} search={{ tab: "integrations" }}>
            <Card className="hover:shadow-md h-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Integration Health
                </CardTitle>
                <HealthIcon
                  icon={ArrowsRightLeftIcon}
                  health={integrationHealth}
                />
              </CardHeader>
              <CardContent>
                <div className={cn("text-2xl font-bold")}>
                  {integrationHealth}%
                </div>
              </CardContent>
            </Card>
          </Link>

          <Link to="/cases">
            <Card className="hover:shadow-md h-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Mean Time To Detect
                </CardTitle>

                <HealthIcon
                  icon={ClockIcon}
                  health={
                    mttd.avgSeconds < 120
                      ? 100
                      : mttd.avgSeconds > 60 * 60
                        ? 0
                        : 75
                  }
                />
              </CardHeader>
              <CardContent>
                {mttd.avgSeconds == null ? (
                  <div className="text-2xl font-bold">N/A</div>
                ) : (
                  <>
                    <div className="text-2xl font-bold">
                      {moment.duration(mttd.avgSeconds * 1000).humanize()}
                    </div>
                    <p className="text-xs text-muted-foreground">
                      {numeral(mttd.avgSeconds).format()} seconds
                      {mttd.change != null &&
                        ` | ${numeral(mttd.change).format("+0[a]%")} from last month`}
                    </p>
                  </>
                )}
              </CardContent>
            </Card>
          </Link>

          <Link to="/cases">
            <Card className="hover:shadow-md h-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Mean Time To Respond
                </CardTitle>

                <HealthIcon
                  icon={WrenchIcon}
                  health={
                    mttr.avgSeconds < 120
                      ? 100
                      : mttr.avgSeconds > 60 * 60
                        ? 0
                        : 75
                  }
                />
              </CardHeader>
              <CardContent>
                {mttr.avgSeconds == null ? (
                  <div className="text-2xl font-bold">N/A</div>
                ) : (
                  <>
                    <div className="text-2xl font-bold">
                      {moment.duration(mttr.avgSeconds * 1000).humanize()}
                    </div>
                    <p className="text-xs text-muted-foreground">
                      {numeral(mttr.avgSeconds).format()} seconds
                      {mttr.change != null &&
                        ` | ${numeral(mttr.change).format("+0[a]%")} from last month`}
                    </p>
                  </>
                )}
              </CardContent>
            </Card>
          </Link>
        </div>
        <Card>
          <CardHeader>
            <CardTitle>Trends</CardTitle>
            <CardDescription>Cases over the last 3 months</CardDescription>
          </CardHeader>
          <CardContent className="flex items-center justify-center">
            {formattedWeeklyCases.length < 2 ? (
              <div className="italic text-muted-foreground">
                Not enough data yet. Check back soon!
              </div>
            ) : (
              <ChartContainer
                className="w-full h-24 min-h-24"
                config={chartConfig}
              >
                <AreaChart
                  accessibilityLayer
                  data={formattedWeeklyCases}
                  margin={{
                    top: 6,
                    bottom: 2,
                  }}
                >
                  <XAxis
                    dataKey="week"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <ChartTooltip
                    cursor={false}
                    labelFormatter={(label) => `Week of ${label}`}
                    content={<ChartTooltipContent indicator="line" />}
                  />
                  <Area
                    dataKey="count"
                    label="Count"
                    type="basis"
                    fill="var(--color-desktop)"
                    fillOpacity={0.4}
                    stroke="var(--color-desktop)"
                  />
                </AreaChart>
              </ChartContainer>
            )}
          </CardContent>
        </Card>
        <div className="grid gap-4 md:gap-8 grid-cols-1 xl:grid-cols-3">
          <Card>
            <CardHeader>
              <CardTitle>Recently Closed Cases</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {closedCases.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/no-cases.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No closed cases yet</h3>
                </div>
              )}
              {closedCases.data.map((v) => (
                <Link key={v.id} to="/cases/$caseId" params={{ caseId: v.id }}>
                  <div className="grid lg:grid-cols-4 grid-cols-1 hover:bg-muted p-2 rounded-md items-center gap-4">
                    <div className="col-span-3 flex space-x-2">
                      <CheckBadgeIcon className="h-9 w-9 flex-shrink-0 text-green-500" />
                      <div className="flex flex-col gap-1 justify-center">
                        <p className="text-sm font-medium leading-none">
                          {v.title}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          {v.name}
                        </p>
                      </div>
                    </div>
                    {v.closedAt && (
                      <div className="flex-shrink-0 text-muted-foreground lg:text-right text-sm">
                        {dateTimeBuilder(v.closedAt).fromNow()}
                      </div>
                    )}
                  </div>
                </Link>
              ))}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Integrations</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {integrations.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/lost.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No integrations yet</h3>
                </div>
              )}
              {integrations.data.map((v) => {
                const config = getIntegrationConfigByPlatform(v.platform);
                return (
                  <Link
                    key={v.id}
                    to="/settings/integrations/$integrationId"
                    params={{ integrationId: v.id }}
                  >
                    <div className="grid lg:grid-cols-4 grid-cols-1 hover:bg-muted p-2 rounded-md items-center gap-4">
                      <div className="col-span-3 items-center flex space-x-2">
                        <img src={config?.logo} className="h-6 w-6" />
                        <div className="grid gap-1">
                          <p className="text-sm font-medium leading-none">
                            {getIntegrationConfigByPlatform(v.platform).display}
                          </p>
                          <p className="text-sm text-muted-foreground">
                            {v.lastUsedAt != null
                              ? `Last used ${dateTimeBuilder(v.lastUsedAt).fromNow()}`
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="flex-shrink-0 text-muted-foreground lg:text-right text-sm"></div>
                    </div>
                  </Link>
                );
              })}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Recently Discovered VIPs</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {recentVIPs.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/empty-void.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No VIPs yet</h3>
                </div>
              )}
              {recentVIPs.data.map((v) => (
                <Link
                  key={v.id}
                  to="/assets/users/$userId"
                  params={{ userId: v.id }}
                >
                  <div className="grid lg:grid-cols-4 grid-cols-1 hover:bg-muted p-2 rounded-md items-center gap-4">
                    <div className="col-span-3 flex space-x-2">
                      <UserIcon className="h-9 w-9 flex-shrink-0 text-purple-500" />
                      <div className="grid gap-1">
                        <p className="text-sm font-medium leading-none">
                          {v.name}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          {v.title ?? "-"}
                        </p>
                      </div>
                    </div>
                    <div className="flex-shrink-0 text-muted-foreground lg:text-right text-sm">
                      {dateTimeBuilder(v.createdAt).fromNow()}
                    </div>
                  </div>
                </Link>
              ))}
            </CardContent>
          </Card>
        </div>
      </main>
    </AppLayout>
  );
}

function HealthIcon(props: {
  health: number;
  icon: React.FC<{ className?: string }>;
}) {
  if (props.health == 100) {
    return <CheckIcon className="h-4 w-4 text-green-500" />;
  }
  return (
    <props.icon
      className={cn("h-4 w-4 text-muted-foreground", {
        "text-yellow-500": props.health >= 75 && props.health < 100,
        "text-red-500": props.health < 75,
      })}
    />
  );
}
