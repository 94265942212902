export enum NotificationFrequency {
  REALTIME = "REALTIME",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}

export enum NotificationType {
  NEW_CASE_ESCALATION = "NEW_CASE_ESCALATION",
  NEW_VIP = "NEW_VIP",
  NEW_HVA = "NEW_HVA",
  NEW_SECURITY_EVENT = "NEW_SECURITY_EVENT",
}

export const NotificationTypeConfig = {
  [NotificationType.NEW_CASE_ESCALATION.toString()]: {
    display: "New Case Escalation",
    type: NotificationType.NEW_CASE_ESCALATION,
    description: "A new case has been escalated in response to a new detection",
  },
  [NotificationType.NEW_VIP.toString()]: {
    display: "New VIP",
    description: "A new VIP has been identified in your organization",
    type: NotificationType.NEW_VIP,
  },
  [NotificationType.NEW_HVA.toString()]: {
    display: "New High Value Asset",
    description: "A new HVA has been identified in your organization",
    type: NotificationType.NEW_HVA,
  },
  [NotificationType.NEW_SECURITY_EVENT.toString()]: {
    display: "New System Security Event",
    description: "A new security event has taken place in your account",
    type: NotificationType.NEW_SECURITY_EVENT,
  },
};

export const NOTIFICATION_TYPE_CONFIG_LIST = Object.values(
  NotificationTypeConfig
);

export const NOTIFICATION_TYPE_LIST = Object.values(NotificationType);

export function getNotificationTypeConfig(type: string) {
  return NotificationTypeConfig[type as NotificationType];
}

export const NotificationFrequencyConfig = {
  [NotificationFrequency.REALTIME.toString()]: {
    display: "Real Time",
    type: NotificationFrequency.REALTIME,
  },
  [NotificationFrequency.DAILY.toString()]: {
    display: "Daily",
    type: NotificationFrequency.DAILY,
  },
  [NotificationFrequency.WEEKLY.toString()]: {
    display: "Weekly",
    type: NotificationFrequency.WEEKLY,
  },
};

export const NOTIFICATION_FREQUENCY_CONFIG_LIST = Object.values(
  NotificationFrequencyConfig
);

export function getNotificationFrequencyConfig(type: string) {
  return NotificationType[type as NotificationType];
}
