"use client";
import { toast } from "sonner";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { useDebounce } from "@/lib/utils";
import AddTeamMember from "./add-team-member";
import { TableCard } from "../table-card";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useRouteContext } from "@tanstack/react-router";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { GLOBAL_TEAM_QUERY_KEY, router } from "@/main";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import DeleteTeam from "@/components/settings/delete-team";

export const SETTINGS_TEAM_MEMBER_QUERY = "settings-team-member";

async function getTeamMembers(search: components["schemas"]["PaginationDto"]) {
  const response = await apiClient.POST("/team", { body: search });
  if (response.error != null) {
    throw new Error("Error getting team members");
  }
  return response.data;
}

export default function TeamSettings() {
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  const { team } = useRouteContext({ from: "/_application" });
  const [teamName, setTeamName] = useState(team.name);
  const [autoContainment, setAutoContainment] = useState(team.autoContainment);
  const [maxAutoContainmentsPerDay, setMaxAutoContainmentsPerDay] = useState(
    team.maxAutoContainmentsPerDay
  );
  const { debounced: debouncedTeamName } = useDebounce(250, teamName);
  const queryClient = useQueryClient();
  const teamMemberQuery = useQuery({
    queryKey: [SETTINGS_TEAM_MEMBER_QUERY, searchSettings],
    queryFn: () => getTeamMembers(searchSettings),
    placeholderData: keepPreviousData,
  });

  async function updateMaxAutoContainmentsPerDay(max?: number) {
    const response = await apiClient.PATCH("/team", {
      body: { maxAutoContainmentsPerDay: max },
    });
    if (response.error != null) {
      toast.error("Error updating max auto containments per day");
      return;
    }
    toast.success("Team updated");
    setMaxAutoContainmentsPerDay(max);
  }

  async function toggleTestMode() {
    const response = await apiClient.PATCH("/team", {
      body: { testMode: !team.testMode },
    });

    if (response.error != null) {
      toast.error("Error updating test mode");
      return;
    }
    toast.success("Team updated");
    // Refresh all data
    await queryClient.invalidateQueries({ queryKey: [] });
    await router.invalidate();
  }

  async function toggleAutoContainment() {
    const response = await apiClient.PATCH("/team", {
      body: { autoContainment: !team.autoContainment },
    });

    if (response.error != null) {
      toast.error("Error updating auto containment");
      return;
    }
    setAutoContainment(!autoContainment);
    toast.success("Team updated");
  }

  useEffect(() => {
    async function update() {
      const response = await apiClient.PATCH("/team", {
        body: { name: debouncedTeamName },
      });

      if (response.error != null) {
        toast.error("Error updating team name");
        return;
      }
      toast.success("Team updated");
      await queryClient.invalidateQueries({
        queryKey: [GLOBAL_TEAM_QUERY_KEY],
      });
    }
    if (debouncedTeamName != team.name) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      update();
    }
  }, [debouncedTeamName]);

  async function lockUser(id: string) {
    const response = await apiClient.PATCH("/users/{id}/lock", {
      params: { path: { id } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.warning("User locked");
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBER_QUERY],
    });
  }

  async function unlockUser(id: string) {
    const response = await apiClient.PATCH("/users/{id}/unlock", {
      params: { path: { id } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.warning("User unlocked");
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBER_QUERY],
    });
  }

  async function deleteUser({ id }: { id: string }) {
    const toastId = toast.loading("Deleting user...");
    const response = await apiClient.DELETE("/users/{id}", {
      params: { path: { id } },
    });
    toast.dismiss(toastId);
    if (response.error != null) {
      toast.error("Error deleting user");
    } else {
      toast.warning("User deleted");
    }
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBER_QUERY],
    });
  }

  return (
    <Card>
      <CardHeader className="flex lg:items-center justify-between lg:flex-row">
        <CardTitle>Team Settings</CardTitle>
        <div className="flex flex-row gap-2">
          <AddTeamMember />
          <DeleteTeam />
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col space-y-2">
            <Label>Team Name</Label>
            <Input
              placeholder="Team name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
          <div className="flex flex-row gap-8 flex-wrap">
            <div className="flex flex-col space-y-2">
              <Label className="flex items-center gap-1">
                Test Mode{" "}
                <a target="_blank" href="/docs/settings#test-mode">
                  <InformationCircleIcon className="h-4 w-4" />
                </a>
              </Label>
              <Switch
                checked={team.testMode}
                onCheckedChange={(e) => toggleTestMode()}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Label className="flex items-center gap-1">
                Auto Containment{" "}
                <a target="_blank" href="/docs/settings#auto-containment">
                  <InformationCircleIcon className="h-4 w-4" />
                </a>
              </Label>
              <Switch
                checked={autoContainment}
                onCheckedChange={(e) => toggleAutoContainment()}
              />
            </div>
            {autoContainment && (
              <div className="flex flex-col space-y-2">
                <Label className="flex items-center gap-1">
                  Max Auto Containments Per Day{" "}
                  <a target="_blank" href="/docs/settings#auto-containment">
                    <InformationCircleIcon className="h-4 w-4" />
                  </a>
                </Label>
                <Input
                  type="number"
                  value={maxAutoContainmentsPerDay}
                  onChange={(e) =>
                    updateMaxAutoContainmentsPerDay(parseInt(e.target.value))
                  }
                />
              </div>
            )}
          </div>
        </div>
        <hr className="my-10" />
        <TableCard
          query={teamMemberQuery}
          onUpdate={setSearchSettings}
          searchable
          headers={[
            { key: "name", display: "Name" },
            { key: "email", display: "Email" },
            { key: "locked", display: "Locked" },
          ]}
          rowActions={[
            {
              name: (row) => (row.locked ? "Unlock User" : "Lock User"),
              onClick: async (row) =>
                row.locked ? unlockUser(row.id) : lockUser(row.id),
            },
            {
              name: "Delete User",
              confirm: true,
              confirmMessage: (row) =>
                `Are you sure you want to delete ${row.name}?`,
              onClick: deleteUser,
            },
          ]}
        >
          <CardHeader>
            <CardTitle>Team Members</CardTitle>
          </CardHeader>
        </TableCard>
      </CardContent>
    </Card>
  );
}
