import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { Dialog, DialogTrigger, DialogContent } from "@radix-ui/react-dialog";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import { ConstrainedAppLayout } from "@/components/app-layout";

export const Route = createFileRoute("/_application/assets/processes/")({
  component: Processes,
  loader: async ({ context, deps }) => {
    await context.queryClient.ensureQueryData(getOptions());
  },
});

function getOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [PROCESSES_QUERY_KEY, settings],
    queryFn: () => getProcesses(settings),
    placeholderData: keepPreviousData,
  });
}

async function getProcesses(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/process", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting processes");
  }

  return response.data;
}

const PROCESSES_QUERY_KEY = "settings-processes";

export default function Processes() {
  const queryClient = useQueryClient();
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useSuspenseQuery(getOptions(searchSettings));

  if (query.isLoading) {
    return <Skeleton className="w-full h-[256px]" />;
  }

  return (
    <ConstrainedAppLayout>
      <TableCard
        onUpdate={updateSearchSettings}
        query={query}
        searchable
        onClick={(row) =>
          navigate({
            to: "/assets/processes/$processId",
            params: { processId: row.id },
          })
        }
        compact
        headers={[
          { display: "Command", key: "command", sortable: true, truncate: 60 },
          { display: "SHA1", key: "sha1", sortable: true },
          { display: "SHA256", key: "sha256", sortable: true },
        ]}
      >
        <CardHeader>
          <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
            <div className="flex flex-col gap-2">
              <CardTitle>Processes</CardTitle>
              <CardDescription>
                Process commands that have been extracted from detections in
                your environment.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
      </TableCard>
    </ConstrainedAppLayout>
  );
}
