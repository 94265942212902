import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { getCaseStatusConfigByStatus } from "@wire/shared";
import { useState } from "react";

export const Route = createFileRoute("/_application/assets/tools/$toolId")({
  component: Tool,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.toolId));
  },
});

async function getData(toolId: string) {
  const [tool] = await Promise.all([
    apiClient.GET("/tool/{id}", {
      params: { path: { id: toolId } },
    }),
  ]);
  if (tool.error != null) {
    throw new Error("Error getting tool information");
  }
  return {
    tool: tool.data,
  };
}

export const ASSET_QUERY_KEY = "tool";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  toolId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "TOOL", assetId: toolId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  toolId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, toolId],
    queryFn: () => searchCases(searchSettings, toolId),
    placeholderData: keepPreviousData,
  });

function Tool() {
  const { toolId } = Route.useParams();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, toolId));
  const {
    data: { tool },
  } = useSuspenseQuery(getOptions(toolId));
  const navigate = useNavigate();

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>{tool.name}</CardTitle>
            <CardDescription>Tool</CardDescription>
          </CardHeader>
          <CardContent className="flex overflow-auto flex-col gap-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">{dateTime(tool.createdAt)}</p>
            </div>
            <div>
              <h2 className="font-semibold">Late Stage Tool</h2>
              <p className="text-sm">
                {tool.lateStage ? (
                  <CheckIcon className="text-green-500 h-6 w-6" />
                ) : (
                  <XMarkIcon className="text-red-500 h-6 w-6" />
                )}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">Remote Management Tool</h2>
              <p className="text-sm">
                {tool.remoteManagement ? (
                  <CheckIcon className="text-green-500 h-6 w-6" />
                ) : (
                  <XMarkIcon className="text-red-500 h-6 w-6" />
                )}
              </p>
            </div>
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({ to: "/cases/$caseId", params: { caseId: row.id } })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getCaseStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "sourceIngestedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this tool has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
