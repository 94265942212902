import { cn } from "@/lib/utils";

interface LogoProps {
  words?: boolean;
  className?: string;
}

export function Logo(props: LogoProps) {
  if (props.words) {
    return (
      <>
        <img
          className={cn("mx-auto h-32 w-auto dark:hidden", props.className)}
          src="/logo-words-light.svg"
          alt="Wirespeed"
        />
        <img
          src="/logo-words-dark.svg"
          className={cn(
            "mx-auto h-32 dark:block hidden w-auto",
            props.className
          )}
          alt="Wirespeed"
        />
      </>
    );
  } else {
    return <img className={cn("w-6 h-6", props.className)} src="/logo.svg" />;
  }
}
