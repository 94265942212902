import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import {
  CheckIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { CaseStatus, getCaseStatusConfigByStatus } from "@wire/shared";
import moment from "moment";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/assets/users/$userId")({
  component: User,
  loader: async ({ params, context }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(getOptions(params.userId)),
      context.queryClient.ensureQueryData(
        getSearchCaseOptions({}, params.userId)
      ),
      context.queryClient.ensureQueryData(
        getSearchIPsOptions({}, params.userId)
      ),
    ]);
  },
});

async function getData(userId: string) {
  const user = await apiClient.GET("/directory/{id}", {
    params: { path: { id: userId } },
  });
  if (user.error != null) {
    throw new Error("Error getting user information");
  }
  return user.data;
}

export const ASSET_QUERY_KEY = "user";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
  });

async function searchIPs(
  searchSettings: components["schemas"]["SearchIPDto"],
  userId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/ip", {
      body: { ...searchSettings, userId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting IP information");
  }
  return cases.data;
}
export const IP_QUERY_KEY = "user-ips";
const getSearchIPsOptions = (
  searchSettings: components["schemas"]["SearchIPDto"],
  userId: string
) =>
  queryOptions({
    queryKey: [IP_QUERY_KEY, searchSettings, userId],
    queryFn: () => searchIPs(searchSettings, userId),
    placeholderData: keepPreviousData,
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  userId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "USER", assetId: userId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  userId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, userId],
    queryFn: () => searchCases(searchSettings, userId),
    placeholderData: keepPreviousData,
  });

function User() {
  const { userId } = Route.useParams();
  const [caseSearchSettings, setCaseSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const [ipSearchSettings, setIPSearchSettings] = useState<
    components["schemas"]["SearchIPDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(caseSearchSettings, userId));
  const ipsQuery = useQuery(getSearchIPsOptions(ipSearchSettings, userId));
  const { data: user } = useSuspenseQuery(getOptions(userId));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function updateUser(
    dto: components["schemas"]["UpdateDirectoryUserDto"]
  ) {
    const response = await apiClient.PATCH("/directory/{id}", {
      params: { path: { id: userId } },
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
    }
    await queryClient.invalidateQueries({
      queryKey: [ASSET_QUERY_KEY, userId],
    });
  }

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 mb-4  space-y-0 lg:items-center flex-col gap-4 lg:flex-row lg:justify-between">
            <div>
              <CardTitle>{user.name ?? user.email ?? user.id}</CardTitle>
              <CardDescription>User</CardDescription>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button>Actions</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {user.vip && (
                    <DropdownMenuItem
                      onClick={() => updateUser({ vip: false })}
                    >
                      Remove VIP Status
                    </DropdownMenuItem>
                  )}
                  {!user.vip && (
                    <DropdownMenuItem onClick={() => updateUser({ vip: true })}>
                      Set as VIP
                    </DropdownMenuItem>
                  )}
                  {user.technical && (
                    <DropdownMenuItem
                      onClick={() => updateUser({ technical: false })}
                    >
                      Remove Technical Status
                    </DropdownMenuItem>
                  )}
                  {!user.technical && (
                    <DropdownMenuItem
                      onClick={() => updateUser({ technical: true })}
                    >
                      Set as Technical
                    </DropdownMenuItem>
                  )}
                  {user.credentialsExposed && (
                    <DropdownMenuItem
                      onClick={() => updateUser({ credentialsExposed: false })}
                    >
                      Set credentials as not exposed
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              <div>
                <h2 className="font-semibold">Ingested by Wirespeed At</h2>
                <p className="text-sm">{dateTime(user.createdAt)}</p>
              </div>
              <div>
                <h2 className="font-semibold">VIP</h2>
                <p className="text-sm">
                  {user.vip ? (
                    <CheckIcon className="text-green-500 h-6 w-6" />
                  ) : (
                    <XMarkIcon className="text-red-500 h-6 w-6" />
                  )}
                </p>
              </div>
              <div>
                <h2 className="font-semibold">Technical</h2>
                <p className="text-sm">
                  {user.technical ? (
                    <CheckIcon className="text-green-500 h-6 w-6" />
                  ) : (
                    <XMarkIcon className="text-red-500 h-6 w-6" />
                  )}
                </p>
              </div>
              <div>
                <h2 className="font-semibold">Email</h2>
                <p className="text-sm">{user.email}</p>
              </div>
              <div>
                <h2 className="font-semibold">Source ID</h2>
                <p className="text-sm">{user.directoryId}</p>
              </div>
              <div>
                <h2 className="font-semibold">Department</h2>
                <p className="text-sm">{user.department ?? "-"}</p>
              </div>
              <div>
                <h2 className="font-semibold">Title</h2>
                <p className="text-sm">{user.title ?? "-"}</p>
              </div>
              {user.managerEmail && (
                <div>
                  <h2 className="font-semibold">Manager</h2>
                  <p className="text-sm">{user.managerEmail}</p>
                </div>
              )}
              {user.lastCheckedForCredentialExposures != null && (
                <div>
                  <h2 className="font-semibold flex items-center gap-1">
                    Credentials Exposed{" "}
                    <a href="/docs/integrations/hibp/" target="_blank">
                      <InformationCircleIcon className="h-4 w-4" />
                    </a>
                  </h2>
                  <p className="text-sm">
                    {user.credentialsExposed ? (
                      <CheckIcon className="text-green-500 h-6 w-6" />
                    ) : (
                      <XMarkIcon className="text-red-500 h-6 w-6" />
                    )}
                  </p>
                </div>
              )}
            </div>
          </CardContent>
          {user.lastCheckedForCredentialExposures != null && (
            <CardFooter className="text-xs text-muted-foreground">
              <div>
                Breach data provided by{" "}
                <a target="_blank" href="https://haveibeenpwned.com/">
                  haveibeenpwned.com
                </a>
              </div>
            </CardFooter>
          )}
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({ to: "/cases/$caseId", params: { caseId: row.id } })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setCaseSearchSettings({ ...caseSearchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getCaseStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "sourceIngestedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this user has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>

        <TableCard
          onClick={(row) =>
            navigate({ to: "/assets/ips/$ipId", params: { ipId: row.id } })
          }
          query={ipsQuery}
          onUpdate={(settings) =>
            setCaseSearchSettings({ ...caseSearchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "id",
              sortable: true,
            },
            {
              display: "IP",
              key: "id",
              format: (value, row) => row.ipv4 ?? row.ipv6,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related IP Addresses</CardTitle>
            <CardDescription>
              IPs that this user has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
