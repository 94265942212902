import { components } from "@/lib/api.types";
import { dashCaseToTitleCase } from "@/lib/utils";
import {
  CardHeader,
  Card,
  CardTitle,
  CardDescription,
  CardContent,
} from "../ui/card";
import { Badge } from "../ui/badge";
import { QueueStatus } from "@wire/shared";
import { Link } from "@tanstack/react-router";
import { ReactNode, useMemo } from "react";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { toast } from "sonner";
import { ADMIN_SETTINGS_QUERY_KEY } from "@/routes/_application/admin";
import { useQueryClient } from "@tanstack/react-query";

interface QueuesProps {
  queues: components["schemas"]["QueueStatus"][];
}

type QueueGroups = {
  [group: string]: components["schemas"]["QueueStatus"][];
};

export default function Queues(props: QueuesProps) {
  const queryClient = useQueryClient();
  const groups = useMemo(() => {
    let out = props.queues.reduce((acc, val) => {
      if (acc[val.group] == null) acc[val.group] = [];
      acc[val.group].push(val);
      return acc;
    }, {} as QueueGroups);
    return Object.values(out);
  }, [props.queues]);

  async function pauseQueues() {
    const response = await apiClient.PATCH("/admin/queue/pause");
    toast.success("Paused all queues");
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_SETTINGS_QUERY_KEY],
    });
  }

  async function resumeQueues() {
    const response = await apiClient.PATCH("/admin/queue/resume");
    toast.success("Resumed all queues");
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_SETTINGS_QUERY_KEY],
    });
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex gap-2 justify-end">
        {props.queues.some((v) => !v.paused) && (
          <Button onClick={() => pauseQueues()} variant="outline">
            Pause All
          </Button>
        )}
        {props.queues.some((v) => v.paused) && (
          <Button onClick={() => resumeQueues()} variant="outline">
            Resume All
          </Button>
        )}
      </div>
      {groups.map((group) => {
        return (
          <Card key={group[0].group}>
            <CardHeader>
              <CardTitle>{group[0].group}</CardTitle>
              <CardDescription>{group[0].description}</CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="grid mt-2 grid-cols-1 gap-4">
                {group
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((v) => (
                    <Link
                      key={v.name}
                      to="/admin/$queueName"
                      params={{ queueName: v.name }}
                    >
                      <li className="p-4 flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between hover:bg-muted border rounded-md">
                        <h2 className="text-lg font-semibold">{v.display}</h2>
                        {getQueueStatusBadges(
                          Object.entries(v.statuses),
                          v.paused
                        )}
                      </li>
                    </Link>
                  ))}
              </ul>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}

export function getQueueStatusBadges(
  statuses: [name: string, count: number][],
  paused: boolean
) {
  let validStatuses = statuses.filter((v) => v[1] > 0);
  let badges: ReactNode[] = [];
  if (paused) {
    badges.push(
      <li key={"paused"}>
        <Badge variant="warning">Paused</Badge>
      </li>
    );
  }
  if (!validStatuses.length) {
    badges.push(
      <li key={"none"}>
        <Badge variant="outline">None</Badge>
      </li>
    );
  } else {
    badges.push(
      ...validStatuses
        .sort(([a], [b]) =>
          getQueueStatusSort(a as QueueStatus) >
          getQueueStatusSort(b as QueueStatus)
            ? 1
            : -1
        )
        .map(([name, count]: any) => (
          <li key={name}>
            <Badge variant={getQueueStatusVariant(name)}>
              {name} {count}
            </Badge>
          </li>
        ))
    );
  }
  return <ul className="flex flex-wrap gap-2">{badges}</ul>;
}

export function getQueueStatusVariant(status: QueueStatus) {
  switch (status) {
    case QueueStatus.active:
    case QueueStatus.paused:
    case QueueStatus.prioritized:
    case QueueStatus.repeat:
    case QueueStatus.waiting:
    case QueueStatus.delayed:
    case QueueStatus.waiting_children:
      return "outline";
    case QueueStatus.failed:
      return "destructive";
    case QueueStatus.completed:
      return "success";
  }
}

export function getQueueStatusSort(status: QueueStatus) {
  switch (status) {
    case QueueStatus.active:
    case QueueStatus.paused:
    case QueueStatus.prioritized:
    case QueueStatus.repeat:
    case QueueStatus.waiting:
    case QueueStatus.delayed:
    case QueueStatus.waiting_children:
      return 3;
    case QueueStatus.failed:
      return 2;
    case QueueStatus.completed:
      return 1;
  }
}
