import {
  Link,
  SearchSchemaInput,
  createFileRoute,
} from "@tanstack/react-router";

import { ConstrainedAppLayout } from "@/components/app-layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { apiClient } from "@/lib/api";
import ProfileSettings from "@/components/settings/profile";
import TeamSettings from "@/components/settings/team";
import IntegrationSettings from "@/components/settings/integration";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import NotificationSettings from "@/components/settings/notifications";
import ChatOpsSettings from "@/components/settings/chat-ops";
import SystemLogSettings from "@/components/settings/system-log";

export const Route = createFileRoute("/_application/settings/")({
  component: Settings,
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getOptions());
  },
  validateSearch: (
    search: {
      tab?:
        | "account"
        | "integrations"
        | "team"
        | "notifications"
        | "chat_ops"
        | "system_log";
    } & SearchSchemaInput
  ) => {
    return {
      tab: search.tab ?? "account",
    };
  },
});

async function getSettings() {
  const [user, team] = await Promise.all([
    apiClient.GET("/users"),
    apiClient.GET("/team"),
  ]);
  if (user.error != null || team.error != null) {
    throw new Error("Error getting settings information");
  }
  return {
    user: user.data,
    team: team.data,
  };
}

export const SETTINGS_QUERY_KEY = "settings";
const getOptions = () =>
  queryOptions({
    queryKey: [SETTINGS_QUERY_KEY],
    queryFn: getSettings,
  });

export function Settings() {
  const {
    data: { user, team },
  } = useSuspenseQuery(getOptions());
  const { tab } = Route.useSearch();

  return (
    <ConstrainedAppLayout>
      <Card className="bg-inherit border-none shadow-none">
        <CardHeader>
          <CardTitle className="text-2xl">Settings</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs
            orientation="vertical"
            value={tab ?? "account"}
            className="grid grid-cols-1 w-full mx-auto gap-4 lg:grid-cols-4"
          >
            <TabsList className="col-span-1 flex-col justify-start items-start space-y-2 h-auto bg-inherit">
              <TabsTrigger value="account" asChild>
                <Link to="." search={{ tab: "account" }}>
                  Account
                </Link>
              </TabsTrigger>
              <TabsTrigger value="team" asChild>
                <Link to="." search={{ tab: "team" }}>
                  Team
                </Link>
              </TabsTrigger>
              <TabsTrigger value="integrations" asChild>
                <Link to="." search={{ tab: "integrations" }}>
                  Integrations
                </Link>
              </TabsTrigger>
              <TabsTrigger value="notifications" asChild>
                <Link to="." search={{ tab: "notifications" }}>
                  Notifications
                </Link>
              </TabsTrigger>
              <TabsTrigger value="chat_ops" asChild>
                <Link to="." search={{ tab: "chat_ops" }}>
                  Chat Ops
                </Link>
              </TabsTrigger>
              <TabsTrigger value="system_log" asChild>
                <Link to="." search={{ tab: "system_log" }}>
                  System Log
                </Link>
              </TabsTrigger>
            </TabsList>
            <div className="col-span-3">
              <TabsContent value="account">
                <ProfileSettings user={user} />
              </TabsContent>
              <TabsContent value="team">
                <TeamSettings />
              </TabsContent>
              <TabsContent value="integrations">
                <IntegrationSettings />
              </TabsContent>
              <TabsContent value="notifications">
                <NotificationSettings />
              </TabsContent>
              <TabsContent value="chat_ops">
                <ChatOpsSettings team={team} />
              </TabsContent>
              <TabsContent value="system_log">
                <SystemLogSettings />
              </TabsContent>
            </div>
          </Tabs>
        </CardContent>
      </Card>
    </ConstrainedAppLayout>
  );
}
