import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { getCaseStatusConfigByStatus } from "@wire/shared";
import { useMemo, useState } from "react";

export const Route = createFileRoute(
  "/_application/assets/locations/$locationId"
)({
  component: Location,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.locationId));
  },
});

async function getData(locationId: string) {
  const [location] = await Promise.all([
    apiClient.GET("/location/{id}", {
      params: { path: { id: locationId } },
    }),
  ]);
  if (location.error != null) {
    throw new Error("Error getting location information");
  }
  return {
    location: location.data,
  };
}

export const ASSET_QUERY_KEY = "location";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  locationId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "LOCATION", assetId: locationId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  locationId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, locationId],
    queryFn: () => searchCases(searchSettings, locationId),
    placeholderData: keepPreviousData,
  });

function Location() {
  const { locationId } = Route.useParams();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, locationId));
  const {
    data: { location },
  } = useSuspenseQuery(getOptions(locationId));
  const navigate = useNavigate();

  const title = useMemo(() => {
    if (location.city && location.state) {
      return `${location.city}, ${location.state}`;
    }
    if (location.lat && location.lon) {
      return `${location.lat}, ${location.lon}`;
    }
    return "N/A";
  }, [location]);

  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>{title}</CardTitle>
            <CardDescription>Location</CardDescription>
          </CardHeader>
          <CardContent className="overflow-auto">
            <h2 className="font-semibold">Created At</h2>
            <p className="text-sm">{dateTime(location.createdAt)}</p>
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({ to: "/cases/$caseId", params: { caseId: row.id } })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "title",
              sortable: true,
              format(value, row) {
                return (
                  <div>
                    <div>{row.title}</div>
                    <div className="text-xs">{row.name}</div>
                  </div>
                );
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getCaseStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "sourceIngestedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this location has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
