import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { useDebounce, useIsMount } from "@/lib/utils";
import { SETTINGS_QUERY_KEY } from "@/routes/_application/settings";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

interface ChatOpsSettingsProps {
  team: components["schemas"]["Team"];
}

export default function ChatOpsSettings(props: ChatOpsSettingsProps) {
  const isMount = useIsMount();
  const [welcomeMessage, setWelcomeMessage] = useState<string | undefined>(
    props.team.chatOpsWelcomeMessage
  );
  const [securityChatOps, setSecurityChatOps] = useState(
    props.team.securityChatOps
  );
  const queryClient = useQueryClient();
  const [chatOpsEscalationDelayMinutes, setChatOpsEscalationDelayMinutes] =
    useState(props.team.chatOpsEscalationDelayMinutes);
  const [chatOpsPerDay, setChatOpsPerDay] = useState<number | undefined>(
    props.team.maxChatOpsPerDay
  );

  const {
    debounced: debouncedWelcomeMessage,
    override: skipDebounceWelcomeMessage,
  } = useDebounce(500, welcomeMessage);
  const {
    debounced: debouncedMaxChatOps,
    override: skipDebounceChatOpsPerDay,
  } = useDebounce(500, chatOpsPerDay);
  const {
    debounced: debouncedChatOpsEscalationDelayMinutes,
    override: skipDebounceEscalationDelayMinutes,
  } = useDebounce(500, chatOpsEscalationDelayMinutes);

  const securityChatOpsEnabled = useMemo(() => {
    return props.team.securityChatOps;
  }, [props.team.securityChatOps]);

  const maxChatOpsEnabled = useMemo(() => {
    return props.team.maxChatOpsPerDay != null;
  }, [props.team.maxChatOpsPerDay]);

  const welcomeMessageEnabled = useMemo(() => {
    return (
      props.team.chatOpsWelcomeMessage != null &&
      props.team.chatOpsWelcomeMessage.length > 0
    );
  }, [props.team.chatOpsWelcomeMessage]);

  async function updateMessage(message: string | undefined) {
    await updateTeam(
      {
        chatOpsWelcomeMessage: message,
        disableChatOpsWelcomeMessage: !message || message.length == 0,
      },
      "Welcome message updated"
    );
  }

  useEffect(() => {
    if (isMount) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateMessage(debouncedWelcomeMessage);
  }, [debouncedWelcomeMessage]);

  async function updateRateLimit(val?: number) {
    await updateTeam(
      { maxChatOpsPerDay: val, disableMaxChatOps: val == null },
      val != null ? "Rate limit updated" : "Rate limit removed"
    );
  }

  useEffect(() => {
    if (isMount) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateRateLimit(debouncedMaxChatOps);
  }, [debouncedMaxChatOps]);

  async function updateEscalationDelay(val?: number) {
    if (val == null) {
      toast.error("Escalation delay must be present");
      return;
    }
    await updateTeam(
      { chatOpsEscalationDelayMinutes: val },
      "Escalation delay updated"
    );
  }

  useEffect(() => {
    if (isMount) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateEscalationDelay(debouncedChatOpsEscalationDelayMinutes);
  }, [debouncedChatOpsEscalationDelayMinutes]);

  async function updateSecurityChatOps(val: boolean) {
    await updateTeam(
      { securityChatOps: val },
      val ? "Security team chat ops enabled" : "Security team chat ops disabled"
    );
  }

  async function updateTeam(
    dto: components["schemas"]["UpdateTeamDto"],
    success: string
  ) {
    const response = await apiClient.PATCH("/team", {
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success(success);
    await queryClient.invalidateQueries({ queryKey: [SETTINGS_QUERY_KEY] });
  }

  return (
    <div className="flex flex-col gap-4">
      <Card>
        <CardHeader>
          <CardTitle>Settings</CardTitle>
          <CardDescription>
            Limits and configurations for chat operations
          </CardDescription>
        </CardHeader>
        <CardContent className="flex gap-8 flex-col">
          <div>
            <Label>Escalation Delay</Label>
            <Input
              value={chatOpsEscalationDelayMinutes ?? ""}
              required
              onChange={(e) =>
                setChatOpsEscalationDelayMinutes(parseInt(e.target.value))
              }
              className="mt-1"
              placeholder="10"
              type="number"
            />
            <p className="text-xs text-muted-foreground mt-1">
              How many minutes to wait until escalating unresponded chat ops -{" "}
              <a
                className="hover:underline"
                target="_blank"
                href="/docs/chat-ops/#escalations"
              >
                Learn More
              </a>
            </p>
          </div>
          <div>
            <div className="flex justify-between items-center">
              <Label>Security Team Chat Ops</Label>
              <div className="flex items-center gap-2">
                <Switch
                  checked={securityChatOps}
                  onCheckedChange={async (e) => {
                    setSecurityChatOps(e);
                    await updateSecurityChatOps(e);
                  }}
                />
              </div>
            </div>

            <p className="text-xs text-muted-foreground mt-1">
              {!securityChatOpsEnabled && (
                <span>
                  <span className="text-red-500">Disabled</span> -{" "}
                </span>
              )}{" "}
              Communicate with security team via chat integrations -{" "}
              <a
                target="_blank"
                className="hover:underline"
                href="/docs/chat-ops/#security-team"
              >
                Learn More
              </a>
            </p>
          </div>
          <div>
            <Label>Max Messages Per Day</Label>
            <div className="flex items-center mt-1 gap-2">
              <Input
                value={chatOpsPerDay ?? ""}
                onChange={(e) =>
                  setChatOpsPerDay(
                    e.target.value ? parseInt(e.target.value) : undefined
                  )
                }
                className=""
                placeholder="10"
                type="number"
              />
              <Button
                onClick={() => {
                  setChatOpsPerDay(undefined);
                  skipDebounceChatOpsPerDay(undefined);
                }}
                variant="outline"
              >
                <TrashIcon className="h-4 w-4" />
              </Button>
            </div>
            <p className="text-xs text-muted-foreground mt-1">
              {!maxChatOpsEnabled && (
                <span>
                  <span className="text-red-500">Disabled</span> -{" "}
                </span>
              )}{" "}
              Maximum number of chat ops interactions Wirespeed can make within
              a 24 hour period -{" "}
              <a
                target="_blank"
                className="hover:underline"
                href="/docs/chat-ops/communication-plan/#initial-rate-limiting"
              >
                Learn More
              </a>
            </p>
          </div>
          <div>
            <Label>Welcome Message</Label>
            <div className="flex  items-stretch mt-1 gap-2">
              <Textarea
                value={welcomeMessage ?? ""}
                rows={4}
                placeholder="Introduce Wirespeed to your team..."
                onChange={(e) => setWelcomeMessage(e.target.value)}
              />
              <div>
                <Button
                  className="h-full"
                  onClick={() => {
                    setWelcomeMessage(undefined);
                    skipDebounceWelcomeMessage(undefined);
                  }}
                  variant="outline"
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>

            <p className="text-xs text-muted-foreground mt-1">
              {!welcomeMessageEnabled && (
                <span>
                  <span className="text-red-500">Disabled</span> -{" "}
                </span>
              )}{" "}
              Introductory message to be sent to users upon their first message
              from Wirespeed -{" "}
              <a
                className="hover:underline"
                target="_blank"
                href="/docs/chat-ops/communication-plan/#on-first-use-messages"
              >
                Learn More
              </a>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
