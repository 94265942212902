"use client";

import * as React from "react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  CheckIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

interface ComboboxProps {
  values: { value: string; label: string }[];
  placeholder: string;
  defaultValue: string;
  value?: string;
  emptyMessage: string;
  onSelect: (value: string) => void;
}

export function Combobox(props: ComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.defaultValue ?? props.value);

  function selectValue(value: string) {
    props.onSelect(value);
    setValue(value);
    setOpen(false);
  }

  React.useEffect(() => {
    if (props.value != null) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] font-normal justify-between"
        >
          {value
            ? props.values?.find((option) => option.value === value)?.label
            : props.placeholder}

          <ChevronDownIcon
            className={cn(
              "ml-2 h-4 w-4 shrink-0 opacity-50 transition-transform",
              {
                "rotate-180": open,
              }
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command
          filter={(value, search) => {
            if (value.includes(search)) return 1;
            if (
              props.values
                .find((v) => v.value == value)
                ?.label.toLowerCase()
                .includes(search.toLowerCase())
            )
              return 1;
            return 0;
          }}
        >
          <CommandInput placeholder={props.placeholder} />
          <CommandEmpty>{props.emptyMessage}</CommandEmpty>
          <CommandList>
            {props.values?.map((option) => (
              <CommandItem
                key={option.value}
                className="break-all cursor-pointer"
                value={option.value}
                onSelect={selectValue}
              >
                <CheckIcon
                  className={cn(
                    "mr-2 h-4 w-4 flex-shrink-0",
                    value === option.value ? "opacity-100" : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
