import type { IntegrationPlatform } from "./integration.types";

export * from "./integration.types";
export * from "./notification";
export * from "./detection.types";
export * from "./log.types";

export enum Verdict {
  ACTIONABLE = "ACTIONABLE",
  UNSURE = "UNSURE",
  NON_ACTIONABLE = "NON_ACTIONABLE",
}
export const VERDICT_CATEGORIES = Object.values(Verdict);

interface IVerdictConfig {
  display: string;
  type: Verdict;
}

export const VerdictConfig: {
  [key in Verdict]: IVerdictConfig;
} = {
  [Verdict.ACTIONABLE]: {
    display: "Actionable",
    type: Verdict.ACTIONABLE,
  },
  [Verdict.UNSURE]: {
    display: "Unsure",
    type: Verdict.UNSURE,
  },
  [Verdict.NON_ACTIONABLE]: {
    display: "Non-Actionable",
    type: Verdict.NON_ACTIONABLE,
  },
};

export function getVerdictConfigByVerdict(verdict: null | string | Verdict) {
  if (verdict == null) return VerdictConfig[Verdict.UNSURE];
  return VerdictConfig[verdict as Verdict];
}

export enum VerdictAction {
  CONTAIN = "CONTAIN",
  CHAT_OPS = "CHAT_OPS",
  ESCALATE = "ESCALATE",
  COMPLETE = "COMPLETE",
}

interface IVerdictActionConfig {
  display: string;
  type: VerdictAction;
}

export const VerdictActionConfig: {
  [key in VerdictAction]: IVerdictActionConfig;
} = {
  [VerdictAction.CONTAIN]: {
    display: "Contain",
    type: VerdictAction.CONTAIN,
  },
  [VerdictAction.CHAT_OPS]: {
    display: "Chat Ops",
    type: VerdictAction.CHAT_OPS,
  },
  [VerdictAction.ESCALATE]: {
    display: "Escalate",
    type: VerdictAction.ESCALATE,
  },
  [VerdictAction.COMPLETE]: {
    display: "Complete",
    type: VerdictAction.COMPLETE,
  },
};

export function getVerdictActionConfigByVerdictValue(
  verdictNextStep: string | VerdictAction
) {
  return VerdictActionConfig[verdictNextStep as VerdictAction];
}

export enum CaseStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  ESCALATED = "ESCALATED",
  CLOSED = "CLOSED",
}

interface ICaseStatusConfig {
  display: string;
  type: CaseStatus;
}

export const CaseStatusConfig: {
  [key in CaseStatus]: ICaseStatusConfig;
} = {
  [CaseStatus.NEW]: {
    display: "New",
    type: CaseStatus.NEW,
  },
  [CaseStatus.PROCESSING]: {
    display: "Processing",
    type: CaseStatus.PROCESSING,
  },
  [CaseStatus.ESCALATED]: {
    display: "Escalated",
    type: CaseStatus.ESCALATED,
  },
  [CaseStatus.CLOSED]: {
    display: "Closed",
    type: CaseStatus.CLOSED,
  },
};

export function getCaseStatusConfigByStatus(status: string | CaseStatus) {
  return CaseStatusConfig[status as CaseStatus];
}

export enum QueueStatus {
  completed = "completed",
  failed = "failed",
  delayed = "delayed",
  active = "active",
  waiting = "waiting",
  prioritized = "prioritized",
  paused = "paused",
  repeat = "repeat",
  waiting_children = "waiting-children",
}

export interface ChatOpsActionJWT {
  caseId: string;
  verdict: Verdict;
  recipient: CHAT_OPS_RECIPIENT_TYPE;
  platform: IntegrationPlatform;
}

export enum CHAT_OPS_RECIPIENT_TYPE {
  USER = "USER",
  MANAGER = "MANAGER",
  SECURITY = "SECURITY",
}

export type Nullable<T> = T | null;

export type IPInfoMetadata = {
  ip: string;
  hostname: string;
  city: string;
  region: string;
  org?: string;
  country: string;
  loc: string;
  postal: string;
  timezone: string;
  asn?: {
    asn: string;
    name: string;
    domain: string;
    route: string;
    type: string;
  };
  company?: {
    name: string;
    domain: string;
    type: string;
  };
  privacy?: {
    vpn: boolean;
    proxy: boolean;
    tor: boolean;
    relay: boolean;
    hosting: boolean;
    service: string;
  };
  abuse?: {
    address: string;
    country: string;
    email: string;
    name: string;
    network: string;
    phone: string;
  };
  domains?: {
    ip: string;
    total: number;
    domains: Array<string>;
  };
};
