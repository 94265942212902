"use client";

import createClient, { Middleware } from "openapi-fetch";
import type { components, paths } from "./api.types.ts"; // generated by openapi-typescript
import { validate } from "uuid";
import { UseNavigateResult } from "@tanstack/react-router";
import { queryClient, router } from "@/main.tsx";

/**
 * When the user logs in, we persist their access token in localStorage.
 * If, after authentication, they switch teams, we persist the updated access token in session storage. This way, multiple tabs can be open and have different sessions.
 * We fall back to localStorage if session storage isn't available.
 */
let accessToken: string | undefined | null;
if (typeof localStorage != "undefined") {
  let localToken = localStorage.getItem("AUTH_TOKEN");
  if (localToken != null) {
    accessToken = localToken;
  }
}
if (typeof sessionStorage != "undefined") {
  let sessionToken = sessionStorage.getItem("AUTH_TOKEN");
  if (sessionToken != null) {
    accessToken = sessionToken;
  }
}

if (typeof window != "undefined") {
  window.removeEventListener("focus", handleTabFocusForAuth);
  window.addEventListener("focus", handleTabFocusForAuth);
}

/**
 * When a user is switching between multiple teams, we store the update tokens in session storage
 *   so they can have multiple browsers open (and refresh the page) without the token reverting back to whatever
 *   it originally was.
 *
 * However, if they click on a link that opens in a new tab, that new tab grabs session state from local storage, which
 *  may not be the same as the session storage of the opening tab.
 *
 * This makes sure that when a user is in this tab and clicks a link, the session storage for the opened tab
 * matches this link. There's like some race conditions here, but that's accepted for now.
 *
 */
function handleTabFocusForAuth() {
  let local = localStorage.getItem("AUTH_TOKEN");
  let session = sessionStorage.getItem("AUTH_TOKEN");
  if (local != session && session != null) {
    localStorage.setItem("AUTH_TOKEN", session);
  }
}

const authMiddleware: Middleware = {
  onRequest(req) {
    if (accessToken != null) {
      req.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return req;
  },
};

const unauthorizedMiddleware: Middleware = {
  async onResponse(res: Response) {
    const user = getUser();

    if ((res.status == 401 || res.status == 404) && user != null) {
      let uuid = location.pathname.split("/").find(validate);
      // Technically there could be multiple UUIDs, but it's unlikely they'd be from separate teams
      if (uuid != null) {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/team/proper-team/${uuid}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.ok) {
          const body: components["schemas"]["TeamId"] = await response.json();
          window.location.href = `/switch?from=${user.teamId}&to=${body.teamId}&redirect=${encodeURIComponent(window.location.pathname)}`;
          return;
        }
      }
    }

    return res;
  },
};

export const apiClient = createClient<paths>({
  baseUrl: import.meta.env.VITE_API_URL,
});

apiClient.use(authMiddleware);
apiClient.use(unauthorizedMiddleware);

export function setAPIToken(value?: string) {
  if (value == null) {
    localStorage.removeItem("AUTH_TOKEN");
    sessionStorage.removeItem("AUTH_TOKEN");
  } else {
    localStorage.setItem("AUTH_TOKEN", value);
    sessionStorage.setItem("AUTH_TOKEN", value);
  }
  accessToken = value;
  apiClient.eject(authMiddleware);
  apiClient.use(authMiddleware);
}

function getParsedJWT() {
  if (accessToken == null) return;
  try {
    const data = JSON.parse(atob(accessToken.split(".")[1]));
    if (data == null) return;
    return data;
  } catch (err: any) {
    return;
  }
}

export function hasValidAuthToken() {
  const data = getParsedJWT();
  if (data == null) return false;
  const expiration = new Date(parseInt(data.exp) * 1000);
  if (expiration < new Date()) {
    return false;
  }
  return true;
}

export function logout(redirect = true) {
  setAPIToken();
  if (redirect) {
    window.location.pathname = "/";
  }
}

export function getUser(): User {
  const data: User = getParsedJWT();
  return data;
}

export async function getTeam(): Promise<
  components["schemas"]["Team"] | undefined
> {
  if (accessToken == null) {
    return;
  }
  const response = await apiClient.GET("/team");
  return response.data;
}

export interface User {
  email: string;
  id: string;
  teamId: string;
  parentTeamId: string;
  superAdmin?: boolean;
  completedOnboarding?: boolean;
}

export async function switchTeam(
  teamId: string,
  navigate?: UseNavigateResult<string>,
  redirect?: string,
  invalidateFirst = true
) {
  const response = await apiClient.POST("/auth/team", { body: { teamId } });
  if (response.error != null) {
    alert("Error switching teams");
    return;
  }
  setAPIToken(response.data.accessToken);
  let url = redirect ?? "/dashboard";
  if (navigate == null) {
    window.location.href = url;
    return;
  }

  // The query invalidation gets weird if we try to navigate to the same page
  if (url != window.location.pathname) {
    await navigate({ to: redirect ?? "/dashboard" });
  }

  await queryClient.invalidateQueries({ queryKey: [] });
  await router.invalidate();
}

/**
 * Formats our search queries for the API. We let the user use double quotes for exact queries
 * but the API doesn't require them so we can strip them. If the user isn't using double quotes
 * we add wildcards to their search if they haven't already added them.
 * @param search
 * @returns
 */
export function formatSearchQuery(search?: string) {
  if (search && search.startsWith('"') && search?.endsWith('"')) {
    search = search.replaceAll('"', "");
  } else if (search != null) {
    if (!search.startsWith("*")) {
      search = `*${search}`;
    }
    if (!search.endsWith("*")) {
      search = `${search}*`;
    }
  }
  return search;
}
