import {
  Link,
  createFileRoute,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Underline from "@/components/onboarding/underline";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { components } from "@/lib/api.types";
import { CheckCircleIcon } from "@heroicons/react/16/solid";
import AddIntegration from "@/components/settings/add-integration";
import {
  getIntegrationConfigByPlatform,
  getIntegrationTypeConfigByType,
  IntegrationConfig,
  IntegrationPlatform,
  IntegrationType,
} from "@wire/shared";
import { GLOBAL_TEAM_QUERY_KEY } from "@/main";
import { cn } from "@/lib/utils";
export const Route = createFileRoute("/_onboarding/user-onboarding/$step")({
  component: UserOnboarding,
  loader: ({ context }) => context.queryClient.ensureQueryData(getOptions()),
});

async function getData() {
  const integrations = await apiClient.POST("/integration", {
    body: { size: 100 },
  });
  if (integrations.error != null) {
    throw new Error("Error getting integration data");
  }
  return { integrations: integrations.data };
}

export const ONBOARDING_USER_QUERY_KEY = "onboarding-user";
const getOptions = () =>
  queryOptions({
    queryKey: [ONBOARDING_USER_QUERY_KEY],
    queryFn: () => getData(),
  });

export function UserOnboarding() {
  const {
    data: { integrations },
  } = useSuspenseQuery(getOptions());
  const { team } = useRouteContext({ from: "/_onboarding" });
  const { step } = Route.useParams();

  const StepComponent = useMemo(() => {
    switch (step) {
      default:
      case "1":
        return StepOne;
      case "2":
        return StepTwo;
    }
  }, [step]);
  return (
    <div className="w-screen min-h-screen flex">
      <div className="flex w-full flex-col justify-center items-center">
        <AnimatePresence mode="wait">
          <StepComponent
            team={team}
            key={step}
            integrations={integrations?.data}
          />
        </AnimatePresence>
      </div>
    </div>
  );
}

function StepOne() {
  return (
    <motion.div
      className="flex flex-col items-center"
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.25 }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25 }}
      >
        <img src="/illustrations/welcome.svg" className="max-h-96 w-auto" />
        <h1 className="text-4xl font-semibold mt-8">Welcome to Wirespeed!</h1>
      </motion.div>
      <div className="w-96 p-4 h-auto">
        <Underline className="h-auto stroke-primary w-auto" />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <p className="mt-4 text-xl  ">
          Let us introduce you to the platform, we promise it'll be quick
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.6, duration: 0.5 }}
      >
        <Link to="/user-onboarding/$step" params={{ step: "2" }}>
          <Button size="lg" className="mt-8">
            Get Started
          </Button>
        </Link>
      </motion.div>
    </motion.div>
  );
}

function StepTwo(props: {
  team: components["schemas"]["Team"];
  integrations?: components["schemas"]["IntegrationWithoutMetadata"][];
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [integrationPlatform, setIntegrationPlatform] =
    useState<IntegrationPlatform>();
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);

  const detectionIntegrations = useMemo(() => {
    return Object.values(IntegrationConfig)
      .filter((v) =>
        v.types.some((v) => getIntegrationTypeConfigByType(v).detection)
      )
      .map((v) => ({
        ...v,
        integrated: props.integrations?.some((i) => i.platform == v.platform),
      }));
  }, [props.integrations]);

  const completedOnboarding = useMemo(() => {
    return detectionIntegrations.some((v) => v.integrated);
  }, [detectionIntegrations]);

  async function skip() {
    await apiClient.PATCH("/users", { body: { completedOnboarding: true } });
    await navigate({ to: "/dashboard" });
  }

  async function complete() {
    if (!props.team.completedOnboarding) {
      await apiClient.PATCH("/team", { body: { completedOnboarding: true } });
    }
    await apiClient.PATCH("/users", { body: { completedOnboarding: true } });
    await queryClient.invalidateQueries({ queryKey: [GLOBAL_TEAM_QUERY_KEY] });
    await navigate({ to: "/dashboard" });
  }

  // Keep checking for when integrations are set up
  useEffect(() => {
    const interval = setInterval(async () => {
      await queryClient.invalidateQueries({
        queryKey: [ONBOARDING_USER_QUERY_KEY],
      });
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  function selectIntegration(platform: IntegrationPlatform) {
    setIntegrationPlatform(platform);
    setIntegrationModalOpen(true);
  }

  return (
    <motion.div
      className="grid grid-cols-1 lg:grid-cols-2 p-4 gap-8 lg:gap-32"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.25 }}
      exit={{ opacity: 0, scale: 0.5 }}
    >
      <div className="lg:order-1 order-2">
        <h1 className="text-4xl font-bold mt-4">
          Getting Started with Wirespeed
        </h1>
        <>
          <h2 className="text-3xl font-semibold mt-8">Integrations</h2>
          <p className="mt-4 text-lg">
            {completedOnboarding ? (
              <>Your team has completed all necessary onboarding steps.</>
            ) : (
              <>
                We will need to set up an integration before we can get started!
              </>
            )}
          </p>

          <ul className="my-8 flex flex-col gap-2">
            {detectionIntegrations.map((v) => (
              <li
                onClick={() => selectIntegration(v.platform)}
                className={cn(
                  "flex gap-4 cursor-pointer items-center hover:bg-muted rounded-md p-4",
                  {
                    "bg-muted": v.integrated,
                  }
                )}
              >
                <img src={v.logo} className="h-8 w-8" />
                <div className="flex flex-col">
                  <h2 className="text-2xl">{v.display}</h2>
                  <p className="text-xs text-muted-foreground">
                    {getIntegrationConfigByPlatform(v.platform)
                      .types?.map(
                        (t) => getIntegrationTypeConfigByType(t)?.display
                      )
                      .join(", ")}
                  </p>
                </div>
                {v.integrated && (
                  <CheckCircleIcon className="h-6 w-6 text-green-500" />
                )}
              </li>
            ))}
          </ul>

          {completedOnboarding ? (
            <Button onClick={complete}>Continue</Button>
          ) : (
            <Button onClick={skip} variant="outline">
              Skip
            </Button>
          )}
          <AddIntegration
            hideTrigger
            onboarding
            onChange={setIntegrationModalOpen}
            open={integrationModalOpen}
            platform={integrationPlatform}
          />
        </>
      </div>
      <div className="order-1 lg:order-2">
        <img
          src="/illustrations/choose.svg"
          className="lg:h-auto h-96 w-auto"
        />
      </div>
    </motion.div>
  );
}
